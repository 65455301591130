import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import EmbedModalState from "../../State/EmbedModalState";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { FileCopy } from "@material-ui/icons";


class EmbedModal extends Component {

    state = {
        previewMode: false
    }

    handleClose = () => {
        EmbedModalState.hideModel();
    };

    render() {
        let iframeURL = "";
        if (EmbedModalState.iframeURL !== "") {
            iframeURL = this.state.previewMode ? EmbedModalState.iframeURL + "&preview=on" : EmbedModalState.iframeURL;
        }
        let IframeEmbedCode = `<iframe style="width:100vw;height:100vh;" src="${iframeURL}" frameborder="0" ></iframe>`;
        return (
            <Dialog onClose={() => this.handleClose()} open={EmbedModalState.isVisible}>
                <DialogTitle>Embed {EmbedModalState.embedType}</DialogTitle>
                <Divider />
                <DialogContent style={{ width: 600 }}>
                    <FormControlLabel control={<Checkbox
                        checked={this.state.previewMode}
                        onChange={() => this.setState({ previewMode: !this.state.previewMode })}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />} label='Preview Mode' />

                    {this.state.previewMode && <DialogContentText style={{ color: '#F00' }}>
                        Warning: Embedding a preview mode form will not make it go live after publishing.
                        You will need to change your embed code before going live.<br />
                        Preview Mode embeds are meant to see your changes as you are working on them within your site.
                    </DialogContentText>}

                    <TextField variant='filled'
                        value={this.state.previewMode ? EmbedModalState.previewModeCode : EmbedModalState.liveModeCode}
                        label='Script Embed Code'
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <IconButton title="Copy Code" onClick={() => navigator.clipboard.writeText(this.state.previewMode ? EmbedModalState.previewModeCode : EmbedModalState.liveModeCode)}>
                                    <FileCopy />
                                </IconButton>
                            </InputAdornment>
                        }} />
                    <br></br>
                    <br></br>
                    {iframeURL !== "" &&
                        <TextField
                            id="filled-multiline-static"
                            label='Iframe Embed Code'
                            multiline
                            rows={4}
                            value={IframeEmbedCode}
                            variant="filled"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton title="Copy Code" onClick={() => navigator.clipboard.writeText(IframeEmbedCode)}>
                                        <FileCopy />
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(EmbedModal);