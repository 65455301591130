import { store } from '@risingstack/react-easy-state';

const UIState = store({
    pageTitle: 'Form Builder',
    ignoreClientDomains: false,
    setPageTitle: (title) => {
        UIState.pageTitle = title;
        document.title = `Form Builder: ${UIState.pageTitle}`;
    }
});

export default UIState;