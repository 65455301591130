import React, {Component}  from 'react';
import { view } from '@risingstack/react-easy-state';
import Modal from "./Modal";
import Grid from "@material-ui/core/Grid";

class ModalList extends Component{

    render(){
        const { modals } = this.props;

        return (<Grid container spacing={2}>
            {modals.map((modal, index) => (
                <Modal index={index} {...modal} />
            ))}
        </Grid>)
    }

}

export default view(ModalList);