import { store } from '@risingstack/react-easy-state';
import { manageVariableArray } from '../lib';
import PageSocket from "../Sockets/PageSocket";
import NotificationState from "./NotificationState";

const PageEditorState = store({
    pageId: 0,
    config: {},
    css: '',
    js: '',
    socket: null,
    variables: [],
    name: '',
    meta: {
        ogTags: {},
        extraTags: []
    },
    defaultValues: {
        variables: [],
        meta: {
            ogTags: {},
            extraTags: []
        },
    },
    versions: {},
    setPage: (pageId) => {
        PageEditorState.componentId = pageId;
        PageEditorState.initSocket(pageId);
    },
    initSocket: (pageId) => {
        if (pageId && !PageEditorState.socket) {
            PageSocket.init(pageId);
            PageEditorState.socket = PageSocket.socket;
            PageEditorState.socket.on('serverUpdate', (data) => {
                PageEditorState.receiveUpdate(data);
            });
        }
    },
    publishPage: async () => {
        await PageEditorState.socket.emit('publishPage');
        NotificationState.open('The page has been successfully published!', 'Page Published');
    },
    revertPage: async () => {
        await PageEditorState.socket.emit('revertPage');
        NotificationState.open('The page has been successfully reverted!', 'Page Reverted');
    },
    emitUpdate: () => {
        PageEditorState.variables = manageVariableArray(PageEditorState.variables);
        PageEditorState.socket.emit('clientUpdate', {
            config: PageEditorState.config,
            css: PageEditorState.css,
            javascript: PageEditorState.js,
            variables: PageEditorState.variables,
            meta: PageEditorState.meta,
            versions: PageEditorState.versions,
        });
    },
    receiveUpdate: (data) => {
        PageEditorState.config = typeof data.config === 'object' ? data.config : {};
        PageEditorState.css = data.css;
        PageEditorState.js = data.javascript;
        PageEditorState.variables = data.variables || [];
        PageEditorState.meta = data.meta || {};
        if (!PageEditorState.meta.ogTags) {
            PageEditorState.meta.ogTags = {
                "og:type": "website"
            };
        }
        if (!PageEditorState.meta.extraTags) {
            PageEditorState.meta.extraTags = [];
        }
        PageEditorState.name = data.name;
        PageEditorState.versions = data.versions || null;
    },
    addVariable: () => {
        PageEditorState.variables.push({
            key: '',
            value: '',
            id: 'new'
        });
    },
    removeVariable: (index) => {
        if (PageEditorState.variables.length < 2) {
            PageEditorState.variables = [];
        } else {
            const newArray = PageEditorState.variables.slice(0, index);
            PageEditorState.variables.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            PageEditorState.variables = newArray;
        }
    },
    setGoogleAnalytics: () => {
        PageEditorState.config.googleAnalytics = true;
        PageEditorState.emitUpdate();
    }
});

export default PageEditorState;