import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import AssetManagerState from "../../State/AssetManagerState";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Refresh from "@material-ui/icons/Refresh";
import Folder from "@material-ui/icons/Folder";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileIcon from "../AssetManager/FileIcon";
import Divider from "@material-ui/core/Divider";

class AssetManagerOverlay extends Component{

    handleSelect = (filename) => {
        AssetManagerState.publicUrlCallback = (url) => {
            AssetManagerState.currentVariable.value = url;
            this.handleClose();
        };
        AssetManagerState.requestPublicUrl(filename);
    };

    handleClose = () => {
        AssetManagerState.isOverlayOpen = false;
    };

    render(){
        const currentDir = AssetManagerState.getCurrentDirectory();
        const folders = AssetManagerState.getCurrentDirectory().CommonPrefixes ?? [];
        const files = AssetManagerState.getCurrentDirectory().Contents ?? [];

        return (
            <Dialog onClose={() => this.handleClose()} open={AssetManagerState.isOverlayOpen}>
                <DialogTitle>Select an Asset</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{paddingLeft: 10, paddingTop: 7}}>
                                <Button onClick={() => AssetManagerState.previousDirectory()}><ArrowLeft /></Button>
                                <Button onClick={() => AssetManagerState.nextDirectory()}><ArrowRight /></Button>
                                <Button onClick={() => AssetManagerState.refresh()}><Refresh /></Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Current Directory"
                                value={AssetManagerState.getCurrentDirectory().Prefix ?? '/'}
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Folder />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <List component="nav" aria-label="main mailbox folders">
                                {folders.map((folder, index) => (
                                    <ListItem key={index} button onClick={() => AssetManagerState.changeDir((currentDir.Prefix ?? '/') + folder.Prefix.replace(currentDir.Prefix, ''))}>
                                        <ListItemIcon>
                                            <Folder />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={folder.Prefix.replace(currentDir.Prefix, '').replace('/', '')}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <List component="nav" aria-label="main mailbox folders">
                                {files.map((file, index) => (
                                    <>
                                        {file.Key !== '/' && file.Key.replace(currentDir.Prefix, '') !== '.folder' &&
                                            <ListItem button onClick={() => this.handleSelect(file.Key)}>
                                                <ListItemIcon>
                                                    <FileIcon filename={file.Key} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={file.Key.replace(currentDir.Prefix, '')}
                                                />
                                            </ListItem>
                                        }
                                    </>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AssetManagerOverlay);