import React, {Component} from "react";
import {view} from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {camelToTitle} from "../../../lib";

class TemplateSize extends Component{

    state = {
        xs: '12',
        sm: '12',
        md: '12',
        lg: '12'
    }

    componentDidMount() {
        console.log('TemplateSizeProps', this.props);

        const {value} = this.props;
        this.setState({
            xs: value.xs,
            sm: value.sm,
            md: value.md,
            lg: value.lg
        })
    }

    onChange(){
        this.props.onChange(this.state);
    }

    renderSelect(label, id, onChange, value){
        return (
            <FormControl fullWidth>
                <InputLabel id={`${id}-label`}>{label}</InputLabel>
                <Select id={id} labelId={`${id}-label`} value={value} onChange={(event) => onChange(event.target.value)}>
                    <MenuItem value="1">1 / 12 (8.3%)</MenuItem>
                    <MenuItem value="2">2 / 12 (16.6%)</MenuItem>
                    <MenuItem value="3">3 / 12 (25%)</MenuItem>
                    <MenuItem value="4">4 / 12 (33.3%)</MenuItem>
                    <MenuItem value="5">5 / 12 (41.6%)</MenuItem>
                    <MenuItem value="6">6 / 12 (50%)</MenuItem>
                    <MenuItem value="7">7 / 12 (58.3%)</MenuItem>
                    <MenuItem value="8">8 / 12 (66.6%)</MenuItem>
                    <MenuItem value="9">9 / 12 (75%)</MenuItem>
                    <MenuItem value="10">10 / 12 (83.3%)</MenuItem>
                    <MenuItem value="11">11 / 12 (91.6%)</MenuItem>
                    <MenuItem value="12">12 / 12 (100%)</MenuItem>
                </Select>
            </FormControl>
        );
    }

    render(){
        return(
            <div style={{borderBottom: '1px #444 solid', paddingBottom: 10, marginBottom: 10}}>
                {camelToTitle(this.props.label)}
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        {this.renderSelect('Extra Small Size', 'xs-width', (value) => { this.setState({xs: value}, () => this.onChange()); }, this.state.xs)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {this.renderSelect('Small Size', 'sm-width', (value) => { this.setState({sm: value}, () => this.onChange()); }, this.state.sm)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {this.renderSelect('Medium Size', 'md-width', (value) => { this.setState({md: value}, () => this.onChange()); }, this.state.md)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {this.renderSelect('Large Size', 'lg-width', (value) => { this.setState({lg: value}, () => this.onChange()); }, this.state.lg)}
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default view(TemplateSize)