import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state';
import Button from "@material-ui/core/Button";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ComponentEditorState from "../../State/ComponentEditorState";

class AbandonComponentChanges extends Component {

    state = {
        dialogOpen: false
    }

    onConfirm = async () => {
        await ComponentEditorState.revertComponent();
        this.setState({
            dialogOpen: false
        })
    }

    render() {
        return (
            <>
                <Button
                    onClick={() => this.setState({dialogOpen: true})}
                    color='secondary'
                    style={{ float: 'right' }}
                >Abandon Changes</Button>

                <Dialog open={this.state.dialogOpen}>
                    <DialogTitle>Abandon Changes?</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to abandon your changes to this component?
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus color='primary' onClick={() => this.setState({dialogOpen: false})}>Keep Changes</Button>
                        <Button onClick={this.onConfirm} color='secondary'>Abandon Changes</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default view(AbandonComponentChanges);