/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import MaterialTextField from "@material-ui/core/TextField";
import { camelToTitle } from "../../../lib";

class TextField extends Component {

    state = {
        target: null
    }

    cursor;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.target) {
            this.state.target.selectionStart = this.cursor;
            this.state.target.selectionEnd = this.cursor;
        }

    }

    render() {
        const { value, defaultValue, onChange, label, isdisabled = false } = this.props;

        return (
            <MaterialTextField
                {...this.props}
                className={(label === 'weeklyText' ? 'd-none' : '')}
                margin='dense'
                label={camelToTitle(label)}
                type='text'
                fullWidth
                variant={Boolean(isdisabled) ? "filled" : "standard"}
                inputProps={{ disabled: Boolean(isdisabled) }}
                value={value || defaultValue}
                onChange={(event) => {
                    this.cursor = event.target.selectionStart;
                    this.setState({
                        target: event.target
                    });
                    onChange(event.target.value)
                }}
            />
        );
    }

}

export default view(TextField);