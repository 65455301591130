import { store } from "@risingstack/react-easy-state";

const AddFieldState = store({
    isOpen: false,
    isThankYouOpen: false,
    value: 0,
    thankYouValue: 0,
    object: 0,
    objectType: 'page',
    open: (object, objectType = 'page') => {
        AddFieldState.object = object;
        AddFieldState.objectType = objectType;
        AddFieldState.isOpen = true;
    },
    close: () => {
        AddFieldState.isOpen = false;
    },
    toggleOpen: () => {
        AddFieldState.isOpen = !AddFieldState.isOpen
    },
    openThankYou: () => {
        AddFieldState.isThankYouOpen = true;
    },
    closeThankYou: () => {
        AddFieldState.isThankYouOpen = false;
    },
    toggleThankYou: () => {
        AddFieldState.isThankYouOpen = !AddFieldState.isThankYouOpen;
    }
});

export default AddFieldState;