import BaseSocket from "./BaseSocket";
import {getIdToken} from "../lib/auth";
import ClientState from "../State/ClientState";

const PageListSocket = {
    socket: null,
    init: () => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }

        const channel = ClientState.currentClient || Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

        const page = BaseSocket.socket.subscribe(`pageList:${channel}`);
        page.on('update', function(data){
            console.log(data);
        });
        PageListSocket.socket = page;
        PageListSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        PageListSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if(PageListSocket.socket){
            if(!client){
                client = ClientState.currentClient;
            }
            if(typeof callback === 'function'){
                PageListSocket.socket.on('clientSet', () => callback());
            }
            PageListSocket.socket.emit('setClient', client);
        } else if(typeof callback === 'function'){
            callback();
        }
    }
};

export default PageListSocket;