/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import AddFieldState from "../../State/AddFieldState";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ComponentTypes from "../../State/ComponentTypes";
import PageEditorState from "../../State/FormPageEditorState";
import Divider from "@material-ui/core/Divider";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

let tmpCategoryList = [];
class AddField extends Component {

    state = {
        currentTab: 0,
        addFiledsArray: [],
        checked: [],
        tmpFieldArray: []
    }

    handleClose = () => {
        tmpCategoryList = [];
        AddFieldState.close();
    };

    componentDidMount() {
        tmpCategoryList = [];
        this.updateTabColor();
    }

    componentDidUpdate() {
        this.updateTabColor();
    }

    updateTabColor = () => {
        tmpCategoryList = [];
        this.props.pages.length > 0 && this.props.pages.map((page, index) => {
            page.components.map((field, index) => {
                if (!tmpCategoryList.includes(field.category))
                    tmpCategoryList.push(field.category);
            });
        });

        this.props.modals.length > 0 && this.props.modals.map((model, index) => {
            model.components.map((field, index) => {
                if (!tmpCategoryList.includes(field.category))
                    tmpCategoryList.push(field.category);
            });
        });

    }

    onFieldChecked = (value) => {

        const currentIndex = this.state.checked.indexOf(value);


        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            let tmpField = { "object": AddFieldState.object, "value": AddFieldState.value, "objectType": AddFieldState.objectType };
            this.setState({ tmpFieldArray: [...this.state.tmpFieldArray, tmpField] });
        } else {
            newChecked.splice(currentIndex, 1);
            this.setState({ tmpFieldArray: this.state.tmpFieldArray.filter(item => item.value !== AddFieldState.value) })

        }
        this.updateTabColor();
        this.setState({ checked: newChecked });

    };

    handleFieldSubmit = () => {
        tmpCategoryList = [];
        this.state.tmpFieldArray.length > 0 && this.state.tmpFieldArray.map((field, index) => {
            PageEditorState.addComponent(field.object, field.value, field.objectType);
        });
        this.setState({ tmpFieldArray: [], checked: [] });
        this.handleClose();
    }

    a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    getTabs() {
        const tabs = [];
        if(AddFieldState.objectType === 'thankYouPage') {
            tabs.push("Tribute");
        } else {
            ComponentTypes.types.forEach(type => {
                if (typeof type.category === 'undefined') {
                    type.category = 'Uncategorized';
                }
                if (!tabs.includes(type.category)) {
                    tabs.push(type.category);
                }
            });
        }
        tabs.sort();
        return tabs;
    }

    getFieldList() {
        const tab = this.getTabs()[this.state.currentTab];
        const componentTypeList = [];


        let allFields = [];
        if(AddFieldState.objectType === 'thankYouPage') {
            this.props.thankYouPages && Object.keys(this.props.thankYouPages).length > 0 && this.props.thankYouPages.map((thankYouPage, index) => {
                thankYouPage.components.map((field, index) => {
                    if(field) {
                        allFields.push(field.id);
                    }
                });
            });    
        } else {
            this.props.pages.length > 0 && this.props.pages.map((page, index) => {
                page.components.map((field, index) => {
                    allFields.push(field.id);
                });
            });
    
            this.props.modals.length > 0 && this.props.modals.map((model, index) => {
                model.components.map((field, index) => {
                    allFields.push(field.id);
                });
            });
        }

        ComponentTypes.types.forEach((type, index) => {
            if (type.category === tab) {
                type.index = index;
                componentTypeList.push(type);
            }
        });

        componentTypeList.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });

        return (
            <List dense={true} className="add-field-dialog">
                {componentTypeList.map((componentType, index) => {
                    const labelId = `checkbox-list-label-${componentType.id}`;
                    return (
                        <ListItem key={index} className={allFields.includes(componentType.id) ? 'field-extis' : ''} style={{ cursor: 'pointer', marginBottom: "3px" }}
                            onClick={() => {
                                AddFieldState.value = componentType.index;
                                this.onFieldChecked(componentType.id);
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state.checked.indexOf(componentType.id) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={componentType.name}
                            />
                        </ListItem>
                    )
                })}
            </List>
        );
    }


    render() {
        return (
            <Dialog onClose={() => this.handleClose()} className="add-field-modal-parent" open={AddFieldState.isOpen}>
                <DialogTitle className="modal-title">
                    <div className="info-indicator" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h4 style={{ marginRight: '15px' }}>Add Field</h4>
                        <p><span className='info-icon'></span> <span className="info-text">Fields are already in use.</span></p>
                    </div>

                    {/* <div className="info-indicator">
                        <div>test</div><div>Field are already added in form</div>
                        <span className="info-icon"></span><span className='info-text'>Field are already added in form</span>
                    </div> */}
                </DialogTitle>
                <Divider />
                <DialogContent style={{ width: 600, height: 600 }}>
                    <div style={{ flexGrow: 1, display: 'flex' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            scrollButtons="auto"
                            value={this.state.currentTab}
                            onChange={(event, value) => this.setState({ currentTab: value })}
                            aria-label="Vertical tabs example"
                            style={{ borderRight: '1px solid #AAA' }}
                            className="tab-left"
                        >
                            {this.getTabs().map((tab, index) => (
                                <Tab label={tab} key={index} {...this.a11yProps(index)} className={tmpCategoryList.includes(tab) ? 'field-extis' : ''} />
                            ))}
                        </Tabs>
                        <div
                            role="tabpanel"
                            className="tab-right"
                            id={`vertical-tabpanel-${this.state.currentTab}`}
                            aria-labelledby={`vertical-tab-${this.state.currentTab}`}
                        >
                            {this.getFieldList()}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleFieldSubmit()} color='primary'>Ok</Button>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AddField);