import { store } from '@risingstack/react-easy-state';
import ComponentTypes from "./ComponentTypes";
import _ from "lodash";
import FormSocket from "../Sockets/FormSocket";
import CustomFieldListState from "./CustomFieldListState";
import PageEditorState from "./FormPageEditorState";
import NotificationState from "./NotificationState";
import ValidationFieldState from "./ValidationFieldState";
import hostname from "../lib/hostname";
import { getClientInitials, spaceWithUnderscore, manageVariableArray } from "../lib";
import axios from 'axios';
import ClientState from '../State/ClientState';
import GlobalSettingsState from "./GlobalSettingsState";
import EditFieldState from './EditFieldState';

const FormPageEditorState = store({
    formId: 0,
    pages: [],
    modals: [],
    thankYouPage:[],
    confirmationFields: [],
    uniqueComponents: {},
    customCSS: '',
    customJS: '',
    paymentProviders: {},
    socket: null,
    variables: [],
    options: {},
    triggers: [],
    triggerDependencies: {componentList: [], modalList: [], sectionList: []},
    enableDeleteButton: false,
    isDependencyLoading: false,
    rerender: null,
    recaptcha: true,
    DISSetting: false,
    DISRules: [],
    formName: '',
    isLoading: false,
    missingFields: [],
    errorAfterNAttempsSettings: {
        case: {
            field_id: "Lightbox-if-Donation-Error-after-n-attempts",
            field_value: "3",
            type: "fieldEquals",
            isDisabled: true
        },
        index: 0,
        triggerIndex: [],
        modalIndex: [],
        maxModals: 2,
        type: 'modal',
        componentTypeId: 'Lightbox if Donation Error after n attempts',
        customHTMLId: 'customHTML'
    },
    requiredFields: [{
        "name": "Billing First Name",
        "id": "billingFirstName"
    }, {
        "name": "Billing Last Name",
        "id": "billingLastName"
    }, {
        "name": "Billing Street Address Line 1",
        "id": "billingStreetAddress1"
    }, {
        "name": "Billing City",
        "id": "billingCity"
    }, {
        "name": "Billing State",
        "id": "billingState"
    }, {
        "name": "Billing Zip",
        "id": "billingZip"
    }, {
        "name": "Billing Phone",
        "id": "billingPhone"
    }, {
        "name": "Billing Email",
        "id": "billingEmail"
    }, {
        "name": "Donation Amount",
        "id": "giftArrayBtn"
    }, {
        "name": "Payment Info",
        "id": "paymentInfo"
    }],
    versions: {},
    fixedCustomFieldsArray: ['companyMatching', 'companyMatchingName', 'companyDonor', 'companyDonorName', 'giftNotes', 'anonymous', 'billingCountry', 'tributeCountry'],
    fixedCustomFieldsJSONValues: {
        companyMatching: 'OrgMatch',
        companyMatchingName: 'OrgName',
        companyDonor: 'CompanyGift',
        companyDonorName: 'CompanyName',
        giftNotes: 'gift_notes',
        anonymous: 'anonymous',
        billingCountry: 'country',
        tributeCountry: 'tributeCountry'
    },
    setForm: (formId) => {
        FormPageEditorState.formId = formId;
        FormPageEditorState.initSocket(formId);
        FormPageEditorState.receiveUpdate();
    },
    addPage: (name, pageType = 'section') => {
        FormPageEditorState.pages.push({
            name: name,
            components: [],
            pageType: pageType
        });
        FormPageEditorState.emitUpdate();
    },
    addModal: (name) => {
        FormPageEditorState.modals.push({
            name: name,
            id: FormPageEditorState.getRandomId("modal", "_"),
            triggers: [],
            components: []
        });
        FormPageEditorState.emitUpdate();
    },
    addModalErrorAfterNAttemps: (name, triggerIndex) => {
        let modalId = FormPageEditorState.getRandomId("modal", "_");
        FormPageEditorState.modals.push({
            name: name,
            id: modalId,
            triggers: [triggerIndex],
            components: []
        });
        return modalId;
    },
    setObjects: (objects) => {
        FormPageEditorState.pages = objects.pages;
        FormPageEditorState.modals = objects.modals;
        FormPageEditorState.emitUpdate();
    },
    addComponent: async (object, component, objectType = 'page') => {
        const componentData = {
            ...ComponentTypes.types[component],
            options: []
        };

        if(ComponentTypes.types[component].id === 'Lightbox-if-Donation-Error-after-n-attempts') {
            componentData.triggerIndex = [];
            componentData.modalIndex = [];
        }
        
        let status = process.env.REACT_APP_NODE_ENV === 'development' ? 'preview' : 'live';
        let formData = new FormData();
        let isFixedCustomField = false;

        // eslint-disable-next-line array-callback-return
        ComponentTypes.types[component].options.map((optionData) => {
            const newOptionData = {
                field: optionData.field,
            };
            
            if(ComponentTypes.types[component].id === 'Lightbox-if-Donation-Error-after-n-attempts' 
                && optionData.category 
                && ["Triggers", "Modals"].includes(optionData.category)
            ){
                // Don't create triggers and modals if Lightbox component.
                newOptionData.value = [];
            } else {
                if (optionData.default) {
                    newOptionData.value = optionData.default;
                }
                if (optionData.value) {
                    newOptionData.value = optionData.value;
                }
                if (optionData.values) {
                    newOptionData.values = optionData.values;
                }
                if (optionData.defaultValues) {
                    newOptionData.value = optionData.defaultValues;
                }
            }

            // Set the formData for store fixed custom fields in client's specific custom_fields.json
            if(optionData.field === 'name' && FormPageEditorState.fixedCustomFieldsArray.includes(optionData.default)) {
                formData.append('acronym', getClientInitials(ClientState.currentClient));
                formData.append('fieldName', FormPageEditorState.fixedCustomFieldsJSONValues[optionData.default]);
                formData.append('status', status);
                isFixedCustomField = true;
            }
            componentData.options.push(newOptionData);
        });
        
        // Callig the API for store fixed custom fields in client's specific custom_fields.json
        if(isFixedCustomField) {
            const apiHost = hostname.creativeAPI();
            const url = `${apiHost}/creatives/createCustomFieldFromFormBuilder`;
            await axios.post(url, formData);
        }

        if (FormPageEditorState.uniqueComponents[ComponentTypes.types[component].id]) {
            FormPageEditorState.uniqueComponents[ComponentTypes.types[component].id]++;
        } else {
            FormPageEditorState.uniqueComponents[ComponentTypes.types[component].id] = 1;
        }

        componentData.dataId = componentData.id + "_" + FormPageEditorState.uniqueComponents[ComponentTypes.types[component].id];

        if (objectType === 'page') {
            FormPageEditorState.pages[object].components.push(componentData);
        } else if (objectType === 'modal') {
            // Set CustomHTML for Lightbox-if-Donation-Error-after-n-attempts
            let modalIfLIDEANA = Number.isInteger(object) ? FormPageEditorState.modals[object] : FormPageEditorState.modals.find(obj => obj.id === object);
            if (modalIfLIDEANA.name.includes(FormPageEditorState.errorAfterNAttempsSettings.case.field_id)) {
                // Search for option "hideOnStoredClient"
                var search = "hideOnStoredClient";
                let index = _.findIndex(componentData.options, function (o) { return o.field === search; });
                componentData.options[index].value = "No";
                // console.log("!!!-FormPageEditorState.js->addComponent:componentData", componentData);
            }
            if(Number.isInteger(object)) {
                FormPageEditorState.modals[object].components.push(componentData);
            } else {
                FormPageEditorState.modals = FormPageEditorState.modals.map( (modal) => {
                    if(modal.id === object) {
                        modal.components.push(componentData)
                    }
                    return modal;
                })
            }
            // FormPageEditorState.modals[object].components.push(componentData);
        } else if(objectType === 'thankYouPage') {
            FormPageEditorState.thankYouPage[object].components.push(componentData);
        }
        FormPageEditorState.handleFieldErrorAttempt(object, ComponentTypes.types[component], componentData);
        FormPageEditorState.emitUpdate();
    },
    setComponents: (object, components, objectType = 'page') => {
        if (objectType === 'page') {
            FormPageEditorState.pages[object].components = components;
        } else if (objectType === 'modal') {
            FormPageEditorState.modals = FormPageEditorState.modals.map( (modal, index) => {
                if(index === object) {
                    modal.components = components
                }
                return modal;
            })
            // FormPageEditorState.modals[object].components = components;
        } else if (objectType === 'thankYouPage') {
            FormPageEditorState.thankYouPage[object].components = components;
        }

        FormPageEditorState.emitUpdate();
    },
    addCustomComponent: (object, component, objectType = 'page') => {
        const componentData = {
            ...CustomFieldListState.data[component].draft_state,
            options: []
        };
        // eslint-disable-next-line array-callback-return
        CustomFieldListState.data[component].draft_state.options.map((optionData) => {
            componentData.options.push({
                ...optionData,
                value: optionData.value || optionData.default
            })
        });
        if (FormPageEditorState.uniqueComponents[CustomFieldListState.data[component].draft_state.id]) {
            FormPageEditorState.uniqueComponents[CustomFieldListState.data[component].draft_state.id]++;
        } else {
            FormPageEditorState.uniqueComponents[CustomFieldListState.data[component].draft_state.id] = 1;
        }
        if (objectType === 'page') {
            FormPageEditorState.pages[object].components.push(componentData);
        } else if (objectType === 'modal') {
            FormPageEditorState.modals = FormPageEditorState.modals.map( (modal) => {
                if(modal.id === object) {
                    modal.components.push(componentData)
                }
                return modal;
            })
            // FormPageEditorState.modals[object].components.push(componentData);
        }
        FormPageEditorState.emitUpdate();
    },
    deleteComponent: (object, component, objectType = 'page') => {

        let component_id = "";
        if(objectType === 'page') {
            component_id = (FormPageEditorState.pages[object].components[component].id === "thankYouPageCustomHTML" ? FormPageEditorState.pages[object].components[component].dataId : FormPageEditorState.pages[object].components[component].id);
        } else if(objectType === 'modal') {
            component_id = (FormPageEditorState.modals[object].components[component].id === "thankYouPageCustomHTML" ? FormPageEditorState.modals[object].components[component].dataId : FormPageEditorState.modals[object].components[component].id);
        } else if(objectType === 'thankYouPage') {
            component_id = (FormPageEditorState.thankYouPage[object].components[component].id === "thankYouPageCustomHTML" ? FormPageEditorState.thankYouPage[object].components[component].dataId : FormPageEditorState.thankYouPage[object].components[component].id);
        }

        if (objectType === 'page') {
            if (FormPageEditorState.confirmationFields.includes(component_id)) {
                let confirmationFieldIndex = FormPageEditorState.confirmationFields.indexOf(component_id);
                _.pullAt(FormPageEditorState.confirmationFields, [confirmationFieldIndex]);
            }

            // Also remove the field from ne thankYouPage object. 
            if(FormPageEditorState.thankYouPage.length > 0)
            {
                FormPageEditorState.thankYouPage.map((section) =>{
                    if(section.pageType === 'readonly') {
                        let readOnlyFieldIndex = section.components.indexOf(component_id);
                        _.pullAt(section.components, [readOnlyFieldIndex]);
                    }
                    return section;
                });
            }

            if (FormPageEditorState.uniqueComponents[FormPageEditorState.pages[object].components[component].id]) {
                FormPageEditorState.uniqueComponents[FormPageEditorState.pages[object].components[component].id]--;
            } else {
                FormPageEditorState.uniqueComponents[FormPageEditorState.pages[object].components[component].id] = 0;
            }
            // delete if its a errorAlertAfterNAttemps field
            const valuesErrorNAttemps = FormPageEditorState.errorAfterNAttempsSettings;
            console.log('result: ', FormPageEditorState.pages[object].components[component].id, ": ", (valuesErrorNAttemps.case.field_id));
            
            if (FormPageEditorState.pages[object].components[component].id.includes(valuesErrorNAttemps.case.field_id)) {
                try {
                    let componentObj = FormPageEditorState.pages[object].components[component];
                    let triggerIndex = componentObj.triggerIndex;
                    let modalIndex = componentObj.modalIndex;

                    let indexToDelete = []
                    FormPageEditorState.triggers.map((indexId, trigger) => {
                        if(triggerIndex.includes(trigger.id)) {
                            indexToDelete.push(indexId);
                        }
                    });
                    _.pullAt(FormPageEditorState.triggers, [...indexToDelete]);
                    
                    indexToDelete = []
                    FormPageEditorState.modals.map((indexId, modal) => {
                        if(modalIndex.includes(modal.id)) {
                            indexToDelete.push(indexId);
                        }
                    });
                    _.pullAt(FormPageEditorState.modals, [...indexToDelete]);

                    // set the defaults
                    valuesErrorNAttemps.triggerIndex = [];
                    componentObj.triggerIndex = [];
                    valuesErrorNAttemps.modalIndex = [];
                    componentObj.modalIndex = [];

                    let search = valuesErrorNAttemps.componentTypeId;
                    let index = _.findIndex(ComponentTypes.types, function (o) { return o.name === search; });
                    const fieldObj = ComponentTypes.types[index];

                    search = 'triggers';
                    index = _.findIndex(fieldObj.options, function (o) { return o.field === search; });
                    fieldObj.options[index].value = [];

                    search = 'modals';
                    index = _.findIndex(fieldObj.options, function (o) { return o.field === search; });
                    fieldObj.options[index].value = [];

                    // last sanity check on triggers and modals
                    // console.log("!!!->deleteComponent:errorAfterNAttempsSettings:triggers", FormPageEditorState.triggers);
                    // console.log("!!!->deleteComponent:errorAfterNAttempsSettings:modals", FormPageEditorState.modals);
                    let tmpArrayHelper = [];
                    for (let index = 0; index < FormPageEditorState.triggers.length; index++) {
                        const element = FormPageEditorState.triggers[index];
                        if (element.name.includes(valuesErrorNAttemps.case.field_id)) {
                            // console.log("!!!->deleteComponent:errorAfterNAttempsSettings:triggerFound", element);
                            tmpArrayHelper.push(index);
                        }
                    }
                    _.pullAt(FormPageEditorState.triggers, [...tmpArrayHelper]);
                    tmpArrayHelper = [];

                    for (let index = 0; index < FormPageEditorState.modals.length; index++) {
                        const element = FormPageEditorState.modals[index];
                        if (element.name.includes(valuesErrorNAttemps.case.field_id)) {
                            // console.log("!!!->deleteComponent:errorAfterNAttempsSettings:modalFound", element);
                            tmpArrayHelper.push(index);
                        }
                    }
                    _.pullAt(FormPageEditorState.modals, [...tmpArrayHelper]);
                    tmpArrayHelper = [];

                    // FormPageEditorState.emitUpdate();
                } catch (error) {
                    console.log("error trying to delete trigger and modal", object, component, FormPageEditorState.pages[object].components[component].id, error);
                }
            }
            _.pullAt(FormPageEditorState.pages[object].components, [component]);
        } else if (objectType === 'modal') {

            if (FormPageEditorState.confirmationFields.includes(component_id)) {
                const confirmationFieldIndex = FormPageEditorState.confirmationFields.indexOf(component_id);
                _.pullAt(FormPageEditorState.confirmationFields, [confirmationFieldIndex]);
            }

            // Also remove the field from ne thankYouPage object. 
            if(FormPageEditorState.thankYouPage.length > 0)
            {
                FormPageEditorState.thankYouPage.map((section) =>{
                    if(section.pageType === 'readonly') {
                        let readOnlyFieldIndex = section.components.indexOf(component_id);
                        _.pullAt(section.components, [readOnlyFieldIndex]);
                    }
                    return section;
                });
            }

            let indexOfModal = object;
            if(!Number.isInteger(object)) {
                indexOfModal = FormPageEditorState.modals.map((i, modal) => {
                    if(modal.id === object) {
                        return i;
                    }
                });
            }

            if (FormPageEditorState.uniqueComponents[FormPageEditorState.modals[indexOfModal].components[component].id]) {
                FormPageEditorState.uniqueComponents[FormPageEditorState.modals[indexOfModal].components[component].id]--;
            } else {
                FormPageEditorState.uniqueComponents[FormPageEditorState.modals[indexOfModal].components[component].id] = 0;
            }
            _.pullAt(FormPageEditorState.modals[indexOfModal].components, [component]);
        }  else if (objectType === 'thankYouPage') {

            if (FormPageEditorState.confirmationFields.includes(component_id)) {
                const confirmationFieldIndex = FormPageEditorState.confirmationFields.indexOf(component_id);
                _.pullAt(FormPageEditorState.confirmationFields, [confirmationFieldIndex]);
            }

            // Also remove the field from ne thankYouPage object. 
            if(FormPageEditorState.thankYouPage.length > 0)
            {
                FormPageEditorState.thankYouPage.map((section) =>{
                    if(section.pageType === 'readonly') {
                        let readOnlyFieldIndex = section.components.indexOf(component_id);
                        _.pullAt(section.components, [readOnlyFieldIndex]);
                    }
                    return section;
                });
            }

            let indexOfThankYouPage = object;
            if(!Number.isInteger(object)) {
                indexOfThankYouPage = FormPageEditorState.thankYouPage.map((i, tyPage) => {
                    if(tyPage.id === object) {
                        return i;
                    }
                });
            }

            if (FormPageEditorState.uniqueComponents[FormPageEditorState.thankYouPage[indexOfThankYouPage].components[component].id]) {
                FormPageEditorState.uniqueComponents[FormPageEditorState.thankYouPage[indexOfThankYouPage].components[component].id]--;
            } else {
                FormPageEditorState.uniqueComponents[FormPageEditorState.thankYouPage[indexOfThankYouPage].components[component].id] = 0;
            }
            _.pullAt(FormPageEditorState.thankYouPage[indexOfThankYouPage].components, [component]);
        }


        FormPageEditorState.emitUpdate();
    },
    addThankYouField: (fieldId) => {
        if (!FormPageEditorState.confirmationFields.includes(fieldId)) {
            FormPageEditorState.confirmationFields.push(fieldId);
            if(FormPageEditorState.thankYouPage.length > 0) {
                FormPageEditorState.thankYouPage.map((section) =>{
                    if(section.pageType === 'readonly') {
                        section.components = FormPageEditorState.confirmationFields;
                    }
                    return section;
                })
            }
            FormPageEditorState.emitUpdate();
        }
    },
    setThankYouFields: (fields) => {
        FormPageEditorState.confirmationFields = fields;
        if(FormPageEditorState.thankYouPage.length > 0) {
            FormPageEditorState.thankYouPage.map((section) =>{
                if(section.pageType === 'readonly') {
                    section.components = FormPageEditorState.confirmationFields;
                }
                return section;
            })
        }
        FormPageEditorState.emitUpdate();
    },
    deleteThankYouField: (index) => {
        if(FormPageEditorState.thankYouPage.length > 0)
        {
            FormPageEditorState.thankYouPage.map((section) =>{
                if(section.pageType === 'readonly') {
                    _.pullAt(section.components, [index]);            
                }
                return section;
            });
        }
        _.pullAt(FormPageEditorState.confirmationFields, [index]);
        FormPageEditorState.emitUpdate();
    },
    initSocket: (formId) => {
        if (formId && !FormPageEditorState.socket) {
            FormSocket.init(formId);
            FormPageEditorState.socket = FormSocket.socket;
        }
    },
    publishForm: async () => {
        // check if at least one payment processor its defined
        let paymentProvidersDefined = 0;
        let i = 0;
        let paymentProvidersObj = GlobalSettingsState.paymentProviders.live;
        // console.log(paymentProvidersObj);
        for (var key in paymentProvidersObj) {
            if (paymentProvidersObj.hasOwnProperty(key)) {
                if (paymentProvidersObj[key].type === 'none') {
                    console.log(paymentProvidersObj[key]);
                    paymentProvidersDefined++;
                }
            }
            i++;
        }
        // console.log(paymentProvidersObj[key]);
        if (paymentProvidersObj === {} || i === paymentProvidersDefined) {
            NotificationState.open('There needs to be a payment processor setup to publish forms! Please set your payment processor within Global Settings.', 'Form Publish Warning');
        } else {
            try {
                // move the GlobalSettingsState.paymentProviders.live to FormPageEditorState.paymentProviders ;
                FormPageEditorState.paymentProviders = GlobalSettingsState.paymentProviders.live;

                // check all the required fields before publish
                const fieldList = FormPageEditorState.getFieldList();
                const diff = _.differenceBy(FormPageEditorState.requiredFields, fieldList, 'id');
                if (diff.length > 0) {
                    FormPageEditorState.missingFields = diff;
                    ValidationFieldState.open();
                    return false;
                }

                const apiHost = hostname.creativeAPI();
                FormPageEditorState.isLoading = true;

                let postData = {};
                let env = "production";
                if (window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)) {
                    env = "localhost"
                }
                else {
                    if (process.env.REACT_APP_STAGE === "true" || process.env.REACT_APP_STAGE === true) {
                        env = "stage";
                    }
                }

                postData = { ...FormPageEditorState.options, googleRecaptcha: FormPageEditorState.recaptcha ? "on" : "off", status: "live", environment: env }

                // Update the CREATIVE file to DMC
                let formData = new FormData();
                formData.append('name', spaceWithUnderscore(FormPageEditorState.formName));
                formData.append('acronym', getClientInitials(ClientState.currentClient));
                formData.append('createdType', "FormBuilder");
                formData.append('data', JSON.stringify(postData));

                let url = `${apiHost}/creatives/updateCreativeFromFormBuilder`;

                const { data } = await axios.post(url, formData);

                if (data.status === "success") {
                    FormPageEditorState.isLoading = false;
                    await FormPageEditorState.socket.emit('publishForm');
                    NotificationState.open('The form has been successfully published!', 'Form Published');
                } else {
                    FormPageEditorState.isLoading = false;
                    NotificationState.open(data.message, 'Form Published Failed');
                }
                // set the FormPageEditorState.paymentProviders back to preview to avoid issues on active preview forms 
                FormPageEditorState.paymentProviders = GlobalSettingsState.paymentProviders.preview || {};

            } catch (e) {
                NotificationState.open(e.message, 'Form Published Failed');
            }
        }

    },
    revertForm: async () => {
        await FormPageEditorState.socket.emit('revertForm');
        NotificationState.open('The form has been successfully reverted!', 'Form Reverted');
    },
    emitUpdate: () => {
        FormPageEditorState.variables = manageVariableArray(FormPageEditorState.variables);        
        FormPageEditorState.socket.emit('clientUpdate', {
            pages: FormPageEditorState.pages,
            modals: FormPageEditorState.modals,
            thankYouPage: FormPageEditorState.thankYouPage,
            confirmationFields: FormPageEditorState.confirmationFields,
            uniqueComponents: FormPageEditorState.uniqueComponents,
            customCSS: FormPageEditorState.customCSS,
            customJS: FormPageEditorState.customJS,
            variables: FormPageEditorState.variables,
            paymentProviders: FormPageEditorState.paymentProviders,
            triggers: FormPageEditorState.triggers,
            options: FormPageEditorState.options,
            recaptcha: FormPageEditorState.recaptcha,
            DISSetting: FormPageEditorState.DISSetting,
            DISRules: FormPageEditorState.DISRules,
            errorAfterNAttempsSettings: FormPageEditorState.errorAfterNAttempsSettings,
            versions: FormPageEditorState.versions,
        });
        FormPageEditorState.doRerender()
    },
    receiveUpdate: () => {
        FormPageEditorState.socket.on('serverUpdate', (data) => {
            FormPageEditorState.formName = data.formName;
            FormPageEditorState.pages = data.pages;
            FormPageEditorState.modals = data.modals || [];
            FormPageEditorState.thankYouPage = data.thankYouPage || [];
            FormPageEditorState.confirmationFields = data.confirmationFields;
            FormPageEditorState.uniqueComponents = data.uniqueComponents;
            FormPageEditorState.customCSS = data.customCSS;
            FormPageEditorState.customJS = data.customJS;
            FormPageEditorState.variables = data.variables || [];
            FormPageEditorState.paymentProviders = data.paymentProviders || {};
            FormPageEditorState.triggers = data.triggers || [];
            FormPageEditorState.options = data.options || {};
            FormPageEditorState.recaptcha = data.recaptcha || false;
            FormPageEditorState.DISSetting = data.DISSetting || false;
            FormPageEditorState.DISRules = data.DISRules || [];
            FormPageEditorState.doRerender();
            FormPageEditorState.versions = data.versions || null;
        });

        FormPageEditorState.socket.on('onGetTriggerDependencies', (data) => {
            FormPageEditorState.isDependencyLoading = false;
            FormPageEditorState.triggerDependencies = data;
            FormPageEditorState.enableDeleteButton = data.componentList.length || data.modalList.length || data.sectionList.length;
        })
    },
    addVariable: () => {
        FormPageEditorState.variables.push({
            key: '',
            value: '',
            id: 'new'
        });
    },
    removeVariable: (index) => {
        if (FormPageEditorState.variables.length < 2) {
            FormPageEditorState.variables = [];
        } else {
            const newArray = FormPageEditorState.variables.slice(0, index);
            FormPageEditorState.variables.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            FormPageEditorState.variables = newArray;
        }
    },
    createTrigger: (name) => {
        FormPageEditorState.triggers.push({
            name: name,
            id: FormPageEditorState.getRandomId("trigger", "_"),
            cases: [],
            action: 'none',
            inverseAction: 'none',
            defaultAction: 'none'
        });
        FormPageEditorState.emitUpdate();
    },
    createTriggerErrorAfterNAttemps: (object, name, conditions, fieldObj) => {
        if (conditions !== '') {
            name = name + '-' + conditions;
        } else {
            FormPageEditorState.errorAfterNAttempsSettings.triggerIndex = [];
            FormPageEditorState.errorAfterNAttempsSettings.modalIndex = [];
        }
        const valuesErrorNAttemps = FormPageEditorState.errorAfterNAttempsSettings;
        let field_id = valuesErrorNAttemps.case.field_id;
        let triggerId = FormPageEditorState.getRandomId("trigger", "_");
        FormPageEditorState.triggers.push({
            name: name,
            id: triggerId,
            cases: [valuesErrorNAttemps.case],
            action: 'show',
            inverseAction: 'hide',
            defaultAction: 'none',
            isDisabledToEdit: true,
            expression: (field_id.replace(/-/g, "_") + ' == "' + valuesErrorNAttemps.case.field_value + '"')
        });
        
        let modalIndex = FormPageEditorState.addModalErrorAfterNAttemps(name, triggerId);
    
        var search = FormPageEditorState.errorAfterNAttempsSettings.componentTypeId;
        let indexObj = _.findIndex(FormPageEditorState.pages[object].components, function (o) { return o.name === search; });
        let componentObj = FormPageEditorState.pages[object].components[indexObj];
        
        search = 'triggers';
        let index = _.findIndex(componentObj.options, function (o) { return o.field === search; });
        fieldObj.options[index].value.push(triggerId);
    
        search = 'modals';
        index = _.findIndex(componentObj.options, function (o) { return o.field === search; });
        fieldObj.options[index].value.push(modalIndex);
    
        // console.log("FormPageEditorState.js->createTriggerErrorAfterNAttemps:componentObj", fieldObj);
    
        FormPageEditorState.errorAfterNAttempsSettings.triggerIndex.push(triggerId);
        FormPageEditorState.errorAfterNAttempsSettings.modalIndex.push(modalIndex);
    
        fieldObj.modalIndex.push(modalIndex);
        fieldObj.triggerIndex.push(triggerId);
        //agregar esos índices en el elmeneto como tal
    
        FormPageEditorState.pages[object].components[indexObj] = fieldObj;
    
        ComponentTypes.types.forEach((type, index) => {
            if (type.id === valuesErrorNAttemps.customHTMLId) {
                PageEditorState.addComponent(modalIndex, index, valuesErrorNAttemps.type);
            }
        });
        FormPageEditorState.emitUpdate();
    },
    removeTriggerErrorAfterNAttemps: (object, index) => {
        try {
            let search = FormPageEditorState.errorAfterNAttempsSettings.componentTypeId;
            let indexObj = _.findIndex(FormPageEditorState.pages[object].components, function (o) { return o.name === search; });
            const componentObj = FormPageEditorState.pages[object].components[indexObj];
            
            // Remove trigger and modal from triggers and modals object
            let indexTrigger = _.find(FormPageEditorState.triggers, function (o) { return o.name === componentObj.id + '-duo'; }).id;
            let indexModal = _.find(FormPageEditorState.modals, function (o) { return o.name === componentObj.id + '-duo'; }).id;
            
            FormPageEditorState.modals = FormPageEditorState.modals.filter((obj) => obj.id !== indexModal);
            FormPageEditorState.triggers = FormPageEditorState.triggers.filter((obj) => obj.id !== indexTrigger);
            
            // Remove triggers and modals from component triggerIndex and modalIndex array
            let indexToRemoveTrigger = componentObj.triggerIndex.indexOf(indexTrigger);
            let indexToRemoveModal = componentObj.modalIndex.indexOf(indexModal);

            _.pullAt(componentObj.triggerIndex, [indexToRemoveTrigger]);
            _.pullAt(componentObj.modalIndex, [indexToRemoveModal]);

            // Remove triggers and modals from component options value triggerIndex and modalIndex array
            let indexTriggerOption = _.findIndex(componentObj.options, function (o) { return o.field === 'triggers'; });
            let indexModalOption = _.findIndex(componentObj.options, function (o) { return o.field === 'modals'; });

            indexToRemoveTrigger = componentObj.options[indexTriggerOption].value.indexOf(indexTrigger);
            indexToRemoveModal = componentObj.options[indexModalOption].value.indexOf(indexModal);

            _.pullAt(componentObj.options[indexTriggerOption].value, [indexToRemoveTrigger]);
            _.pullAt(componentObj.options[indexModalOption].value, [indexToRemoveModal]);

            // Remove triggers and modals from errorAfterNAttempsSettings object's triggerIndex and modalIndex array
            indexToRemoveTrigger = FormPageEditorState.errorAfterNAttempsSettings.triggerIndex.indexOf(indexTrigger);
            indexToRemoveModal = FormPageEditorState.errorAfterNAttempsSettings.modalIndex.indexOf(indexModal);

            _.pullAt(FormPageEditorState.errorAfterNAttempsSettings.triggerIndex, [indexToRemoveTrigger]);
            _.pullAt(FormPageEditorState.errorAfterNAttempsSettings.modalIndex, [indexToRemoveModal]);

            // set the defaults
            FormPageEditorState.emitUpdate();
            EditFieldState.close();
        } catch (error) {
            // console.log("error trying to delete trigger and modal", object, component, FormPageEditorState.pages[object].components[component].id, error);
        }
    },
    removeTrigger: (id) => {
        const objWithIdIndex = FormPageEditorState.triggers.findIndex((obj) => obj.id === id);
        FormPageEditorState.triggers.splice(objWithIdIndex, 1);

        FormPageEditorState.emitUpdate();
    },
    setPaymentProvider(type, configObject) {
        FormPageEditorState.paymentProviders[type] = configObject;
        FormPageEditorState.emitUpdate();
    },
    setOption(option, value) {
        FormPageEditorState.options[option] = value;
        FormPageEditorState.emitUpdate();
    },
    setReCaptcha(value) {
        FormPageEditorState.recaptcha = value;
        FormPageEditorState.emitUpdate();
    },
    setDISSetting(value) {
        FormPageEditorState.DISSetting = value;
        FormPageEditorState.emitUpdate();
    },
    setDISRule(ruleList) {
        FormPageEditorState.DISRules = ruleList;
        FormPageEditorState.emitUpdate();
    },
    availableComponents: () => {

    },
    doRerender: () => {
        if (FormPageEditorState.rerender !== null) {
            FormPageEditorState.rerender();
        }
    },
    getFieldList() {

        const fields = [];
        const fieldIndex = [];

        FormPageEditorState.pages.forEach(page => {
            page.components.forEach(component => {
                if (
                    !fieldIndex.includes(component.id) // The field is not already in the field index
                ) {
                    fields.push({ id: component.id, name: component.customName || component.name });
                    fieldIndex.push(component.id);
                }
            });
        });

        FormPageEditorState.modals.forEach(modal => {
            modal.components.forEach(component => {
                if (
                    !fieldIndex.includes(component.id) // The field is not already in the field index
                ) {
                    fields.push({ id: component.id, name: component.customName || component.name });
                    fieldIndex.push(component.id);
                }
            });
        });

        return fields;
    },
    handleFieldErrorAttempt(conponentIndex, componentType, componentData) {
        if (componentType.id === FormPageEditorState.errorAfterNAttempsSettings.case.field_id) {
            FormPageEditorState.createTriggerErrorAfterNAttemps(conponentIndex, componentType.id, "", componentData);
        }
    },
    getTriggerDependencies(triggerId, formId) {
        FormPageEditorState.isDependencyLoading = true;
        FormPageEditorState.socket.emit('getTriggerDependencies', {
            index: triggerId,
            formId: formId
        });
    },
    getRandomId(prefix, saperator = "_") {
        let randomString = (Math.random() + 1).toString(36).substring(8);
        return prefix ? `${prefix}${saperator}${randomString}` : randomString;
    },
    setThankYouPageValues(value) {
        FormPageEditorState.thankYouPage = value;
    }
});

export default FormPageEditorState;