import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import PageList from "../../../Components/PageList/PageList";
import ModalList from "../../../Components/ModalList/ModalList";
import AddField from "../../../Components/Dialogs/AddField";
import FormPageEditorState from "../../../State/FormPageEditorState";
import AddPage from "../../../Components/Dialogs/AddPage";
import AddModal from "../../../Components/Dialogs/AddModal";
import EditPage from "../../../Components/Dialogs/EditPage";
import EditModal from "../../../Components/Dialogs/EditModal";
import FieldEdit from "../../../Components/Dialogs/FieldEdit";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../../Components/Template/TabPanel";
import CSSEditor from "../../../Components/Editors/CSSEditor";
import Button from "@material-ui/core/Button";
import JSEditor from "../../../Components/Editors/JSEditor";
import PreviewPage from "../../../Components/Buttons/PreviewPage";
import CreateNewState from "../../../State/CreateNewState";
import VariableEditor from "../../../Components/Editors/VariableEditor";
import UIState from "../../../State/UIState";
import AddCustomField from "../../../Components/Dialogs/AddCustomField";
import Divider from "@material-ui/core/Divider";
import AddThankYouField from "../../../Components/Dialogs/AddThankYouField";
import Typography from "@material-ui/core/Typography";
import ThankYouList from "../../../Components/PageList/ThankYouList";
import FormSettingsEditor from "../../../Components/Editors/FormSettingsEditor";
import TriggerListNew from "../../../Components/Triggers2/TriggerList";
import AddTrigger from "../../../Components/Dialogs/AddTrigger";
import AddTriggerState from "../../../State/AddTriggerState";
import { DragDropContext } from "react-beautiful-dnd";
import EditModalTriggers from "../../../Components/Dialogs/EditModalTriggers";
import PublishForm from "../../../Components/Buttons/PublishForm";
import AbandonFormChanges from "../../../Components/Buttons/AbandonFormChanges";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { downloadFile, updateVersion, getLastElement } from '../../../lib';
import EditPageTriggers from "../../../Components/Dialogs/EditPageTriggers";
import FormDISSettingsEditor from '../../../Components/Editors/FormDISSettingsEditor';
import GlobalSettingsState from "../../../State/GlobalSettingsState";

class EditForm extends Component {

    state = {
        tab: 0,
        tabs: ['formStep', 'thankYouPage', 'triggers', 'scss', 'js', 'variables', 'generalSettings', 'disSettings'],
        checkedCSSDownload: true,
        checkedJSDownload: true,
        latestVersion: null,
        enableDISSettings: false,
        globalSettingsLoaded: false
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        const { tab, tabs } = this.state;
        FormPageEditorState.setForm(params.id);
        CreateNewState.itemType = 'form';
        UIState.setPageTitle('Edit Form');
        let latestVersion = FormPageEditorState.versions !== null ? getLastElement(FormPageEditorState.versions[tabs[tab]]) : null;
        if (latestVersion) {
            this.setState({ latestVersion: latestVersion });
        }
        GlobalSettingsState.initSocket();
    }
    
    componentWillUnmount() {
        // set formPageEdiorState socket to close and null because of reInit/fetch new form data based on selection(FBC-439)
        FormPageEditorState.socket.close();
        FormPageEditorState.socket = null;
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { tab, tabs } = this.state;
        if (FormPageEditorState.versions !== null && getLastElement(FormPageEditorState.versions[tabs[tab]]) !== null && this.state.latestVersion !== getLastElement(FormPageEditorState.versions[tabs[tab]])) {
            this.setState({ latestVersion: getLastElement(FormPageEditorState.versions[tabs[tab]]) });
        }

        if(!this.state.globalSettingsLoaded) {
            let disSettingData = GlobalSettingsState.data.find((obj) => obj.config === "disSettings");
            if(disSettingData && disSettingData.value === '1') {
                this.setState({ enableDISSettings: true });
                this.setState( { globalSettingsLoaded: true });
            }
        }
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    handleTabChange = (event, tab) => {
        this.setState({
            tab,
            latestVersion: null
        })
    };

    handleSave = (fileType = '') => {
        const { tab, tabs, latestVersion } = this.state;

        if (tabs[tab] === 'scss' && this.state.checkedCSSDownload) {
            downloadFile('form', FormPageEditorState.formName, tabs[tab], FormPageEditorState.customCSS, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if (tabs[tab] === 'js' && this.state.checkedJSDownload) {
            downloadFile('form', FormPageEditorState.formName, tabs[tab], FormPageEditorState.customJS, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }

        FormPageEditorState.versions = updateVersion(tabs[tab], FormPageEditorState.versions);
        this.setState({ latestVersion: getLastElement(FormPageEditorState.versions[tabs[tab]]) });

        FormPageEditorState.emitUpdate();
    };

    getDroppableType(droppableId) {
        const droppableParts = droppableId.split(':');
         let droppableType = "page";
        if (droppableParts[1] === 'modal') {
            droppableType = "modal";
        } else if(droppableParts[1] === 'thankYouPage') {
            droppableType = "thankYouPage";
        }

        return droppableType;
    }

    reorderList = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    moveItem = (source, destination, droppableSource, droppableDestination, sourceObject, destinationObject, sourceType, destinationType) => {
        const sourceClone = Array.from(source);
        const destinationClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destinationClone.splice(droppableDestination.index, 0, removed);

        const pages = FormPageEditorState.pages;
        const modals = FormPageEditorState.modals;

        if (sourceType === 'page') {
            pages[sourceObject].components = sourceClone;
        } else if (sourceType === 'modal') {
            modals[sourceObject].components = sourceClone;
        }

        if (destinationType === 'page') {
            pages[destinationObject].components = destinationClone;
        } else if (destinationType === 'modal') {
            modals[destinationObject].components = destinationClone;
        }

        return {
            pages,
            modals
        };
    }

    onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const sourceDroppedParts = source.droppableId.split(':');
        const sourceObjectId = parseInt(sourceDroppedParts[2]);
        const DestinationDroppedParts = destination.droppableId.split(':');
        const destinationObjectId = parseInt(DestinationDroppedParts[2]);

        const sourceDroppableType = this.getDroppableType(source.droppableId);
        const destinationDroppableType = this.getDroppableType(destination.droppableId);

        if (source.droppableId === destination.droppableId) {

            let droppableObject = [];
            let isSetComponents = false;
            if(sourceDroppableType === 'page') {
                droppableObject = FormPageEditorState.pages[destinationObjectId].components;
                isSetComponents = true;
            } else if(sourceDroppableType === 'modal') {
                droppableObject = FormPageEditorState.modals[destinationObjectId].components;
                isSetComponents = true;
            } else if(sourceDroppableType === 'thankYouPage') {
                if(DestinationDroppedParts[3] === 'readonly') {
                    droppableObject = Array.from(FormPageEditorState.confirmationFields);
                    const reordered = Array.from(droppableObject);
                    const [removed] = reordered.splice(source.index, 1);
                    reordered.splice(destination.index, 0, removed);
                    FormPageEditorState.setThankYouFields(reordered);
                } else {
                    isSetComponents = true;
                    droppableObject = FormPageEditorState.thankYouPage[destinationObjectId].components;
                }
            }

            if(isSetComponents) {
                const components = this.reorderList(
                    droppableObject,
                    source.index,
                    destination.index
                );

                FormPageEditorState.setComponents(destinationObjectId, components, sourceDroppableType);
            }
        } else {
            
            let sourceComponents = [];
            if(sourceDroppableType === 'page') {
                sourceComponents = FormPageEditorState.pages[sourceObjectId].components;
            } else if(sourceDroppableType === 'modal') {
                sourceComponents = FormPageEditorState.modals[sourceObjectId].components;
            } else if(sourceDroppableType === 'thankYouPage') {
                if(sourceDroppedParts[3] === 'readonly') {
                    sourceComponents = Array.from(FormPageEditorState.confirmationFields);
                } else {
                    sourceComponents = FormPageEditorState.thankYouPage[sourceObjectId].components;
                }
            }

            let destinationComponents = [];
            if(destinationDroppableType === 'page') {
                destinationComponents = FormPageEditorState.pages[destinationObjectId].components;
            } else if(destinationDroppableType === 'modal') {
                destinationComponents = FormPageEditorState.modals[destinationObjectId].components;
            } else if(destinationDroppableType === 'thankYouPage') {
                if(DestinationDroppedParts[3] === 'readonly') {
                    destinationComponents = Array.from(FormPageEditorState.confirmationFields);
                } else {
                    destinationComponents = FormPageEditorState.thankYouPage[destinationObjectId].components;
                }
            }

            const result = this.moveItem(
                sourceComponents,
                destinationComponents,
                source,
                destination,
                sourceObjectId,
                destinationObjectId,
                sourceDroppableType,
                destinationDroppableType
            )

            FormPageEditorState.setObjects(result);
        }

    }

    downloadFile = async (fileType) => {

        const { tab, tabs, latestVersion } = this.state;
        if (tabs[tab] === 'scss') {
            // I am assuming that "FormPageEditorState.customCSS" is an object and I wrote it to file as SCSS.
            downloadFile('form', FormPageEditorState.formName, tabs[tab], FormPageEditorState.customCSS, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if (tabs[tab] === 'js') {
            // I am assuming that "FormPageEditorState.customJS" is an object and I wrote it to file as JS.
            downloadFile('form', FormPageEditorState.formName, tabs[tab], FormPageEditorState.customJS, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
    }

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    render() {
        const { pages, modals, triggers, formName, confirmationFields } = FormPageEditorState;
        let { thankYouPage } = FormPageEditorState;

        if(!thankYouPage || Object.keys(thankYouPage).length === 0) {
            thankYouPage = [
                {
                    "name": "Thank You Page",
                    "components": confirmationFields,
                    "pageType": "readonly"
                },
                {
                    "name": "Tribute Gift",
                    "components": [],
                    "pageType": "section"
                }
            ];

            FormPageEditorState.setThankYouPageValues(thankYouPage);
        }

        const { history } = this.props;
        const { latestVersion } = this.state;
        let updatedDateTime = latestVersion ? latestVersion.updatedAt.split(" ") : "Not Updated";

        setTimeout(() => {
            if (FormPageEditorState.formName === "") {
                history.push('/');
            }
        }, 1000);

        let countThankYouComponents = 0;
        if (pages.length > 0) {
            pages.map((page) => {
                return page.components.filter((component) => {
                    if (component.id === "thankYouPageCustomHTML") {
                        return countThankYouComponents++;
                    } else {
                        return true;
                    }
                })
            });
        }

        return (
            <>
                <Paper style={{ padding: 15, margin: 20 }}>
                    <Typography variant='h6' gutterBottom>Form Name: {formName}</Typography>
                    <Tabs value={this.state.tab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                        <Tab label='Form Steps' {...this.a11yProps(0)} />
                        <Tab label='Thank You Page' {...this.a11yProps(1)} />
                        <Tab label='Triggers' {...this.a11yProps(2)} />
                        <Tab label='Custom CSS' {...this.a11yProps(3)} />
                        <Tab label='Custom Javascript' {...this.a11yProps(4)} />
                        <Tab label='Variables' {...this.a11yProps(5)} />
                        <Tab label='General Settings' {...this.a11yProps(6)} />
                        { this.state.enableDISSettings === true && (<Tab label='Identity' {...this.a11yProps(7)} />) }
                    </Tabs>
                    {/* Form Steps */}
                    <TabPanel value={this.state.tab} index={0}>
                        <PublishForm />
                        <AbandonFormChanges />
                        <PreviewPage />
                        <AddPage />
                        <AddModal />
                        <Divider />
                        <EditPage />
                        <EditModal />
                        <FieldEdit />
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h5 style={{ marginRight: '15px' }}>Elements</h5>
                                <p style={{ display: (countThankYouComponents === 0 ? "none" : "block") }}><span style={{ float: 'left', width: '30px', height: '20px', backgroundColor: '#f9a306', marginRight: '10px' }}></span> You can use this field for display on Thank You page.</p>
                            </div>
                            <PageList pages={pages} />
                            <h5>Modals</h5>
                            <ModalList modals={modals} />
                        </DragDropContext>
                        <AddField pages={pages} modals={modals} />
                        <AddCustomField />
                        <EditModalTriggers />
                        <EditPageTriggers />
                    </TabPanel>
                    {/* Thank You Page */}
                    <TabPanel value={this.state.tab} index={1}>
                        <PublishForm />
                        <PreviewPage />
                        <AddThankYouField />
                        <FieldEdit />
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h5 style={{ marginRight: '15px' }}>Elements</h5>
                                <p style={{ display: (countThankYouComponents === 0 ? "none" : "block") }}><span style={{ float: 'left', width: '30px', height: '20px', backgroundColor: '#f9a306', marginRight: '10px' }}></span> You can use this field for display on Thank You page.</p>
                            </div>
                            {/* <PageList pages={pages} /> */}
                            <ThankYouList thankYouPages={thankYouPage} />
                            {/* <h5>Modals</h5>
                            <ModalList modals={modals} /> */}
                        </DragDropContext>
                        <AddField pages={pages} modals={modals} thankYouPages={thankYouPage} />
                        {/* <Grid container spacing={2} justify='center'>
                            <Grid xs={12} md={6} lg={4} item>
                                <Card variant='outlined'>
                                    <CardContent>
                                        <Typography variant='h6' color='textSecondary' gutterBottom>Thank You Page</Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <ThankYouList />
                                    </CardContent>
                                    <Divider />
                                    <CardActions>
                                        <Button color='primary' size="small" onClick={() => AddFieldState.openThankYou()}>Add Field</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid> */}
                    </TabPanel>
                    <TabPanel value={this.state.tab} index={2}>
                        <PublishForm />
                        <PreviewPage />
                        <AddTrigger />
                        <Button color='primary' size="small" onClick={() => AddTriggerState.isOpen = true}>Create Trigger</Button>
                        <TriggerListNew triggers={triggers} />
                    </TabPanel>
                    {/* Custom CSS */}
                    <TabPanel value={this.state.tab} index={3}>
                        <PublishForm />
                        <PreviewPage />
                        <CSSEditor />
                        <Button onClick={() => this.handleSave('scss')} color='primary'>Save Changes</Button>
                        <FormControlLabel
                            style={{ marginLeft: "15px", marginRight: "0px" }}
                            control={
                                <Checkbox
                                    checked={this.state.checkedCSSDownload}
                                    onChange={this.handleChange}
                                    name="checkedCSSDownload"
                                    color="primary"
                                />
                            }
                        />
                        <Button onClick={() => this.downloadFile('scss')} color='primary'>Download CSS</Button>
                        <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                    </TabPanel>
                    {/* Custom JS */}
                    <TabPanel value={this.state.tab} index={4}>
                        <PublishForm />
                        <PreviewPage />
                        <JSEditor />
                        <Button onClick={() => this.handleSave('js')} color='primary'>Save Changes</Button>
                        <FormControlLabel
                            style={{ marginLeft: "15px", marginRight: "0px" }}
                            control={
                                <Checkbox
                                    checked={this.state.checkedJSDownload}
                                    onChange={this.handleChange}
                                    name="checkedJSDownload"
                                    color="primary"
                                />
                            }
                        />
                        <Button onClick={() => this.downloadFile('js')} color='primary'>Download JS</Button>
                        <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                    </TabPanel>
                    {/* Form Variables */}
                    <TabPanel value={this.state.tab} index={5}>
                        <PublishForm />
                        <PreviewPage />
                        <VariableEditor type='form' currentId={FormPageEditorState.formId} />                        
                    </TabPanel>
                    <TabPanel value={this.state.tab} index={6}>
                        <PublishForm />
                        <PreviewPage />
                        <FormSettingsEditor />
                    </TabPanel>
                    {/* DIS Settings */}
                    { this.state.enableDISSettings === true && (<TabPanel value={this.state.tab} index={7}>
                            <PublishForm />
                            <PreviewPage />
                            <FormDISSettingsEditor />
                        </TabPanel>)
                    }
                </Paper>
            </>
        );
    }
}

export default view(EditForm);