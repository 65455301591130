import React, { Component } from 'react';
import {view} from '@risingstack/react-easy-state';
import { DataGrid } from '@mui/x-data-grid';
import UIState from "../../../State/UIState";
import CreateNewState from "../../../State/CreateNewState";
import {Redirect} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import CustomFieldListState from "../../../State/CustomFieldListState";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, {bindTrigger, bindMenu} from "material-ui-popup-state";
import CopyCustomFieldState from "../../../State/CustomField/CopyCustomFieldState";
import CopyCustomField from "../../../Components/Dialogs/Copy/CopyCustomField";
import DeleteForm from "../../../Components/Dialogs/Delete/DeleteForm";
import DeleteState from "../../../State/Common/DeleteState";
import ClientState from "../../../State/ClientState";

function CustomNoRowsOverlay() {

    return (
        <div className='overlayComponent'>No custom fields found.</div>
    );
}
class CustomFieldList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {field: 'id',           headerName: 'Field Id',    width: 150},
                {field: 'name',         headerName: 'Field Name',  width: 420},
                { field: 'created_at', headerName: 'Created At', width: 200, type: 'date' },
                { field: 'updated_at', headerName: 'Updated At', width: 200, type: 'date' },
                {
                    field: 'Action',
                    description: 'You can perform varios actions on the forms.',
                    sortable: false,
                    filterable: false,
                    width: 150,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        const triggerCopyCustomField = (sourceId) => {
                            CopyCustomFieldState.sourceId = sourceId;
                            CopyCustomFieldState.modalOpen = true;
                        }
                    
                        const triggerDeleteCustomField = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = true;
                        }

                        const triggerCopyCustomFieldToClient = (sourceId, sourceName) => {
                            CopyCustomFieldState.sourceId = sourceId;
                            CopyCustomFieldState.sourceName = sourceName;
                            CopyCustomFieldState.modalOpen = true;
                            CopyCustomFieldState.toClient = true;
                        };

                        return(
                            <React.Fragment>
                                <Button onClick={() => this.props.history.push(`/customField/${params.row.id}`)}><Icon className='fa fa-edit' style={{margin: 2, color: 'black'}} /></Button>
                                <PopupState variant="popover" popupId={`action-menu-${params.row.id}`}>
                                    {(popupState) => (
                                        <>
                                            <Button {...bindTrigger(popupState)}>
                                                <Icon className='fa fa-chevron-circle-down list-icon' />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem onClick={() => { popupState.close(); triggerCopyCustomField(params.row.id); }}>Copy Field</MenuItem>
                                                { ClientState.clientType === "super" &&
                                                    <MenuItem onClick={() => { popupState.close(); triggerCopyCustomFieldToClient(params.row.id, params.row.name); }}>Copy Field To Another Client</MenuItem>
                                                }
                                                <MenuItem onClick={() => { popupState.close(); triggerDeleteCustomField(params.row.id, params.row.name, "custom_field"); }}>Delete Field</MenuItem>

                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </React.Fragment>
                        );
                    }
                }
            ],
            rows:[],
            sortModel: [
                {
                    field: 'id',
                    sort: 'desc',
                },
            ]
        }
    }

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.rows.length !== CustomFieldListState.data.length)
        {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        CustomFieldListState.initSocket();
        UIState.setPageTitle('Custom Fields');
        CreateNewState.itemType = 'Custom Field';

        setTimeout(() => {
            this.setState({rows: CustomFieldListState.data});
        }, 1000);
    }

    setSortModel = (model) => {
        this.setState({ sortModel: model });
    }

    render() {
        const redirectToPage = CustomFieldListState.newFormId;
        const hasRedirect = !!(redirectToPage);
        CustomFieldListState.newFormId = 0;
        return (
            <React.Fragment>
                {hasRedirect && <Redirect to={`/customField/${redirectToPage}`} />}
                {!hasRedirect && 
                <Paper>
                    <div style={{ height: 'calc(100vh - 65px)', width: '100%' }}>
                            <DataGrid 
                                rows={this.state.rows}
                                columns={this.state.columns}
                                hideFooterSelectedRowCount={true}
                                disableSelectionOnClick={true}
                                hideFooterPagination={true}
                                sortModel={this.state.sortModel}
                                onSortModelChange={(model) => this.setSortModel(model)}
                                components= {{
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    NoResultsOverlay: CustomNoRowsOverlay
                                }}
                            />
                        </div>
                </Paper>
                }
                <CopyCustomField />
                <DeleteForm getInitialData={this.getInitialData} />

            </React.Fragment>
        );
    }

}

export default view(CustomFieldList);