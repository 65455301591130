import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import AceEditor from "react-ace";
import FormPageEditorState from "../../State/FormPageEditorState";

import 'ace-builds/src-min-noconflict/mode-html'
import 'ace-builds/src-min-noconflict/theme-tomorrow'
import ComponentEditorState from "../../State/ComponentEditorState";

class JSEditor extends Component{

    onChange = (value) => {
        if(this.props.editing === 'component'){
            ComponentEditorState.html = value;
        } else {
            FormPageEditorState.html = value;
        }
    };

    render() {
        let value;
        if(this.props.editing === 'component'){
            value = ComponentEditorState.html;
        } else {
            value = FormPageEditorState.html;
        }

        return (
            <AceEditor
                placeholder="Insert Custom HTML Here"
                style={{width: '100%'}}
                mode="html"
                theme="tomorrow"
                name="htmlEditor"
                onChange={this.onChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={value}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}/>
        );
    }

}

export default view(JSEditor);