import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import ComponentEditorState from "../../../State/ComponentEditorState";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../../Components/Template/TabPanel";
import CSSEditor from "../../../Components/Editors/CSSEditor";
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import JSEditor from "../../../Components/Editors/JSEditor";
import CreateNewState from "../../../State/CreateNewState";
import HTMLEditor from "../../../Components/Editors/HTMLEditor";
import VariableEditor from "../../../Components/Editors/VariableEditor";
import UIState from "../../../State/UIState";
import PublishComponent from "../../../Components/Buttons/PublishComponent";
import ClientState from "../../../State/ClientState";
import AbandonComponentChanges from "../../../Components/Buttons/AbandonComponentChanges";
import { mapDomain, downloadFile, updateVersion, getLastElement } from "../../../lib";
import Typography from "@material-ui/core/Typography";
class EditComponent extends Component {

    state = {
        tab: 0,
        tabs: ['html', 'scss', 'js', 'variables'],
        checkedHTMLDownload: true,
        checkedCSSDownload: true,
        checkedJSDownload: true,
        latestVersion: null
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        const {tab, tabs} = this.state;
        ComponentEditorState.setComponent(params.id);
        CreateNewState.itemType = 'component';
        UIState.setPageTitle('Edit Component');
        let latestVersion = ComponentEditorState.versions !== null ? getLastElement(ComponentEditorState.versions[tabs[tab]]) : null;
        if(latestVersion)
        {
            this.setState({latestVersion: latestVersion});
        }
    }

    componentWillUnmount() {
        ComponentEditorState.socket.close();
        ComponentEditorState.socket = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const {tab, tabs} = this.state;
        if(ComponentEditorState.versions !== null && getLastElement(ComponentEditorState.versions[tabs[tab]]) !== null && this.state.latestVersion !== getLastElement(ComponentEditorState.versions[tabs[tab]]))
        {
            this.setState({ latestVersion : getLastElement(ComponentEditorState.versions[tabs[tab]])});
        }
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    handleTabChange = (event, tab) => {
        this.setState({
            tab: tab,
            latestVersion: null
        });
    };

    handleSave = (fileType = '') => {
        const {tab, tabs, latestVersion} = this.state;
                
        if(tabs[tab] === 'html' && this.state.checkedHTMLDownload)
        {
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.html,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if(tabs[tab] === 'scss' && this.state.checkedCSSDownload)
        {
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.css,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if(tabs[tab] === 'js' && this.state.checkedJSDownload)
        {
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.js,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        

        ComponentEditorState.versions = updateVersion(tabs[tab], ComponentEditorState.versions);
        this.setState({latestVersion: getLastElement(ComponentEditorState.versions[tabs[tab]])});
        ComponentEditorState.emitUpdate();
    };


    downloadFile = async (fileType) => {

        const {tab, tabs, latestVersion} = this.state;

        if(tabs[tab] === 'html')
        {
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.html,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if(tabs[tab] === 'scss')
        {
            // I am assuming that "ComponentEditorState.css" is an object and I wrote it to file as SCSS.
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.css,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if(tabs[tab] === 'js')
        {
            // I am assuming that "ComponentEditorState.js" is an object and I wrote it to file as JS.
            downloadFile('component', ComponentEditorState.name, tabs[tab], ComponentEditorState.js,  (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        
    }

    handleDownloadCheckbox = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    render() {
        const { match: { params } } = this.props;
        const { history } = this.props;
        const { latestVersion } = this.state;
        let updatedDateTime = latestVersion ? latestVersion.updatedAt.split(" ") : "Not Updated";
        
        setTimeout(() => {
            if (ComponentEditorState.name === "") {
                history.push('/components');
            }
        }, 1000);
        return (
            <Paper style={{ padding: 15, margin: 20 }}>
                <Typography variant='h6' gutterBottom>Component Name: {ComponentEditorState.name}</Typography>
                <PublishComponent />
                <AbandonComponentChanges />
                <Button
                    onClick={() => window.open(mapDomain('component?c=' + params.id + '&cl=' + ClientState.currentClient + '&preview=true'))}
                    color='primary'
                    style={{ float: 'right' }}
                >Preview Component</Button>
                <Tabs value={this.state.tab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab label='HTML' {...this.a11yProps(0)} />
                    <Tab label='CSS' {...this.a11yProps(1)} />
                    <Tab label='Javascript' {...this.a11yProps(2)} />
                    <Tab label='Variables' {...this.a11yProps(3)} />
                </Tabs>
                <TabPanel value={this.state.tab} index={0}>
                    <HTMLEditor editing='component' />
                    <Button onClick={() => this.handleSave('html')} color='primary'>Save Changes</Button>
                    <FormControlLabel
                        style={{ marginLeft: "15px", marginRight: "0px" }}
                        control={
                        <Checkbox
                            checked={this.state.checkedHTMLDownload}
                            onChange={this.handleDownloadCheckbox}
                            name="checkedHTMLDownload"
                            color="primary"
                        />
                        }
                    />
                    <Button onClick={() => this.downloadFile('html')} color='primary'>Download HTML</Button>
                    <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                </TabPanel>
                <TabPanel value={this.state.tab} index={1}>
                    <CSSEditor editing='component' />
                    <Button onClick={() => this.handleSave('scss')} color='primary'>Save Changes</Button>
                    <FormControlLabel
                        style={{ marginLeft: "15px", marginRight: "0px" }}
                        control={
                        <Checkbox
                            checked={this.state.checkedCSSDownload}
                            onChange={this.handleDownloadCheckbox}
                            name="checkedCSSDownload"
                            color="primary"
                        />
                        }
                    />
                    <Button onClick={() => this.downloadFile('scss')} color='primary'>Download CSS</Button>
                    <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                </TabPanel>
                <TabPanel value={this.state.tab} index={2}>
                    <JSEditor editing='component' />
                    <Button onClick={() => this.handleSave('js')} color='primary'>Save Changes</Button>
                    <FormControlLabel
                        style={{ marginLeft: "15px", marginRight: "0px" }}
                        control={
                        <Checkbox
                            checked={this.state.checkedJSDownload}
                            onChange={this.handleDownloadCheckbox}
                            name="checkedJSDownload"
                            color="primary"
                        />
                        }
                    />
                    <Button onClick={() => this.downloadFile('js')} color='primary'>Download JS</Button>
                    <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                </TabPanel>
                <TabPanel value={this.state.tab} index={3}>
                    <VariableEditor type='component' currentId={ComponentEditorState.componentId} />
                </TabPanel>
            </Paper>
        );
    }
}

export default view(EditComponent);