import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import _ from 'lodash';
import PageEditorState from "../../State/FormPageEditorState";
import EditFormModalState from "../../State/EditFormModalState";
import Divider from "@material-ui/core/Divider";


class AddPage extends Component {

    state = {
        isError: false
    }

    handleClose = () => {
        EditFormModalState.close();
        this.setState({ isError: false });
    };

    handleOpen = () => {
        EditFormModalState.open();
    };

    handleSubmit = () => {
        if (EditFormModalState.value.trim() === "") {
            this.setState({ isError: true });
        } else {
            PageEditorState.modals[EditFormModalState.modal].name = EditFormModalState.value;
            PageEditorState.emitUpdate();
            this.handleClose();
        }
    };

    handleDelete = () => {
        _.pullAt(PageEditorState.modals, [EditFormModalState.modal]);
        PageEditorState.emitUpdate();
        this.handleClose();
    };

    render() {
        return (
            <Dialog onClose={() => this.handleClose()} open={EditFormModalState.isOpen}>
                <DialogTitle>Edit Modal</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Modal Name'
                        type='text'
                        fullWidth
                        value={EditFormModalState.value}
                        onChange={(event) => {
                            EditFormModalState.value = event.target.value
                            this.setState({ isError: event.target.value.trim() === "" ? true : false });
                        }}
                        helperText={this.state.isError ? "Please enter modal name" : ""}
                        error={this.state.isError ? true : false}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleDelete()} color='secondary'>Delete Modal</Button>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withRouter(view(AddPage));