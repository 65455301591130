import BaseSocket from "./BaseSocket";
import { getIdToken } from "../lib/auth";
import ClientState from "../State/ClientState";

const ComponentSocket = {
    socket: null,
    init: (formId) => {
        if (formId) {
            if (!BaseSocket.socket) {
                BaseSocket.init();
            }
            const component = BaseSocket.socket.subscribe('component:' + formId + "|" + ClientState.currentClient);
            component.on('update', function (data) {
                console.log(data);
            });
            ComponentSocket.socket = component;
            ComponentSocket.socket.emit('auth', {
                token: getIdToken(),
                client: ClientState.currentClient
            });
        }
    },
    emitUpdate: (data) => {
        ComponentSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if (ComponentSocket.socket) {
            if (!client) {
                client = ClientState.currentClient;
            }
            if (typeof callback === 'function') {
                ComponentSocket.socket.on('clientSet', () => callback());
            }
            ComponentSocket.socket.emit('setClient', client);
        } else if (typeof callback === 'function') {
            callback();
        }
    }
};

export default ComponentSocket;