import { store } from "@risingstack/react-easy-state";
import FormPageEditorState from "./FormPageEditorState";
import _ from 'lodash'

const EditFieldState = store({
    isOpen: false,
    object: 0,
    objectType: 'page',
    component: 0,
    componentData: {},
    isError: false,
    open: (object, component, objectType = 'page') => {
        EditFieldState.object = object;
        EditFieldState.component = component;
        EditFieldState.objectType = objectType;

        if(objectType === 'page'){
            EditFieldState.componentData = _.cloneDeep(FormPageEditorState.pages[object].components[component]);
        } else if(objectType === 'modal'){
            EditFieldState.componentData = _.cloneDeep(FormPageEditorState.modals[object].components[component]);
        } else if(objectType === 'thankYouPage'){
            EditFieldState.componentData = _.cloneDeep(FormPageEditorState.thankYouPage[object].components[component]);
        }

        EditFieldState.isOpen = true;
    },
    saveComponent: () => {
        if(EditFieldState.objectType === 'page'){
            FormPageEditorState.pages[EditFieldState.object].components[EditFieldState.component] = _.cloneDeep(EditFieldState.componentData);
        } else if(EditFieldState.objectType === 'modal'){
            FormPageEditorState.modals[EditFieldState.object].components[EditFieldState.component] = _.cloneDeep(EditFieldState.componentData);
        } else if(EditFieldState.objectType === 'thankYouPage'){
            FormPageEditorState.thankYouPage[EditFieldState.object].components[EditFieldState.component] = _.cloneDeep(EditFieldState.componentData);
        }
    },
    close: () => {
        EditFieldState.isOpen = false;
    },
    toggleOpen: () => {
        EditFieldState.isOpen = !EditFieldState.isOpen
    }
});

export default EditFieldState;