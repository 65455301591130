import React, {Component} from 'react';
import {view} from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import CopyComponentState from "../../../State/Component/CopyComponentState";
import ComponentListState from "../../../State/ComponentListState";
import ClientState from '../../../State/ClientState';

class CopyComponent extends Component{

    state = {
        isError: false,
        toClient: "none",
        toClientValidate: false
    }

    handleSubmit() {
        let isFormValidate = true;
        if(CopyComponentState.dynamicElementName.trim() === "") {
            this.setState({ isError: true });
            isFormValidate = false;
        }

        if(CopyComponentState.toClient && this.state.toClient === 'none') {
            this.setState({ toClientValidate: true });
            isFormValidate = false;
        }

        if(isFormValidate) {
            CopyComponentState.targetClient = CopyComponentState.toClient ? this.state.toClient : "";
            ComponentListState.copyComponent(CopyComponentState.sourceId, CopyComponentState.dynamicElementName, CopyComponentState.toClient ? this.state.toClient : "");
        }
    }

    handleClose() {
        this.setState({
            isError: false,
            toClient: "none",
            toClientValidate: false
        });
        CopyComponentState.modalOpen = false;
        CopyComponentState.toClient = false;
        CopyComponentState.targetClient = "";
        CopyComponentState.dynamicElementName = ""; 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if(event.target.name === "name") {
            CopyComponentState.dynamicElementName = event.target.value;
            if (event.target.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
            }
        }

        if(CopyComponentState.toClient && event.target.name === "toClient") {
            if (event.target.value !== "none") {
                this.setState({ toClientValidate: false });
                ComponentListState.getDynamicElementName(CopyComponentState.sourceName, event.target.value);        
            } else {
                this.setState({ toClientValidate: true });
            }
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={CopyComponentState.modalOpen}>
                    <DialogTitle>Copy Component {CopyComponentState.toClient ? 'To Another Client' : ''}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the component a name to get started.</DialogContentText>
                        {CopyComponentState.toClient &&
                            <React.Fragment>
                                <FormControl fullWidth error={ this.state.toClientValidate }>
                                    <InputLabel id={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}>Client</InputLabel>
                                    <Select
                                        autoFocus
                                        fullWidth
                                        labelId={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}
                                        id={`client-select${this.state.toClientValidate ? `-error` : ``}`}
                                        name='toClient'
                                        value={this.state.toClient}
                                        onChange={(event) => this.handleChange(event)}
                                    >
                                        <MenuItem value="none" key="0">Please Select</MenuItem>
                                        {ClientState.clientList.map((client, key) => (
                                            <MenuItem value={client} key={key+1}>{client}</MenuItem>
                                        ))}
                                    </Select>
                                    { this.state.toClientValidate && 
                                        <FormHelperText>Please Select Client</FormHelperText>
                                    }
                                </FormControl>
                            </React.Fragment>
                        }
                        <TextField
                            margin='dense'
                            label={"Component Name"}
                            name='name'
                            type='text'
                            value={CopyComponentState.dynamicElementName}
                            onChange={(event) => this.handleChange(event)}
                            helperText={this.state.isError ? "Please Enter Component Name" : ""}
                            error={this.state.isError}
                            fullWidth
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!ComponentListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'>
                                Copy Component
                        </Button>
                        }
                        {ComponentListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'
                                disabled={true}>
                                Copy Component  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    ComponentListState.isDuplicateComponent && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={ComponentListState.isDuplicateComponent} autoHideDuration={10000}
                        onClose={() => ComponentListState.isDuplicateComponent = false}>

                        <Alert variant="filled" severity="error" onClose={() => ComponentListState.isDuplicateComponent = false}>
                            {ComponentListState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>
        );
    }

}

export default view(CopyComponent);

