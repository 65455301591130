import { store } from '@risingstack/react-easy-state';
import axios from 'axios'

const CopyRuleState = store({
    sourceId: null,
    modalOpen: false,
    sourceName: null,
    clientList: [],
    targetClient: "",
    dynamicElementName: "",
    isLoading: false,
    toggleOpen() {
        CopyRuleState.modalOpen = !CopyRuleState.modalOpen;
    },
    loadDISClients: async() => {
        try{
            
            let protocol = 'https';
            if(process.env.NODE_ENV === 'development'){
                protocol = 'http';
            }
            const response = await axios.get(`${protocol}://${process.env.REACT_APP_API_HOST}/meta/getDISClients`);
            const responseData = response.data;
            CopyRuleState.clientList = responseData;

        } catch (e){
            console.error(e);
            CopyRuleState.clientList = [];
        }
    }
});

export default CopyRuleState;