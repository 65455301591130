import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AssetManagerOverlay from "../Components/Dialogs/AssetManagerOverlay";
import AssetPreview from "../Components/Dialogs/AssetPreview";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import GlobalVariableDialog from "../Components/Dialogs/GlobalVariable";

import {
    defaultLayoutPreset,
    standardLayoutPreset,
    fixedLayoutPreset,
    contentBasedLayoutPreset,
    cozyLayoutPreset,
    muiTreasuryPreset
} from "@mui-treasury/layout/presets";

import {
    Root,
    Sidebar,
    Content,
    CollapseBtn,
    CollapseIcon,
} from "@mui-treasury/layout";

import AppBar from "../Components/Template/AppBar";
import SidebarContent from "../Components/Template/SidebarContent";
import NewItem from "../Components/Dialogs/NewItem";
import Routes from "./Pages/Routes";
import NotificationDialog from "../Components/Dialogs/NotificationDialog";
import ValidationDialog from "../Components/Dialogs/ValidationDialog";
import { getUserInfo } from "../lib/auth";
import ClientState from "../State/ClientState";
import EmbedModal from "../Components/Dialogs/EmbedModal";
import SocketDisconnected from "../Components/Dialogs/SocketDisconnected";
import { pendoInitialization } from "../lib/index";
import PrivateMetaState from "../State/PrivateMetaState";
import crypto from "crypto";


const presets = {
    createDefaultLayout: defaultLayoutPreset,
    createStandardLayout: standardLayoutPreset,
    createFixedLayout: fixedLayoutPreset,
    createContentBasedLayout: contentBasedLayoutPreset,
    createCozyLayout: cozyLayoutPreset,
    createMuiTreasuryLayout: muiTreasuryPreset
};

function LoggedInTemplate() {
    const [preset] = useState("createStandardLayout");
    const [data] = useState({
        header: true,
        nav: true,
        content: true,
        footer: true
    });

    const parsedUser = getUserInfo();
    const user = {
        image: parsedUser.picture,
        name: parsedUser.nickname,
        email: parsedUser.email
    }

    useEffect(() => {
      const secretKey = process.env.REACT_APP_INTERCOM_SECRETE; // an Identity Verification secret key (web)
      const userIdentifier = user.email; // the email for your user
      const hash = crypto.createHmac("sha256", secretKey).update(userIdentifier).digest("hex");
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "ubbjplne",
        name: user.name, // Full name
        email: user.email, // the email for your user
        created_at: user.updated_at, // Signup date as a Unix timestamp
        user_hash: hash, // HMAC using SHA-256
      });
    }, []);

    let clientSelect = null;
    if (ClientState.multiClient) {
        clientSelect = (<><br />
            <InputLabel id='client-select-label'>Client</InputLabel>
            <Select fullWidth labelId='client-select-label' id='client-select' value={ClientState.currentClient} onChange={event => ClientState.changeClient(event.target.value)}>
                {ClientState.clientList.map((client, key) => (
                    <MenuItem value={client} key={key}>{client}</MenuItem>
                ))}
            </Select>
        </>);
    }

    const localStorageClient = window.localStorage.getItem(ClientState.clientContextVariable);

    if(localStorageClient === PrivateMetaState.data.acronym) {
        pendoInitialization(PrivateMetaState, parsedUser);
    }

    return (
        <div style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Router>
                <Root config={presets[preset]}>
                    {({ headerStyles, sidebarStyles }) => (
                        <>
                            <NotificationDialog />
                            <ValidationDialog />
                            <GlobalVariableDialog />
                            <AssetManagerOverlay />
                            <SocketDisconnected />
                            <EmbedModal />
                            <AssetPreview />
                            <CssBaseline />
                            <AppBar collapseClass={headerStyles.leftTrigger} sidebarTriggerClass={headerStyles.leftTrigger} />
                            <Content>
                                <NewItem />
                                <div>
                                    <Routes />
                                </div>
                            </Content>
                            <Sidebar>
                                {({ collapsed }) => (
                                    <>
                                        <div style={{ padding: collapsed ? 8 : 16, transition: "0.3s" }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Avatar
                                                    style={{
                                                        width: collapsed ? 48 : 80,
                                                        height: collapsed ? 48 : 80,
                                                        transition: "0.3s"
                                                    }}
                                                    src={user.image}
                                                />
                                            </div>
                                            <div style={{ paddingBottom: 16 }} />
                                            <Typography variant={"h6"} noWrap>
                                                {user.name}
                                            </Typography>
                                            <Typography color={"textSecondary"} noWrap gutterBottom>
                                                {user.email}
                                            </Typography>
                                            {clientSelect}
                                        </div>
                                        <Divider />
                                        <div className={sidebarStyles.container}>
                                            {data.nav && <SidebarContent />}
                                        </div>
                                        <CollapseBtn className={sidebarStyles.collapseBtn}>
                                            <CollapseIcon />
                                        </CollapseBtn>
                                    </>
                                )}
                            </Sidebar>
                        </>
                    )}
                </Root>
            </Router>
        </div>
    );
}

export default view(LoggedInTemplate);
