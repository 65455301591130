import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FormPageEditorState from "../../../State/FormPageEditorState";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import MaterialTextField from "@material-ui/core/TextField";
import EditFieldState from "../../../State/EditFieldState";
import AddFieldState from "../../../State/AddFieldState";

class LIDEANE extends Component{

    getRowFields = (row, rowIndex) => {
        const {triggers, modals} = FormPageEditorState;
        
        let labelText = triggers.map( t => {
            if(t.id === row) {
                return t.name
            }
        })
        if(this.props.label === 'modals'){
            labelText = modals.map( t => {
                if(t.id === row) {
                    return t.name
                }
            });
        }
        
        try{
            return (
                <Grid container spacing={2} key={row} style={{paddingBottom: 10}}>
                    <Grid item xs={11}>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            {(this.props.label !== 'modals')? 'Trigger attached':'Modal attached'}
                        </InputLabel>
                        <MaterialTextField
                            margin='dense'
                            label={labelText}
                            type='text'
                            fullWidth
                            variant={Boolean(true) ? "filled" : "standard"}
                            inputProps={{ disabled: Boolean(true) }}
                            value={""}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {
                            rowIndex!==0 &&
                            this.props.label !== 'modals' &&
                            <IconButton aria-label="delete">
                                <DeleteIcon onClick={() => {
                                    FormPageEditorState.removeTriggerErrorAfterNAttemps(EditFieldState.object, row);
                                    // value.splice(rowIndex, 1);
                                }} />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            );
        }
        catch(err){
            return (<></>);
        }
    };

    addNewTrigger = (value) => {
        if(value.length < FormPageEditorState.errorAfterNAttempsSettings.maxModals){
            FormPageEditorState.createTriggerErrorAfterNAttemps(EditFieldState.object, EditFieldState.componentData.id, "duo", EditFieldState.componentData);
            EditFieldState.saveComponent();
            FormPageEditorState.emitUpdate();
            EditFieldState.close();
        }
    };

    searchAllLIDEANETriggers = () => {
        const {triggers} = FormPageEditorState;
        // console.log("!!!->LIDEANE:searchAllLIDEANETriggers:errorAfterNAttempsSettings.field_id", FormPageEditorState.errorAfterNAttempsSettings.case.field_id);
        // console.log("!!!->LIDEANE:searchAllLIDEANETriggers:triggers", triggers);
        // search allLIDEANETriggers
        let tmpLIDEANETriggers = [];
        let name = FormPageEditorState.errorAfterNAttempsSettings.case.field_id;
        for (let index = 0; index < triggers.length; index++) {
            const element = triggers[index];
            if(element.name.includes(name)){
                // then adding
                tmpLIDEANETriggers.push(element);
            }
        }
        // console.log("!!!->LIDEANE:searchAllLIDEANETriggers:tmpLIDEANETriggers", tmpLIDEANETriggers);
        return tmpLIDEANETriggers;
    };

    render(){
        const { option, value } = this.props;
        // console.log("!!!->LIDEANE:this.props", this.props);
        // proecess globaly the triggres to avoid 
        const handlerLIDEANE = this.searchAllLIDEANETriggers();
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={11}>{option.field}</Grid>
                    <Grid item xs={1}>
                        {
                            (
                                handlerLIDEANE.length < FormPageEditorState.errorAfterNAttempsSettings.maxModals &&
                                this.props.label !== 'modals'
                            ) &&
                            <Button onClick={() => this.addNewTrigger(handlerLIDEANE)}>
                                <AddBoxIcon />
                            </Button>
                        }
                    </Grid>
                </Grid>
                {value.map((value, index) => this.getRowFields(value, index))}
            </>
        );
    }

}

export default view(LIDEANE);