import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {view} from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import AddModalState from "../../State/AddModalState";
import PageEditorState from "../../State/FormPageEditorState";
import Divider from "@material-ui/core/Divider";


class AddPage extends Component{

    state = {
        isError: false
    }

    handleClose = () => {
        AddModalState.close();
        this.setState({ isError: false });
    };

    handleOpen = () => {
        AddModalState.open();
    };

    handleSubmit = () => {
        if (AddModalState.value.trim() === "") {
            this.setState({ isError: true });
        } else {
            PageEditorState.addModal(AddModalState.value);
            AddModalState.value = "";
            this.handleClose();
        }
       
    };

    render(){
        return (
            <>
                <Button onClick={() => this.handleOpen()} color='primary'>Add Modal</Button>
                <Dialog onClose={() => this.handleClose()} open={AddModalState.isOpen}>
                    <DialogTitle>Add Modal</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Let's give your modal a name.</DialogContentText>
                        <TextField
                            autoFocus
                            margin='dense'
                            label='Modal Name'
                            type='text'
                            fullWidth
                            value={AddModalState.value}
                            onChange={(event) => AddModalState.value = event.target.value}
                            helperText={this.state.isError ? "Please enter modal name" : ""}
                            error={this.state.isError ? true : false}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        <Button onClick={() => this.handleSubmit()} color='primary'>Add Modal</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default withRouter(view(AddPage));