import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Grid from '@material-ui/core/Grid';
import FolderIcon from '@material-ui/icons/Folder';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssetManagerState from "../../State/AssetManagerState";
import Dropzone from "react-dropzone";
import DeleteForm from "../Dialogs/Delete/DeleteForm";
import DeleteState from "../../State/Common/DeleteState";

import './FolderList.css';

class FolderList extends Component{

    state = {
        dropZoneEnter: null
    };

    handleUpload = (folder, acceptedFiles) => {
        this.setState({
            dropZoneEnter: null
        });

        const currentDir = AssetManagerState.getCurrentDirectory();
        let destination = folder.Prefix.replace(currentDir.Prefix, '');
        if(destination.substr(destination.length - 1) !== '/'){
            destination = destination + '/';
        }
        AssetManagerState.uploadFiles(destination, acceptedFiles);
    };

    handleDelete = (folderName) => {
        DeleteState.type = 'assetManagerFolder';
        DeleteState.assetName = folderName;
        DeleteState.modalOpen = true;
        DeleteState.isDeleteModal = true;
        console.log("folderName", folderName);
    };

    render(){
        const { folders } = this.props;
        const currentDir = AssetManagerState.getCurrentDirectory();
        return (
            <>
                <Grid container style={{padding: 5}}>
                    {folders.map((folder, index) => (
                        <Grid item md={2} xs={12}>
                            <Dropzone
                                onDrop={(acceptedFiles) => this.handleUpload(folder, acceptedFiles)}
                                noClick={true}
                                noKeyboard={true}
                                preventDropOnDocument={true}
                                onDragEnter={() => this.setState({dropZoneEnter: index})}
                                onDragLeave={() => this.setState({dropZoneEnter: null})}
                                key={index}
                                style={{background: '#F00'}}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()}>
                                        <input type='hidden' {...getInputProps()} />
                                        <ContextMenuTrigger id={`folder-context-trigger-${index}`}>
                                            <List  component="nav" aria-label="main mailbox folders" className={this.state.dropZoneEnter === index ? 'dragEnter' : ''}>
                                                <ListItem
                                                    button
                                                    onClick={() => AssetManagerState.changeDir((currentDir.Prefix ?? '/') + folder.Prefix.replace(currentDir.Prefix, ''))}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={folder.Prefix.replace(currentDir.Prefix, '').replace('/', '')} />
                                                </ListItem>
                                            </List>
                                        </ContextMenuTrigger>
                                        <ContextMenu id={`folder-context-trigger-${index}`}>
                                            <MenuItem onClick={() => AssetManagerState.copyFolder(folder.Prefix.replace(currentDir.Prefix, '').replace('/', ''))}>Copy Folder</MenuItem>
                                            <MenuItem onClick={() => AssetManagerState.cutFolder(folder.Prefix.replace(currentDir.Prefix, '').replace('/', ''))}>Cut Folder</MenuItem>
                                            {/* <MenuItem onClick={() => AssetManagerState.deleteFolder(folder.Prefix.replace(currentDir.Prefix, '').replace('/', ''))}>Delete Folder</MenuItem> */}
                                            <MenuItem onClick={() =>this.handleDelete(folder.Prefix.replace(currentDir.Prefix, '').replace('/', ''))}>Delete Folder</MenuItem>
                                        </ContextMenu>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                    ))}
                </Grid>
                <DeleteForm />
            </>
        );
    }

}

export default view(FolderList);