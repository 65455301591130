import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import FormPageEditorState from "../../State/FormPageEditorState";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import EditModalTriggersState from "../../State/EditModalTriggersState";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class EditModalTriggers extends Component{

    getRowFields = (row, rowIndex) => {
        const value = EditModalTriggersState.triggers;
        const {triggers} = FormPageEditorState;

        return (
            <Grid container spacing={2} key={rowIndex} style={{paddingBottom: 10}}>
                <Grid item xs={10}>
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Trigger
                    </InputLabel>
                    <Select
                        value={value[rowIndex]}
                        id='add-field-select'
                        style={{width: '100%'}}
                        label='Field'
                        placeholder='Field'
                        onChange={(event) => {
                            value[rowIndex] = event.target.value;
                        }}
                    >
                        {triggers.map((trigger, index) => (
                            <MenuItem key={index} value={trigger.id}>{trigger.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <IconButton aria-label="delete">
                        <DeleteIcon onClick={() => {
                            value.splice(rowIndex, 1);
                        }} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    handleClose() {
        EditModalTriggersState.isOpen = false;
    }

    handleSubmit() {
        FormPageEditorState.modals[EditModalTriggersState.modal].triggers = EditModalTriggersState.triggers;
        FormPageEditorState.emitUpdate();
        this.handleClose();
    }

    render(){
        const value = EditModalTriggersState.triggers;
        console.log('isOpen', EditModalTriggersState.isOpen);
        return (
            <Dialog onClose={() => this.handleClose()} open={EditModalTriggersState.isOpen}>
                <DialogTitle>Edit Modal Triggers</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid style={{width: 350}} container spacing={2}>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => value.push('')}>
                                <AddBoxIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    {value.map((value, index) => this.getRowFields(value, index))}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(EditModalTriggers);