
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import { camelToTitle } from "../../../lib";

class EnumField extends Component {

    render() {
        const { value, onChange, label, options, recurringGiftOptions, numRecurringGiftNoValues } = this.props;
        let disabled = false;
        if(recurringGiftOptions.includes(label) && numRecurringGiftNoValues >= 4 && value === 'Yes') {
            disabled = true;
        }

        return (
            <div className={label === "showWeeklyOption" ? "d-none" : ""} style={{ marginTop: '10px' }}>
                <span>{camelToTitle(label)}<br /></span>
                <div className="radio-main-div">
                    {options.map((option, key) => {
                        return (
                            <label key={key} className={"inline-radio "+ (option === 'weekly' ? 'd-none' : '')}>
                                <input type='radio' checked={value === option} onClick={() => onChange(option)} disabled={disabled} />
                                {camelToTitle(option)}
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }

}

export default view(EnumField);