import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import PageListState from "../../../State/PageListState";
import { DataGrid } from '@mui/x-data-grid';
import Icon from "@material-ui/core/Icon";
import { Redirect, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CreateNewState from "../../../State/CreateNewState";
import UIState from "../../../State/UIState";
import ClientState from "../../../State/ClientState";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CopyPageState from "../../../State/Page/CopyPageState";
import CopyPage from "../../../Components/Dialogs/Copy/CopyPage";
import DeleteForm from "../../../Components/Dialogs/Delete/DeleteForm";
import DeleteState from "../../../State/Common/DeleteState";
import { mapDomain } from "../../../lib";
import EmbedModalState from "../../../State/EmbedModalState";

function CustomNoRowsOverlay() {

    return (
        <div className='overlayComponent'>No pages found.</div>
    );
}
class PageList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { field: 'id', headerName: 'Page Id', width: 150 },
                {
                    field: 'name',
                    headerName: 'Page Name',
                    width: 420,
                    renderCell: (params) => {
                        return (
                            <React.Fragment>
                                {params.row.name}&nbsp;
                                {params.row.is_draft ? <span style={{ color: 'red' }}>(Draft)</span> : null}
                            </React.Fragment>
                        )
                    }
                },
                { field: 'created_at', headerName: 'Created At', width: 200, type: 'date' },
                { field: 'updated_at', headerName: 'Updated At', width: 200, type: 'date' },
                {
                    field: 'Actions',
                    sortable: false,
                    filterable: false,
                    width: 150,
                    renderCell: (params) => {

                        const triggerCopyPage = (sourceId) => {
                            CopyPageState.sourceId = sourceId;
                            CopyPageState.modalOpen = true;
                        };

                        const triggerDeletePage = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = true;
                        };

                        const triggerCopyPageToClient = (sourceId, sourceName) => {
                            CopyPageState.sourceId = sourceId;
                            CopyPageState.sourceName = sourceName;
                            CopyPageState.modalOpen = true;
                            CopyPageState.toClient = true;
                        };

                        return (
                            <React.Fragment>
                                <Button onClick={() => this.props.history.push(`/page/${params.row.id}`)}><Icon className='fa fa-edit' style={{ margin: 2, color: 'black' }} /></Button>
                                <PopupState variant="popover" popupId={`action-menu-${params.row.id}`}>
                                    {(popupState) => (
                                        <>
                                            <Button {...bindTrigger(popupState)}>
                                                <Icon className='fa fa-chevron-circle-down list-icon' />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem onClick={() => window.open(mapDomain(`page?cl=${ClientState.currentClient}&page=${params.row.id}`))}>View Live</MenuItem>
                                                <MenuItem onClick={() => window.open(mapDomain(`page?cl=${ClientState.currentClient}&page=${params.row.id}&preview=on`))}>View Preview</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => {
                                                    EmbedModalState.showModal('Page',
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/page' data-cl='${ClientState.currentClient}' data-page='${params.row.id}' data-preview='true' ></script>`,
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/page' data-cl='${ClientState.currentClient}' data-page='${params.row.id}' ></script>`, '', mapDomain(`page?cl=${ClientState.currentClient}&page=${params.row.id}`));
                                                }}>Embed</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => { popupState.close(); triggerCopyPage(params.row.id); }}>Copy Page</MenuItem>
                                                { ClientState.clientType === "super" &&
                                                    <MenuItem onClick={() => { popupState.close(); triggerCopyPageToClient(params.row.id, params.row.name); }}>Copy Page To Another Client</MenuItem>
                                                }
                                                <MenuItem onClick={() => { popupState.close(); triggerDeletePage(params.row.id, params.row.name, "page"); }}>Delete Page</MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </React.Fragment>
                        )
                    }
                }
            ],
            rows: [],
            sortModel: [
                {
                    field: 'id',
                    sort: 'desc',
                },
            ]
        };
    }

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rows.length !== PageListState.data.length) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        PageListState.initSocket();
        CreateNewState.itemType = 'page';
        UIState.setPageTitle('Pages');

        setTimeout(() => {
            this.setState({ rows: PageListState.data });
        }, 1000);
    }
    
    setSortModel = (model) => {
        this.setState({ sortModel: model });
    }

    render() {
        const redirectToPage = PageListState.newFormId;
        const hasRedirect = !!(redirectToPage);
        PageListState.newFormId = 0;
        return (
            <React.Fragment>
                {hasRedirect && <Redirect to={`/page/${redirectToPage}`} />}
                {!hasRedirect &&
                    <Paper>
                        <div style={{ height: 'calc(100vh - 65px)', width: '100%' }}>
                            <DataGrid
                                rows={this.state.rows}
                                columns={this.state.columns}
                                hideFooterSelectedRowCount={true}
                                disableSelectionOnClick={false}
                                hideFooterPagination={true}
                                sortModel={this.state.sortModel}
                                onSortModelChange={(model) => this.setSortModel(model)}
                                components={{
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    NoResultsOverlay: CustomNoRowsOverlay
                                }}
                            />
                        </div>
                    </Paper>
                }
                <CopyPage />
                <DeleteForm getInitialData={this.getInitialData} />
            </React.Fragment>
        );
    }

}

export default withRouter(view(PageList));