import BaseSocket from "./BaseSocket";
import { getIdToken } from "../lib/auth";
import ClientState from "../State/ClientState";

const RuleSocket = {
    socket: null,
    init: (ruleId) => {

        if (!BaseSocket.socket) {
            BaseSocket.init();
        }
        const rule = BaseSocket.socket.subscribe('rule:' + ruleId + "|" + ClientState.currentClient);
        RuleSocket.socket = rule;
        RuleSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        RuleSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if (RuleSocket.socket) {
            if (!client) {
                client = ClientState.currentClient;
            }
            if (typeof callback === 'function') {
                RuleSocket.socket.on('clientSet', () => callback());
            }
            RuleSocket.socket.emit('setClient', client);
        } else if (typeof callback === 'function') {
            callback();
        }
    }
};

export default RuleSocket;