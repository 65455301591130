import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import {getAuth0Object} from "../lib/auth";

class LoggedOutTemplate extends Component{

    auth0 = null;

    constructor() {
        super();
        this.auth0 = getAuth0Object();
    }

    componentDidMount() {
        this.auth0.authorize();
    }

    render(){
        return (
            <Dialog open={true}>
                <DialogTitle>Invalid Session</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Redirecting you to the sign in page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {window.location = 'https://app.charitable.one/'}}>
                        Sign in
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(LoggedOutTemplate);