import { store } from "@risingstack/react-easy-state";

const ValidationFieldState = store({
    isOpen: false,
    open: () => {
        ValidationFieldState.isOpen = true;
    },
    close: () => {
        ValidationFieldState.isOpen = false;
    }
});

export default store(ValidationFieldState);