import BaseSocket from "./BaseSocket";
import {getIdToken} from "../lib/auth";
import ClientState from "../State/ClientState";

const PageSocket = {
    socket: null,
    init: (formId) => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }
        const page = BaseSocket.socket.subscribe('page:' + formId + "|" + ClientState.currentClient);
        page.on('update', function(data){
            console.log(data);
        });
        PageSocket.socket = page;
        PageSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        PageSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if(PageSocket.socket){
            if(!client){
                client = ClientState.currentClient;
            }
            if(typeof callback === 'function'){
                PageSocket.socket.on('clientSet', () => callback());
            }
            PageSocket.socket.emit('setClient', client);
        } else if(typeof callback === 'function'){
            callback();
        }
    }
};

export default PageSocket;