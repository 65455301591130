import React, { Component, useEffect, useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import Grid from "@material-ui/core/Grid";
import {Accordion, AccordionDetails, AccordionSummary, Card, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormPageEditorState from "../../State/FormPageEditorState";
import RuleState from "../../State/RuleState";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import _ from 'lodash';

const FormDISSettingsEditor = () => {

    const [rulesList, setRulesList] = useState([]);
    const [appliedRulesList, setAppliedRulesList] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState('');
    
    useEffect(() => {
        let singleDonationValues = "";
        let recurringDonationValues = '';
        let giftArrayBtns = {};
        FormPageEditorState.pages.map((page) => {
            let componentFound = page.components.find((c) => c.id === 'giftArrayBtn')
            
            if(componentFound) {
                giftArrayBtns = componentFound;
            }
        })
        if(Object.keys(giftArrayBtns).length) {
            let singleDonationOptions = giftArrayBtns.options.find((item) => item.field === 'options');
            let recurringDonationOptions = giftArrayBtns.options.find((item) => item.field === "recurringOptions");

            singleDonationValues = singleDonationOptions.values.map((item) => item.amount).join(', ');
            recurringDonationValues = recurringDonationOptions.values.map((item) => item.amount).join(', ');
        }
        

        let rules = [];
        let appliedRules = [];

        setEmptyMessage(RuleState.data.length ? 'No Rules Left' : 'No Rules found');
        
        RuleState.data.map((item) => {
            let rule_state = JSON.parse(item.rule_state);
            item.expression = rule_state.expression;
            item.action = rule_state.action;
            item.actionStatement = ''
            item.originalSingleGiftArray = singleDonationValues;
            item.originalRecurringGiftArray = recurringDonationValues;

            console.log('singleDonationValues: ', singleDonationValues);
            console.log('recurringDonationValues: ', recurringDonationValues);

            if(item.action.valueType === 'percent') {
                item.actionStatement = (item.action.vary === 'increase' ? 'Increment by ' : 'Decrement by ') + (item.action.donationValue.donationAmountPercent || 0) + "%, rounded to multiple of " + item.action.multiplier;
                
                let newArray = [];
                newArray = singleDonationValues.split(",").map((item) => {
                    return checkForRule(parseFloat(item), rule_state.action);
                });
                console.log('newArray: ', singleDonationValues, newArray);
                item.newSingleGiftArray = getUniqueAmount(newArray, parseInt(rule_state.action.multiplier)).join(", ");

                newArray = recurringDonationValues.split(",").map((item) => {
                    return checkForRule(parseFloat(item), rule_state.action);
                })
                console.log('newArray: ', recurringDonationValues, newArray);
                item.newRecurringGiftArray = getUniqueAmount(newArray, parseInt(rule_state.action.multiplier)).join(", ");

            } else {
                item.newSingleGiftArray = item.action.donationValue?.singleDonationAmount;
                item.newRecurringGiftArray = item.action.donationValue?.recurringDonationAmount;
            }

            if(FormPageEditorState.DISRules.indexOf(parseInt(item.id)) > -1) {
                appliedRules.push(item);                 
            } else {
                rules.push(item)
            }
        })

        if(FormPageEditorState.DISRules.length) {
            appliedRules.sort((a, b) => FormPageEditorState.DISRules.indexOf(a.id) - FormPageEditorState.DISRules.indexOf(b.id))
        }
        
        setRulesList(rules);
        setAppliedRulesList(appliedRules);
    }, [])

    useEffect(() => {        
        FormPageEditorState.setDISRule(appliedRulesList.map((item) => item.id));        
    }, [appliedRulesList])

    const saveChanges = (value) => {
        if(!value) {
            setAppliedRulesList([]);
        }
        FormPageEditorState.setDISSetting(value);
    }

    const getListStyle = isDraggingOver => ({

    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        border: isDragging ? '2px solid rgba(0, 0, 0, 0.12)' : 'none',
        background: isDragging ? 'rgba(0, 0, 0, 0.08) !important' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const handleDelete = (index) => {
        let sourceComponents = appliedRulesList;
        let destinationComponents = rulesList;

        let source = {index: index};
        let destination = {index: rulesList.length};

        const result = moveItem(
            sourceComponents,
            destinationComponents,
            source,
            destination
        )
        
        setRulesList(result.destinationList);
        setAppliedRulesList(result.sourceList);
    }

    const moveItem = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destinationClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destinationClone.splice(droppableDestination.index, 0, removed);

        console.log('sourceClone: ', sourceClone);
        console.log('destinationClone: ', destinationClone);

        return {
            sourceList: sourceClone,
            destinationList: destinationClone
        };
    }

    const reorderList = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        
        if (!destination || destination.droppableId === 'field_list:rule:1') {
            return;
        }

        if (source.droppableId !== destination.droppableId) { 
            let sourceComponents = rulesList;
            let destinationComponents = appliedRulesList;

            const result = moveItem(
                sourceComponents,
                destinationComponents,
                source,
                destination
            )
            
            setRulesList(result.sourceList);
            setAppliedRulesList(result.destinationList);
        } else {
            let droppableObject = appliedRulesList;

            const components = reorderList(
                droppableObject,
                source.index,
                destination.index
            );
            setAppliedRulesList(components);
        }
    }

    const checkForRule = (donationAmt, action) => {
        if(action) {            
            let calculatedAmount = donationAmt * (parseFloat(action.donationValue.donationAmountPercent || 0) / 100);
            console.log('calculatedAmount: ', calculatedAmount);

            if(action.vary === 'increase') {
                donationAmt = donationAmt + calculatedAmount;
            } else {
                donationAmt = donationAmt - calculatedAmount;
            }
            console.log('donationAmt: ', donationAmt);
            
            if(calculatedAmount) {
                donationAmt = Math.ceil(donationAmt / parseInt(action.multiplier)) * parseInt(action.multiplier);
            }                         
        }

        return donationAmt;
    }

    const getUniqueAmount = (arr, increaseBy) => {
        let set = new Set();
        let duplicates = new Set();
        
        arr.forEach((item) => {
            if (set.has(item)) {
            duplicates.add(item);
            }
            set.add(item);
        });
        
        if (duplicates.size > 0) {            
            duplicates.forEach((item) => {
            let index = arr.indexOf(item);
            arr[index] += increaseBy;
            });
            getUniqueAmount(arr, increaseBy);
        }
        return _.sortBy(arr);
    }

    const loadTooltip = (item) => {
        return (
            <Tooltip arrow placement='top' title={
                <div style={{padding: '5px'}}>
                    <span className='tooltipHeader'>Rule logic: </span>
                    <span className='tooltipContent'>{item.expression}</span>
                    <br />
                    <br />
                    {item.actionStatement && ( <>
                            <span style={{fontSize: '13px', fontWeight: 'bolder'}}>Calculation: </span>
                            <span className='tooltipContent'>{item.actionStatement}</span>
                            <br />
                            <br />
                        </>)                                                                                                                 
                    }
                    <span className='tooltipHeader'>Original gift array: </span>
                    <br />

                    <span className='tooltipContent'>Single Donation: {item.originalSingleGiftArray}</span>
                    <br />
                    <span className='tooltipContent'>Recurring Donation: {item.originalRecurringGiftArray}</span>
                    <br />
                    <br />
                    
                    <span className='tooltipHeader'>New gift array: </span>
                    <br />
                    <span className='tooltipContent'>Single Donation: {item.newSingleGiftArray}</span>
                    <br />
                    <span className='tooltipContent'>Recurring Donation: {item.newRecurringGiftArray}</span>
                </div>
            }>
                <InfoTwoToneIcon color="primary" />
            </Tooltip>
        )
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid xs={12} md={12} lg={12} item>
                    {/* Enable Identity Settings  */}
                    <Card variant='outlined' className='dis-settings-toggle-panel'>
                        <CardContent>
                            <FormControl>
                                <FormControlLabel
                                    control={<Switch
                                        color="primary"
                                        checked={FormPageEditorState.DISSetting}
                                        onChange={(event) => saveChanges(event.target.checked) }
                                    />}
                                    label="Enable Identity"
                                    labelPlacement="start"
                                />
                            </FormControl>                    
                        </CardContent>
                    </Card>                    
                </Grid>
                { FormPageEditorState.DISSetting === true && (
                    <Grid xs={12} md={12} lg={12} item className='ruleList'>
                        <Accordion defaultExpanded={true} style={{marginBottom: '15px'}}>
                            <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header" >
                                    <div className="info-indicator-global-setting accordianTitle">
                                        <h3 style={{ marginRight: '15px' }}>Gift Array Rules</h3>
                                    </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Grid item style={{display: 'flex', flexDirection: 'row', width: '100%', columnGap: '5%'}}>
                                        <Grid item className='triggerEditorCard'>
                                            <Card variant='outlined'>
                                                <CardContent>
                                                    <Typography variant='h6' color='textSecondary' gutterBottom>Rules List</Typography>
                                                </CardContent>
                                                <Divider />
                                                <CardContent>                                                
                                                    {rulesList.length > 0
                                                    ? (     
                                                        <>
                                                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>Drag the rule to the right to apply the rule.</Typography>                                                        
                                                            <List dense={true}>
                                                                
                                                                <Droppable droppableId={`field_list:rule:1`}>
                                                                    {(provided, snapshot) => (
                                                                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} >
                                                                            {rulesList.map((item, index) => (
                                                                                <Draggable key={index} draggableId={`${index}:${item.id}:rule`} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )
                                                                                        }>
                                                                                            <ListItem className='ruleListItems'>
                                                                                                <ListItemText disableTypography primary={<Typography variant="body1">{item.name}</Typography>} />
                                                                                                <ListItemSecondaryAction>
                                                                                                    <IconButton edge="end" aria-label="View Rule">
                                                                                                    {loadTooltip(item)}                                                                                                            
                                                                                                    </IconButton>
                                                                                                </ListItemSecondaryAction>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </List> 
                                                        </>
                                                    )
                                                    : (
                                                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>{emptyMessage}</Typography>
                                                    )}                                    
                                                    
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item className='triggerEditorCard'>
                                            <Card variant='outlined'>
                                                <CardContent>
                                                    <Typography variant='h6' color='textSecondary' gutterBottom>Applied Rules</Typography>
                                                </CardContent>
                                                <Divider />
                                                <CardContent>
                                                    <Typography variant='subtitle2' color='textSecondary' gutterBottom>The gift array rules will be applied in the following order of priority:</Typography>
                                                    <List dense={true}>
                                                        <Droppable droppableId={`field_list:rule:2`}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} >
                                                                    {appliedRulesList.length > 0 ? 
                                                                        appliedRulesList.map((item, index) => (
                                                                            <Draggable key={index} draggableId={`${index}:${item.id}:rule`} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={getItemStyle(
                                                                                            snapshot.isDragging,
                                                                                            provided.draggableProps.style
                                                                                        )
                                                                                    }>
                                                                                        <ListItem className='appliedListItems'>
                                                                                            <ListItemText disableTypography primary={<Typography variant="body1">{item.name}</Typography>} />
                                                                                            <ListItemSecondaryAction>
                                                                                                <IconButton edge="end" aria-label="View Rule">
                                                                                                    {loadTooltip(item)}
                                                                                                </IconButton>
                                                                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                                                                                                    <DeleteIcon color="primary" title="Delete" />
                                                                                                </IconButton>                                                                                    
                                                                                            </ListItemSecondaryAction>                                                                        
                                                                                        </ListItem>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))
                                                                    : <div className='dropZone'>
                                                                        <AddCircleTwoToneIcon color='disabled' fontSize='large' />
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </List>                                            
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </DragDropContext>
                            </AccordionDetails>
                        </Accordion>   
                                        
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

export default view(FormDISSettingsEditor)