import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Page from "./Page";
import Grid from "@material-ui/core/Grid";

class PageList extends Component{

    render(){
        const { pages } = this.props;

        return (<Grid container spacing={2}>
            {pages.map((page, index) => (
                <Page key={index} index={index} {...page} />
            ))}
        </Grid>)
    }

}

export default view(PageList);