import React, {Component} from 'react';
import { view } from "@risingstack/react-easy-state";
import { formatQuery, QueryBuilder, parseCEL } from 'react-querybuilder';
import DeleteIcon from '@material-ui/icons/Delete';

class QueryBuilderComponent extends Component {
    state = {
        query: parseCEL(`${this.props.defaultQueryString}`),
        fields: this.props.fieldList
    }
    setQueryString = (queryString) => {
        this.setState( { query: queryString })
        this.props.onChangeEvent(formatQuery(queryString, 'cel'));        
    }

    AddRuleButtons = (props) => {
        return !this.props.isDisabled 
            ? (<button type="button" data-testid="add-rule" className="ruleGroup-addRule" title="Add rule" onClick={(e) => props.handleOnClick(e, "Add Rule")} >
                    Add Rule
                </button>) 
            : (<></>)
    }

    AddGroupButtons = (props) => {
        return !this.props.isDisabled 
            ? ( <button type="button" data-testid="add-group" className="ruleGroup-addGroup" title="Add group" onClick={(e) => props.handleOnClick(e, "Add Group")}>
                Add Group
                </button>)
            : (<></>)
    }
    
    RemoveRuleButtons = (props) => {
        return !this.props.isDisabled 
            ? ( <button type="button" data-testid="remove-rule" className="rule-remove" title="Remove rule" onClick={(e) => props.handleOnClick(e)}>
                <DeleteIcon />
            </button>)
            : (<></>)
    }

    RemoveGroupButtons = (props) => {
        return !this.props.isDisabled 
            ? (<button type="button" data-testid="remove-group" className="ruleGroup-remove" title="Remove group" onClick={(e) => props.handleOnClick(e)}>
                <DeleteIcon />
            </button>)
            : (<></>)
    };

    render = () => {
        
        return (
            <div>
                <QueryBuilder fields={this.state.fields} 
                    query={this.state.query} 
                    onQueryChange={(q) => this.setQueryString(q)} 
                    autoSelectField={this.props.isDisabled}
                    // disabled={this.props.isDisabled}
                    controlElements={{
                        addRuleAction: this.AddRuleButtons,
                        addGroupAction: this.AddGroupButtons,
                        removeRuleAction: this.RemoveRuleButtons,
                        removeGroupAction: this.RemoveGroupButtons
                    }} />         
                <h4>Query</h4>
                <p>{formatQuery(this.state.query, 'cel')}</p>
            </div>
          );
    }
}

export default view(QueryBuilderComponent);