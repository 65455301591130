import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "../Editors/FieldTypes/TextField";
import Button from "@material-ui/core/Button";
import EditFieldState from "../../State/EditFieldState";
import PageEditorState from "../../State/FormPageEditorState";
import FieldEditor from "../Editors/FieldEditor";
import Divider from "@material-ui/core/Divider";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ComponentTypes from "../../State/ComponentTypes";

class AddPage extends Component{

    state = {
        currentTab: 0,
        componentId: null,
        componentType: {}
    }

    handleClose = () => {
        EditFieldState.close();
    };

    handleOpen = () => {
        EditFieldState.open();
    };

    handleSubmit = () => {
        if(!EditFieldState.isError) {
            EditFieldState.saveComponent();
            PageEditorState.emitUpdate();
            this.handleClose();
        }
    };

    a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    getComponentType(){
        const componentId = EditFieldState.componentData.id;
        if(this.state.componentId !== componentId) {
            if(EditFieldState.componentData.custom){
                this.setState({
                    componentType: EditFieldState.componentData,
                    componentId: EditFieldState.componentData.id
                });
            } else {
                ComponentTypes.types.forEach(ComponentType => {
                    if(ComponentType.id === componentId){
                        this.setState({
                            componentType: ComponentType,
                            componentId: componentId
                        })
                    }
                });
            }
        }
        return this.state.componentType;
    }

    getTabs() {
        const tabs = ['General'];
        const componentType = this.getComponentType();
        if(componentType.options){
            componentType.options.forEach((option) => {
                let category = 'General';
                if(typeof option.category !== 'undefined'){
                    category = option.category;
                }
                if(!tabs.includes(category)){
                    tabs.push(category);
                }
            });
        }
        return tabs;
    }

    renderTabContent(){
        const tab = this.getTabs()[this.state.currentTab];
        const componentType = this.getComponentType();
        const options = [];
        if(componentType.options){
            componentType.options.forEach(option => {
                if((typeof option.category === 'undefined' && tab === 'General') || option.category === tab){
                    options.push(option);
                }
            });

            let customOptions = EditFieldState.componentData.options;

            return (<FieldEditor options={options} savedOptions={customOptions} custom={EditFieldState.componentData.custom} isdisabled={true}/>);
        }
        return null;
    }

    render(){
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'lg'} 
                disableBackdropClick={true}
                onClose={() => this.handleClose()} open={EditFieldState.isOpen}>
                <DialogTitle>Edit Field: {EditFieldState.componentData.name}</DialogTitle>
                <Divider />
                <DialogContent>
                    <AppBar position="static" color="default" style={{marginBottom: 10}}>
                        <Tabs
                            value={this.state.currentTab}
                            onChange={(event, value) => this.setState({currentTab: value})}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {this.getTabs().map((tab, index) => (
                                <Tab key={index} label={tab} {...this.a11yProps(index)}></Tab>
                            ))}
                        </Tabs>
                    </AppBar>
                    <div
                        role="tabpanel"
                        id={`scrollable-auto-tabpanel-${this.state.currentTab}`}
                        aria-labelledby={`scrollable-auto-tab-${this.state.currentTab}`}
                    >
                        {this.state.currentTab === 0 && !EditFieldState.componentData.custom && <TextField
                            label='Field Name'
                            value={EditFieldState.componentData.customName}
                            defaultValue={EditFieldState.componentData.name}
                            onChange={(value) => EditFieldState.componentData.name = value}
                        />}
                        {this.renderTabContent()}
                    </div>
                </DialogContent>
                <Divider style={{marginTop:'10px'}}/>
                <DialogActions>
                    <Button onClick={() => {this.handleClose(); EditFieldState.isError = false; }} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );

        /* console.log(EditFieldState.componentData);
        return (
            <Dialog onClose={() => this.handleClose()} open={EditFieldState.isOpen}>
                <DialogTitle>Edit Field</DialogTitle>
                <Divider />
                <DialogContent style={{minWidth: 500}}>
                    <TextField
                        label='Field Name'
                        value={EditFieldState.componentData.customName}
                        defaultValue={EditFieldState.componentData.name}
                        onChange={(value) => EditFieldState.componentData.name = value}
                    />
                    <div>
                        {EditFieldState.componentData.options && <FieldEditor options={EditFieldState.componentData.options}/>}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );*/
    }

}

export default withRouter(view(AddPage));