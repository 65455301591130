import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import PrivateMetaState from "../../State/PrivateMetaState";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormPageEditorState from "../../State/FormPageEditorState";
// import CreativeState from "../../State/CreativeState";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class FormSettingsEditor extends Component{

    setPaymentProvider(type, index){
        let configObject = {};

        if(index === 'none'){
            configObject = {
                type: 'none'
            };
        }else{
            const providerConfig = PrivateMetaState.data['payment-settings']['payment-processors'][index];

            configObject = {
                type: providerConfig.type,
                name: providerConfig.name
            };

            if(providerConfig.type === 'stripe'){
                configObject.publicKey = providerConfig.strpePublic;
            } else if (providerConfig.type === 'braintree'){
                configObject.publicKey = providerConfig.braintreePublic;
            }
        }

        FormPageEditorState.setPaymentProvider(type, configObject);
    }

    findPaymentProvider(type){
        let provider = 'none';

        const configedProvider = FormPageEditorState.paymentProviders[type];

        if(FormPageEditorState.paymentProviders[type] && FormPageEditorState.paymentProviders[type].type !== 'none'){
            let i = 0;
            while(i < PrivateMetaState.data['payment-settings']['payment-processors'].length){

                const currentProvider = PrivateMetaState.data['payment-settings']['payment-processors'][i];

                if(configedProvider.type === currentProvider.type){

                    if(currentProvider.type === 'stripe' && currentProvider.strpePublic === configedProvider.publicKey){
                        provider = i;
                        console.log('stripe card selected');
                    } else if(currentProvider.type === 'braintree' && currentProvider.braintreePublic === configedProvider.publicKey){
                        provider = i;
                    } else if (currentProvider.type === 'ach'){
                        provider = i;
                    }

                }

                i++;
            }
        }

        return provider;
    }

    setFormOption(option, value) {
        FormPageEditorState.setOption(option, value);
    }

    getFormOption(option) {
        return FormPageEditorState.options[option];
    }

    valueToIndex(array, value){
        let i = 0;
        while(i < array.length){
            if(array[i] === value){
                return i;
            }
            i++;
        }
        return 0;
    }

    handleChange = (value) => {
        console.log(value);
        FormPageEditorState.setReCaptcha(value);        
    };

    renderAutoResponderSelection(label, name, tooltip){
        return (<Grid container style={{paddingBottom: 20}}>
            <Grid xs={11}>
                <FormControl style={{width: '100%'}}>
                    <InputLabel htmlFor="credit-card-provider">{label}</InputLabel>
                    <Select native value={this.getFormOption(name)} onChange={(event) => this.setFormOption(name, event.target.value)} inputProps={{ id: 'credit-card-provider'}}>
                        <option aria-label="None" value="none">None (Disabled)</option>
                        {PrivateMetaState.emailData.emails.map((email) => {
                            return (<option value={email}>{email}</option>);
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                <Tooltip title={tooltip} style={{marginTop: 16}}>
                    <HelpIcon />
                </Tooltip>
            </Grid>
        </Grid>);
    }

    renderMailingListSelection(label, name, tooltip){
        return (<Grid container style={{paddingBottom: 20}}>
            <Grid xs={11}>
                <FormControl style={{width: '100%'}}>
                    <InputLabel htmlFor="credit-card-provider">{label}</InputLabel>
                    <Select native value={this.getFormOption(name)} onChange={(event) => this.setFormOption(name, event.target.value)} inputProps={{ id: 'credit-card-provider'}}>
                        <option aria-label="None" value="none">None (Disabled)</option>
                        {PrivateMetaState.emailData.emailLists.map((list) => {
                            return (<option value={list}>{list}</option>);
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                <Tooltip title={tooltip} style={{marginTop: 16}}>
                    <HelpIcon />
                </Tooltip>
            </Grid>
        </Grid>);
    }

    renderCampaignListSelection(label, name) {
        return (<Grid container style={{ paddingBottom: 20 }}>
            <Grid xs={11}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="credit-card-provider">{label}</InputLabel>
                    <Select native value={this.getFormOption(name)} onChange={(event) => this.setFormOption(name, event.target.value)} inputProps={{ id: 'credit-card-provider' }}>
                        <option aria-label="None" value="NONE">None (Disabled)</option>
                        {PrivateMetaState.campaignList.map((list) => {
                            return (<option value={list.Name}>{list.Name}</option>);
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>);
    }

    render() {
        return (
            <div>

                <Grid container spacing={2}>
                    <Grid xs={12} md={6} lg={4} item>

                        {/* PAYMENT PROVIDERS */}
                        {/* <Card variant='outlined'>
                            <CardContent>
                                <Typography variant='h6' color='textSecondary' gutterBottom>Payment Providers</Typography>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <p>Select the payment provider for the following Payment Types</p>
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel htmlFor="credit-card-provider">Credit Cards</InputLabel>
                                    <Select native value={this.findPaymentProvider('creditCard')} onChange={(event) => this.setPaymentProvider('creditCard', event.target.value)} inputProps={{ id: 'credit-card-provider'}}>
                                        <option aria-label="None" value="none">None (Disabled)</option>
                                        {PrivateMetaState.data['payment-settings']['payment-processors'].map((processor, index) => {
                                            if(['stripe', 'braintree'].includes(processor.type)){
                                                return (<option value={index}>{processor.name} ({processor.type})</option>);
                                            }
                                        })}
                                    </Select>
                                </FormControl> <br /><br />
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel htmlFor="credit-card-provider">Apple Pay</InputLabel>
                                    <Select native value={this.findPaymentProvider('applePay')} onChange={(event) => this.setPaymentProvider('applePay', event.target.value)} inputProps={{ id: 'credit-card-provider'}}>
                                        <option aria-label="None" value="none">None (Disabled)</option>
                                        {PrivateMetaState.data['payment-settings']['payment-processors'].map((processor, index) => {
                                            if(['stripe'].includes(processor.type)){
                                                return (<option value={index}>{processor.name} ({processor.type})</option>);
                                            }
                                        })}
                                    </Select>
                                </FormControl> <br /><br />
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel htmlFor="credit-card-provider">PayPal</InputLabel>
                                    <Select native value={this.findPaymentProvider('paypal')} onChange={(event) => this.setPaymentProvider('paypal', event.target.value)} inputProps={{ id: 'credit-card-provider'}}>
                                        <option aria-label="None" value="none">None (Disabled)</option>
                                        {PrivateMetaState.data['payment-settings']['payment-processors'].map((processor, index) => {
                                            if(['braintree'].includes(processor.type)){
                                                return (<option value={index}>{processor.name} ({processor.type})</option>);
                                            }
                                        })}
                                    </Select>
                                </FormControl> <br /><br /> */}
                                {/* <FormControl style={{ width: '100%' }}>
                                    <InputLabel htmlFor="credit-card-provider">Bank ACH</InputLabel>
                                    <Select native value={this.findPaymentProvider('ach')} onChange={(event) => this.setPaymentProvider('ach', event.target.value)} inputProps={{ id: 'credit-card-provider' }}>
                                        <option aria-label="None" value="none">None (Disabled)</option>
                                        {PrivateMetaState.data['payment-settings']['payment-processors'].map((processor, index) => {
                                            if (['ach'].includes(processor.type)) {
                                                return (<option value={index}>{processor.name} ({processor.type})</option>);
                                            }
                                        })}
                                    </Select>
                                </FormControl> */}
                            {/* </CardContent>
                        </Card> */}

                        {/* GOOGLE RECAPTCH */}
                        <Card variant='outlined'>
                            <CardContent>
                                <Typography variant='h6' color='textSecondary' gutterBottom>Google Recaptcha Settings</Typography>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <p>Capture Google Recaptcha Information (Recommended)</p>
                                <FormControl>
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            checked={FormPageEditorState.recaptcha}
                                            onChange={(event) => this.handleChange(event.target.checked)}
                                        />}
                                        label="Recaptcha Captured"
                                        labelPlacement="start"
                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>


                    {/* AUTORESPONDERS */}
                    <Grid xs={12} md={6} lg={4} item>
                        <Card variant='outlined'>
                            <CardContent>
                                <Typography variant='h6' color='textSecondary' gutterBottom>Email Notifications</Typography>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <p>Select the email templates to use.</p>
                                {this.renderAutoResponderSelection('Autoresponder', 'autoresponder', 'Do you want to send a notification email every time a donation is completed?')}
                                {this.renderAutoResponderSelection('Initial Monthly Autoresponder', 'initialMonthlyAutoresponder', 'Do you want to send a notification email everytime when monthly donation is completed?')}
                                {this.renderAutoResponderSelection('Subsequent Monthly Autoresponder', 'subsequentMonthlyAutoresponder', 'Do you want to send a notification email when every recurring payment cut from user account?')}
                                {this.renderAutoResponderSelection('Custom Email Notification', 'customEmailNotification', 'Which email template would you like to be sent to the Custom Notification List assigned below?')}
                                {this.renderMailingListSelection('Custom Notification List', 'customEmailNotificationList', 'Please select the list that you would like the email template assigned above to be sent to.')}
                                {this.renderAutoResponderSelection('Honor Tribute Notification', 'honorTributeNotification', 'Select an email template to be sent to recipients when requested by honorarium donors.')}
                                {this.renderAutoResponderSelection('Memorial Tribute Notification:', 'memorialTributeNotification', 'Select an email template to be sent to recipients when requested by memorial donors.')}
                            </CardContent>
                        </Card>

                        {/* GOOGLE RECAPTCH */}
                        <Card variant='outlined'>
                            <CardContent>
                                <Typography variant='h6' color='textSecondary' gutterBottom>Campaign Settings</Typography>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <p>What campaign is this creative associated with? (Optional)</p>
                                {this.renderCampaignListSelection('Campaign List', 'campaign')}
                            </CardContent>
                        </Card>
                        
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default view(FormSettingsEditor)