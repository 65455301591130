import React, { Component } from 'react';
import { view } from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import AddFieldState from "../../State/AddFieldState";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormPageEditorState from "../../State/FormPageEditorState";
import { CardActions, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getUserInfo } from '../../lib/auth';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

import QueryBuilderComponent from './queryBuilder';

class TriggerNew extends Component {
    constructor (props) {
        super(props);
        this.handleQueryBuilderChange = this.handleQueryBuilderChange.bind(this);
    }

    state = {
        dialogOpen: false,
        isSuperAdmin: true,
        expression: "",
        triggerId: "",
        viewDepedent: false
    };

    actionList = [
        { label: 'Do Nothing', value: 'none' },
        { label: 'Show Attached', value: 'show' },
        { label: 'Hide Attached', value: 'hide' },
    ];

    componentDidMount() {
        const userInfo = getUserInfo();
        if(userInfo['http://one.rkd.io/app_metadata']['type'] !== 'super' ){
            this.setState({ isSuperAdmin: false });
        }
        this.setState({ triggerId: this.props.index });
    }

    renderActionDropdown(variableName) {
        let isDisabled = false;
        try {
            if (this.props.name.includes(FormPageEditorState.errorAfterNAttempsSettings.case.field_id)) {
                isDisabled = this.props.cases[0].isDisabled;
                
            }
        } catch (error) {
            isDisabled = false;
        }
        return (<div style={{ display: 'block', width: '100%' }}><Select
            value={FormPageEditorState.triggers[this.props.index][variableName]}
            id='add-field-select'
            style={{ width: '100%' }}
            disabled={isDisabled}
            onChange={(event) => {
                FormPageEditorState.triggers[this.props.index][variableName] = event.target.value;
                FormPageEditorState.emitUpdate();
            }}
        >
            {this.actionList.map((action, index) => (
                <MenuItem key={index} value={action.value}>{action.label}</MenuItem>
            ))}
        </Select></div>);
    }

    deleteTrigger(id) {
        FormPageEditorState.removeTrigger(id);
    }

    async beforeDeleteTriggerProcess(id) {
        await FormPageEditorState.getTriggerDependencies(id, FormPageEditorState.formId);
        this.setState({dialogOpen: true});
    }

    async getTriggerDependencies(id) {
        await FormPageEditorState.getTriggerDependencies(id, FormPageEditorState.formId);
        this.setState({viewDepedent: true});
    }

    handleQueryBuilderChange (expression) {
        if(FormPageEditorState.triggers[this.props.index].expression !== expression) {
            FormPageEditorState.triggers[this.props.index].expression = expression;
            FormPageEditorState.emitUpdate();        
        }
    }

    getFieldList() {
        const fields = [];
        const fieldIndex = [];
        const componentList = [];

        FormPageEditorState.pages.forEach(page => {
            page.components.forEach(component => {
                componentList.push(component);
            });
        });

        if(FormPageEditorState.thankYouPage) {
            FormPageEditorState.thankYouPage.forEach(page => {
                if(page.pageType === 'section') {
                    page.components.forEach(component => {
                        componentList.push(component);
                    });
                }
            });
        }

        FormPageEditorState.modals.forEach(modal => {
            modal.components.forEach(component => {
                componentList.push(component);
            });
        });

        componentList.map((component) => {
            let id = component.id;
            if (!fieldIndex.includes(id)) {
                if(id.indexOf("-")) {
                    id = id.replace(/-/g, "_");
                }
                fields.push({
                    name: id,
                    label: component.customName || component.thankYouName || component.name,
                    placeholder: "Field Value"
                });
                fieldIndex.push(id);
            }
        });

        if (fields.length > 0) {
            AddFieldState.thankYouValue = fields[0].key;
        }

        fields.push({
            name: "urlParam",
            label: "URL Param",
            placeholder: "param: value",
            operators: [{name: "=", label: "="}]
        });
        
        return fields;
    }

    loadDependencySegment (name) {
        return (
            <React.Fragment>
                {(this.state.viewDepedent && (FormPageEditorState.triggerDependencies.modalList.length < 1 
                    && FormPageEditorState.triggerDependencies.componentList.length < 1
                    && FormPageEditorState.triggerDependencies.sectionList.length < 1
                )) && (
                    <p style={{fontSize: '1rem'}}>This trigger has not been used into any component/modal/element yet. </p>
                )}

                {FormPageEditorState.triggerDependencies.componentList.length > 0 && (
                    <>                                            
                        <h3>{name + " trigger has been applied on the following fields:"}</h3>
                        <ul>
                            {FormPageEditorState.triggerDependencies.componentList.map((item) => (                                                
                                <li>{item}</li>                                                
                            ))}
                        </ul>
                    </>
                )}
                {FormPageEditorState.triggerDependencies.modalList.length > 0 && (
                    <>
                        <h3>{name + " trigger has been applied on the following modals:"}</h3>
                        <ul>
                            {FormPageEditorState.triggerDependencies.modalList.map((item) => (                                                
                                <li>{item}</li>                                                
                            ))}
                        </ul>
                    </>
                )}
                {FormPageEditorState.triggerDependencies.sectionList.length > 0 && (
                    <>
                        <h3>{name + " trigger has been applied on the following elements:"}</h3>
                        <ul>
                            {FormPageEditorState.triggerDependencies.sectionList.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    </>
                )}
                
                {(this.state.dialogOpen && (FormPageEditorState.triggerDependencies.modalList.length > 0 
                    || FormPageEditorState.triggerDependencies.componentList.length > 0
                    || FormPageEditorState.triggerDependencies.sectionList.length > 0 
                )) && (
                    <>
                        <h4><span style={{color: 'red'}}>Note: </span>To delete this trigger, follow these steps:</h4>
                        <ol>
                            <li>Remove the trigger from the above fields or modals</li>
                            <li>Publish the Form</li>
                            <li>Delete this trigger</li>
                        </ol>
                    </>
                )}
            </React.Fragment>
        )
    }

    render () {
        let { name, id, expression} = this.props;

        let fieldList = this.getFieldList();
        
        let isDisabled = false;
        try {
            if (this.props.name.includes(FormPageEditorState.errorAfterNAttempsSettings.case.field_id)) {
                isDisabled = this.props.cases[0].isDisabled;
                fieldList = [{
                    name: FormPageEditorState.errorAfterNAttempsSettings.case.field_id.replace(/-/g, "_"),
                    label: "Lightbox if Donation Error after n attempts",
                    placeholder: "Field Value",
                    operators: [
                        {name: "=", label: "="},
                        { name: '!=', label: '!=' },
                        { name: '<', label: '<' },
                        { name: '>', label: '>' },
                        { name: '<=', label: '<=' },
                        { name: '>=', label: '>=' }
                    ]
                }]
            }
            
        } catch (error) {
            isDisabled = false;
        }


        return (<Grid className='triggerEditorCard' item>
            <Card variant='outlined'>
                <CardContent>
                    <Typography variant='h6' color='textSecondary' gutterBottom>{name}</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div><h4>Conditions</h4></div>
                        <div><Tooltip title='These are the conditions that must occur for this trigger to run. All conditions must be met.'><HelpIcon style={{ height: 20 }} /></Tooltip></div>                        
                    </div>
                    <QueryBuilderComponent isDisabled={isDisabled} fieldList={fieldList} onChangeEvent={this.handleQueryBuilderChange} defaultQueryString={expression} />
                </CardContent>
                <Divider />
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div><h4>Action</h4></div>
                        <div><Tooltip title='This action occurs when all conditions are met. This will occur each time the conditions are met.'><HelpIcon style={{ height: 20 }} /></Tooltip></div>
                    </div>
                    {this.renderActionDropdown('action')}
                    
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div><h4>Inverse Action</h4></div>
                        <div><Tooltip title='This action occurs when all conditions not are met. This will occur each time the conditions are met.'><HelpIcon style={{ height: 20 }} /></Tooltip></div>
                    </div>
                    {this.renderActionDropdown('inverseAction')}
                </CardContent>
                <Divider />
                {this.state.isSuperAdmin && (
                    <>
                        <CardActions style={{alignItems: 'flex-end', display: 'flex', justifyContent: 'right'}}>
                            <Button color='primary' size="small" onClick={() => this.getTriggerDependencies(id)}>View Dependent</Button>
                            <Button color='primary' size="small" onClick={() => this.beforeDeleteTriggerProcess(id)}>Delete</Button>
                        </CardActions>
                        <Divider />

                        {/* Delete trigger dialog */}
                        <Dialog open={this.state.dialogOpen}>
                            <DialogTitle>Delete Trigger</DialogTitle>
                            <DialogContent dividers>
                                <React.Fragment>
                                    Are you sure you want to delete this trigger?
                                </React.Fragment>                                
                                {this.loadDependencySegment(name)}
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus color='primary' onClick={() => this.setState({dialogOpen: false})}>Cancel</Button>
                                <Button onClick={() => {this.deleteTrigger(id); this.setState({dialogOpen: false})}} color='secondary' disabled={!!FormPageEditorState.enableDeleteButton}>Delete</Button>                                
                            </DialogActions>
                        </Dialog>

                        {/* Trigger dependency dialog */}
                        <Dialog open={this.state.viewDepedent}>
                            <DialogTitle>Dependent of <i>{name}</i> Trigger</DialogTitle>
                            <DialogContent dividers>
                                {this.loadDependencySegment(name)}
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus color='primary' onClick={() => this.setState({viewDepedent: false})}>Ok</Button>                                
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </Card>
        </Grid>);
    }
}
export default view(TriggerNew);
