import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import ComponentListState from "../../../State/ComponentListState";
import { DataGrid } from '@mui/x-data-grid';
import Icon from "@material-ui/core/Icon";
import { Redirect, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CreateNewState from "../../../State/CreateNewState";
import UIState from "../../../State/UIState";
import ClientState from "../../../State/ClientState";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CopyComponent from "../../../Components/Dialogs/Copy/CopyComponent";
import CopyComponentState from "../../../State/Component/CopyComponentState";
import DeleteForm from "../../../Components/Dialogs/Delete/DeleteForm";
import DeleteState from "../../../State/Common/DeleteState";
import { mapDomain } from "../../../lib";
import EmbedModalState from "../../../State/EmbedModalState";

function CustomNoRowsOverlay() {

    return (
        <div className='overlayComponent'>No components found.</div>        
    );
}

class FormList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { field: 'id', headerName: 'Component Id', width: 170 },
                {
                    field: 'name',
                    headerName: 'Component Name',
                    width: 400,
                    renderCell: (params) => {
                        return (
                            <React.Fragment>
                                {params.row.name}&nbsp;
                                {params.row.is_draft ? <span style={{ color: 'red' }}>(Draft)</span> : null}
                            </React.Fragment>
                        )
                    }
                },
                { field: 'created_at', headerName: 'Created At', width: 200, type: 'date' },
                { field: 'updated_at', headerName: 'Updated At', width: 200, type: 'date' },
                {
                    field: 'Actions',
                    description: 'You can perform varios actions on the forms.',
                    sortable: false,
                    filterable: false,
                    width: 150,
                    disableColumnMenu: true,
                    renderCell: (params) => {

                        const triggerCopyComponent = (sourceId) => {
                            CopyComponentState.sourceId = sourceId;
                            CopyComponentState.modalOpen = true;
                        };

                        const triggerDeleteComponent = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = true;
                        };

                        const triggerViewDependencies = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = false;
                        }

                        const triggerCopyComponentToClient = (sourceId, sourceName) => {
                            CopyComponentState.sourceId = sourceId;
                            CopyComponentState.sourceName = sourceName;
                            CopyComponentState.modalOpen = true;
                            CopyComponentState.toClient = true;
                        };

                        return (
                            <React.Fragment>
                                <Button onClick={() => this.props.history.push(`/component/${params.row.id}`)}><Icon className='fa fa-edit' style={{ margin: 2, color: 'black' }} /></Button>
                                <PopupState variant="popover" popupId={`action-menu-${params.row.id}`}>
                                    {(popupState) => (
                                        <>
                                            <Button {...bindTrigger(popupState)}>
                                                <Icon className='fa fa-chevron-circle-down list-icon' />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem onClick={() => window.open(mapDomain(`component?c=${params.row.id}&cl=${ClientState.currentClient}`))}>View Live</MenuItem>
                                                <MenuItem onClick={() => window.open(mapDomain(`component?c=${params.row.id}&cl=${ClientState.currentClient}&preview=on`))}>View Preview</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => {
                                                    EmbedModalState.showModal('Component',
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/component' data-cl='${ClientState.currentClient}' data-c='${params.row.id}' data-preview='true' ></script>`,
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/component' data-cl='${ClientState.currentClient}' data-c='${params.row.id}' ></script>`, '', mapDomain(`component?c=${params.row.id}&cl=${ClientState.currentClient}`));
                                                }}>Embed</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => { popupState.close(); triggerCopyComponent(params.row.id); }}>Copy Component</MenuItem>
                                                { ClientState.clientType === "super" &&
                                                    <MenuItem onClick={() => { popupState.close(); triggerCopyComponentToClient(params.row.id, params.row.name); }}>Copy Component To Another Client</MenuItem>
                                                }
                                                <MenuItem onClick={() => { popupState.close(); triggerDeleteComponent(params.row.id, params.row.name, "component"); }}>Delete Component</MenuItem>
                                                <MenuItem onClick={() => { popupState.close(); triggerViewDependencies(params.row.id, params.row.name, "component"); }}>View Dependent</MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </React.Fragment>
                        );
                    }
                }
            ],
            rows: [],
            sortModel: [
                {
                    field: 'id',
                    sort: 'desc',
                },
            ]
        };
    }

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rows.length !== ComponentListState.data.length) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        ComponentListState.initSocket();
        CreateNewState.itemType = 'component';
        UIState.setPageTitle('Components');

        setTimeout(() => {
            this.setState({ rows: ComponentListState.data });
        }, 1000);
    }

    setSortModel = (model) => {
        this.setState({ sortModel: model });
    }

    render() {
        const redirectToPage = ComponentListState.newFormId;
        const hasRedirect = !!(redirectToPage);
        ComponentListState.newFormId = 0;
        return (
            <React.Fragment>
                {hasRedirect && <Redirect to={`/component/${redirectToPage}`} />}
                {!hasRedirect &&
                    <Paper>
                        <div style={{ height: 'calc(100vh - 65px)', width: '100%' }}>
                            <DataGrid
                                rows={this.state.rows}
                                columns={this.state.columns}
                                hideFooterSelectedRowCount={true}
                                disableSelectionOnClick={false}
                                hideFooterPagination={true}
                                sortModel={this.state.sortModel}
                                onSortModelChange={(model) => this.setSortModel(model)}
                                components={{
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    NoResultsOverlay: CustomNoRowsOverlay
                                }}
                            />
                        </div>
                    </Paper>
                }
                <CopyComponent />
                <DeleteForm getInitialData={this.getInitialData} />
            </React.Fragment>
        );
    }

}

export default withRouter(view(FormList));