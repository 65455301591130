import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import RuleBuilder from "./RuleBuilder";
import { Button, Card, CardContent, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { FormControl } from '@mui/material';
import AddRule from '../Dialogs/AddRule';
import AddRuleState from "../../State/AddRuleState";
import GlobalSettingsState from '../../State/GlobalSettingsState';
import RuleState from '../../State/RuleState';
import UIState from '../../State/UIState';
import CopyRule from '../Dialogs/Copy/CopyRule';
import CopyRuleState from '../../State/Rule/CopyRuleState';

class RuleList extends Component{
    state = {
        showRule: false
    }

    componentDidMount() {
        this.getInitialData();
        CopyRuleState.loadDISClients();
    }

    getInitialData = async() => {
        UIState.setPageTitle('Rules');        
        
        let disSettingData = GlobalSettingsState.data.find((obj) => obj.config === "disSettings");
        
        this.setState({showRule: disSettingData && disSettingData.value === '1' ? true : false }, async() => {
            await RuleState.initSocket();
        });        
    }

    saveChanges = (showState) => {
        this.setState({showRule: showState }, () => {
            let objIndex = GlobalSettingsState.data.findIndex((obj) => obj.config === "disSettings");
    
            if (objIndex !== -1) {
                GlobalSettingsState.data[objIndex].config = "disSettings";
                GlobalSettingsState.data[objIndex].value = showState;
            } else {
                GlobalSettingsState.data.push({
                    config: 'disSettings',
                    value: showState
                });
            }
            GlobalSettingsState.saveChanges();
            CopyRuleState.loadDISClients();
        });
    };


    render(){
        const rules = RuleState.data;

        return (<Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
               <Card variant='outlined' className='dis-settings-toggle-panel'>
                   <CardContent>
                       <FormControl>
                           <FormControlLabel
                               control={<Switch
                                   color="primary"
                                   checked={this.state.showRule}
                                   onChange={(event) => { this.saveChanges(event.target.checked); } }
                               />}
                               label="Enable Identity"
                               labelPlacement="start"
                           />
                       </FormControl> 
                       
                       {this.state.showRule === true && 
                            <FormControl style={{float: 'right'}}>
                                <AddRule />
                                <Button color='primary' size="medium" className='create-rule' onClick={() => AddRuleState.isOpen = true}>Create New Rule</Button>
                                <CopyRule />
                            </FormControl>
                       }                                   
                   </CardContent>
               </Card>
                {this.state.showRule === true && 
                    <Grid container spacing={1}>
                        {rules.map((rule, index) => <RuleBuilder key={index} {...rule} />)}
                    </Grid> 
                } 
           </Grid>
       </Grid>);
    }

}

export default view(RuleList);