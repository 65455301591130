import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FormPageEditorState from "../../../State/FormPageEditorState";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

class TriggerField extends Component{

    getRowFields = (row, rowIndex) => {
        const { value } = this.props;
        const {triggers} = FormPageEditorState;

        return (
            <Grid container spacing={2} key={rowIndex} style={{paddingBottom: 10}}>
                <Grid item xs={11}>
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Trigger
                    </InputLabel>
                    <Select
                        value={value[rowIndex]}
                        id='add-field-select'
                        style={{width: '100%'}}
                        label='Field'
                        placeholder='Field'
                        onChange={(event) => {
                            value[rowIndex] = event.target.value;
                        }}
                    >
                        {triggers.map((trigger, index) => (
                            <MenuItem key={index} value={trigger.id}>{trigger.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={1}>
                    <IconButton aria-label="delete">
                        <DeleteIcon onClick={() => {
                            value.splice(rowIndex, 1);
                        }} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    render(){
        const { option, value } = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={11}>{option.field}</Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => value.push('')}>
                            <AddBoxIcon />
                        </Button>
                    </Grid>
                </Grid>
                {value.map((value, index) => this.getRowFields(value, index))}
            </>
        );
    }

}

export default view(TriggerField);