import React, {Component} from 'react';
import {view} from '@risingstack/react-easy-state';
import LoggedInTemplate from "./LoggedInTemplate";
import AppLoadingTemplate from "./AppLoadingTemplate";
import {isAuthorized, startSession, getAuth0Object, getAuth0Config} from "../lib/auth";
import LoggedOutTemplate from "./LoggedOutTemplate";
import {getParameterByName, removeURLParameter} from "../lib/URL";
import qs from 'querystring'
import axios from 'axios';
import ClientState from "../State/ClientState";

class MainTemplate extends Component{

    state = {
        loading: true,
        sessionValid: false
    }

    async componentDidMount() {
        const apiAccessToken = getParameterByName('api_access_token');
        const idToken = getParameterByName('id_token');
        const accessToken = getParameterByName('access_token');
        const clientName = getParameterByName('client_name');

        const hashStr = window.location.hash.replace(/^#?\/?/, '');
        const parsedQs = qs.parse(hashStr);

        if(parsedQs.access_token && parsedQs.id_token) { //Auth0 based sign in
            const auth0 = getAuth0Object();
            auth0.parseHash(async (err, authResult) => {
                if(authResult && authResult.accessToken && authResult.idToken){
                    const accessToken = authResult.accessToken;
                    const idToken = authResult.idToken;
                    let apiAccessToken;
                    const auth0Config = getAuth0Config();
                    const accessTokenData = await axios.get(auth0Config.usersAPI + 'get/token', { headers: { 'Email': authResult.idTokenPayload.email } })
                    apiAccessToken = accessTokenData.data.data.access_token;

                    if(accessToken && idToken && apiAccessToken){
                        if(window.history.pushState){ // If the browser supports pushing a new URL to the history without a reload, let's do that.
                            window.history.pushState({}, null, '/')
                            this.setState({
                                loading: false,
                                sessionValid: await startSession(apiAccessToken, idToken, accessToken)
                            });
                        } else { // If we have to refresh the page (validating the session for a second time), we can do that
                            window.location = '/';
                        }
                    } else {
                        this.setState({
                            loading: false,
                            sessionValid: false
                        });
                    }

                } else {
                    if(err){
                        console.error(err)
                    }
                    console.log('Could not validate auth0 session.');
                    this.setState({
                        loading: false,
                        sessionValid: false
                    });
                }
            })
        } else if(apiAccessToken && idToken && accessToken){ // existing session is being passed in
            // When we redirect, we don't need these params in the URL anymore -- If they were there, it would cause a reauth every time the page loaded.
            let redirectURL = removeURLParameter(window.location.href, 'api_access_token');
            redirectURL = removeURLParameter(redirectURL, 'id_token');
            redirectURL = removeURLParameter(redirectURL, 'access_token');
            redirectURL = removeURLParameter(redirectURL, 'client_name');
            window.localStorage.setItem(ClientState.clientContextVariable, clientName);

            if(window.history.pushState){ // If the browser supports pushing a new URL to the history without a reload, let's do that.
                window.history.pushState({}, null, redirectURL)
                this.setState({
                    loading: false,
                    sessionValid: await startSession(apiAccessToken, idToken, accessToken)
                });
            } else { // If we have to refresh the page (validating the session for a second time), we can do that
                window.location = redirectURL;
            }
        } else {
            this.setState({
                loading: false,
                sessionValid: await isAuthorized()
            });
        }
    }

    render(){
        if(this.state.loading){
            return <AppLoadingTemplate />
        } else if(this.state.sessionValid){
            return <LoggedInTemplate />
        } else {
            return <LoggedOutTemplate />
        }
    }

}

export default view(MainTemplate);