import { store } from '@risingstack/react-easy-state'
import GlobalVariableSocket from "../Sockets/GlobalVariableSocket";
import GlobalSettingsState from './GlobalSettingsState';

const GlobalVariableState = store({
    isOpen: false,
    data: [],
    deletes: [],
    socket: null,
    initSocket: () => {
        if(!GlobalVariableState.socket){
            GlobalVariableSocket.init();
            GlobalVariableState.socket = GlobalVariableSocket.socket;
            GlobalVariableState.receiveUpdate();
        }
    },
    reinitSocket: (client, callback = null) => {
        if(GlobalVariableState.socket){
            GlobalVariableState.socket.close();
            GlobalVariableState.socket = null;
        }
        GlobalVariableSocket.socket = null;
        GlobalVariableState.initSocket();

        if(typeof callback === 'function'){
            callback();
        }
    },
    emitUpdate: () => {
        GlobalVariableState.socket.emit('delete', GlobalVariableState.deletes);
        GlobalVariableState.socket.emit('update', GlobalVariableState.data);
    },
    receiveUpdate: () => {
        GlobalVariableState.socket.on('serverUpdate', (data) => {
            GlobalVariableState.data = data;
        });
    },
    open: () => {
        GlobalVariableState.isOpen = true;
        GlobalSettingsState.isCheckExistsErrorGlobal = false;
        GlobalVariableState.socket.emit('requestedUpdate');
    },
    close: () => {
        GlobalVariableState.isOpen = false;
    },
    toggleOpen: () => {
        GlobalVariableState.isOpen = !GlobalVariableState.isOpen
    },
    performDelete: (index) => {
        if(GlobalVariableState.data[index].id){
            GlobalVariableState.deletes.push(GlobalVariableState.data[index].id);
        }

        if(GlobalVariableState.data.length < 2){
            GlobalVariableState.data = [];
        } else {
            const newArray = GlobalVariableState.data.slice(0, index);
            GlobalVariableState.data.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            GlobalVariableState.data = newArray;
        }
    }
});

export default GlobalVariableState;