import React, { useEffect, useState } from 'react';
import { view } from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { rulesSettings, valueTypesList } from '../../lib/rulesSettings'
import { Button, Card, CardActions, CardContent, Divider, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import QueryBuilderComponent from '../Triggers2/queryBuilder';
import RuleState from '../../State/RuleState';
import { getUserInfo } from '../../lib/auth';
import AddRuleState from '../../State/AddRuleState';
import CopyRuleState from '../../State/Rule/CopyRuleState';
import { isObject } from 'lodash';


const RuleBuilder = (props) => {
    const { name, id, rule_state } = props;

    const oldRuleState = JSON.parse(rule_state);

    const [randomKeyForQB, setRandomKeyQB] = useState(Math.random());

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [isSuperAdmin, setIsSuperAdmin] = useState(true);
    const [error, setError] = useState(false);
    
    const [oldQuery, setOldQuery] = useState(oldRuleState.expression || "1 == 1");    
    const [newQuery, setNewQuery] = useState(oldRuleState.expression || "1 == 1");

    const [singleDonationAmountError, setSingleDonationAmountError] = useState('');
    const [recurringDonationAmountError, setRecurringDonationAmountError] = useState('');
    const [donationAmountError, setDonationAmountError] = useState('');

    const [viewDependent, setViewDependent] = useState(false);

    const oldAction = {
        valueType: oldRuleState.action.valueType || "percent",
        donationValue: oldRuleState.action.donationValue || "",
        vary: oldRuleState.action.vary || "increase",
        multiplier: oldRuleState.action.multiplier || "10"
    };
    const [action, setAction] = useState({
        valueType: oldRuleState.action.valueType || "percent",
        donationValue: oldRuleState.action.donationValue || "",
        vary: oldRuleState.action.vary || "increase",
        multiplier: oldRuleState.action.multiplier || "10"
    })

    useEffect(() => {
        // setRandomKeyQB(Math.random());
        const userInfo = getUserInfo();
        if(userInfo['http://one.rkd.io/app_metadata']['type'] !== 'super' ){
            setIsSuperAdmin(false);
            setShowSaveButton(true);
        }
    }, [])

    useEffect(() => {
        if(newQuery !== oldQuery) {
            setShowSaveButton(false);
        } else {
            setShowSaveButton(true);
        }
    }, [newQuery])
    
    useEffect(() => {
        if(JSON.stringify(oldAction) !== JSON.stringify(action)) {
            setShowSaveButton(false);
        } else {
            setShowSaveButton(true);
        }
    }, [action])

    useEffect(() => {
        if(action.donationValue.donationAmountPercent) {
            setDonationAmountError("");
        }
    }, [action.donationValue.donationAmountPercent])

    useEffect(() => {
        if(action.valueType === 'array' && isObject(action.donationValue) && action.donationValue.hasOwnProperty('singleDonationAmount')) {
            if(!action.donationValue.singleDonationAmount.match(/^[\d ]+(?:,[\d ]+){0,5}$/g)) {
                setSingleDonationAmountError('Please enter valid single donation amounts')
            } else {
                setSingleDonationAmountError('');
                setError(false);
            }
        }        
    }, [action.donationValue.singleDonationAmount]);

    useEffect(() => {
        if(action.valueType === 'array' && isObject(action.donationValue) && action.donationValue.hasOwnProperty('recurringDonationAmount')) {
            if(!action.donationValue.recurringDonationAmount.match(/^[\d ]+(?:,[\d ]+){0,5}$/g)) {
                setRecurringDonationAmountError('Please enter valid recurring donation amounts')
            } else {
                setRecurringDonationAmountError('');
                setError(false);
            }
        }
    }, [action.donationValue.recurringDonationAmount])

    const getFieldList = () => {
        const fields = [];

        const ruleConditions = Object.keys(rulesSettings);

        ruleConditions.map((rule) => {
            fields.push({
                name: rule,
                label: rulesSettings[rule].title,
                placeholder: rulesSettings[rule].placeholder || "",
                operators: rulesSettings[rule].operators,
                valueEditorType: rulesSettings[rule].conditionValueType,
                values: rulesSettings[rule].conditionValue
            });
        });
        
        return fields;
    }

    const handleQueryBuilderChange = (expression) => {
        console.log('expression: ', expression);        
        setNewQuery(expression);        
    }

    const valueTypeChange = (e) => {
        setAction({ ...action, valueType: e.target.value, donationValue: {donationAmountPercent: ''} }); 
        // setShowSaveButton(false); 
        setError(false); 
        setSingleDonationAmountError("");
        setRecurringDonationAmountError("");
    }

    const renderActionPanel = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', columnGap: '15px'}}>
                <FormControl style={{minWidth: '100px'}}>
                    <Select native value={action.valueType} onChange={(e) => valueTypeChange(e) } >
                        {valueTypesList.map((item, index) =>
                            <option key={index} value={item.id}>{item.value}</option>
                        )}
                    </Select>
                </FormControl>

                <FormControl>
                    {action.valueType === 'percent' 
                    ? (
                        <div style={{display: 'flex', flexDirection: 'row', columnGap: '5px'}}>
                            <TextField value={action.donationValue.donationAmountPercent} 
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                type={valueTypesList.find((e) => e.id === action.valueType).type}
                                placeholder="Value" 
                                helperText={donationAmountError || "Note: Enter value in percentage" }
                                error={donationAmountError ? true : false}
                                onChange={(e) => { setAction({ ...action, donationValue: { ...action.donationValue, donationAmountPercent: e.target.value } });}} />
                                <p style={{margin: '7px 0', fontWeight: 'bold'}}>%</p>
                        </div>
                        ) 
                    : (
                        <div style={{display: 'flex', flexDirection: 'row', columnGap: '20px'}}>
                            <TextField 
                                style={{flexBasis: '100%', minWidth: '0'}}
                                value={action.donationValue.singleDonationAmount ? action.donationValue.singleDonationAmount : ""} 
                                type='text'
                                placeholder="Single Donation Amounts" 
                                helperText={ singleDonationAmountError || "Note: Enter comma separated Single Donation amounts (i.e.) 10, 20, 30, 40" }
                                error={singleDonationAmountError ? true : false}
                                onChange={(e) => { setAction({ ...action, donationValue: { ...action.donationValue, singleDonationAmount: e.target.value } }); }} />

                            <TextField 
                                style={{flexBasis: '100%', minWidth: '0'}}
                                value={action.donationValue.recurringDonationAmount || ""} 
                                type='text'
                                placeholder="Recurring Donation Amounts" 
                                helperText={ recurringDonationAmountError || "Note: Enter comma separated Recurring Donation amounts (i.e) 20, 30, 40, 60" }
                                error={recurringDonationAmountError ? true : false}
                                onChange={(e) => { setAction({ ...action, donationValue: { ...action.donationValue, recurringDonationAmount: e.target.value } }); }} />
                         
                        </div>
                        ) 
                    }                    
                </FormControl>
                
                {action.valueType === 'percent' && (
                    <>
                        <FormControl>
                            <Select native value={action.vary} onChange={(e) => { setAction({ ...action, vary: e.target.value }); }} >
                                <option value="increase">Increase</option>
                                <option value="decrease">Decrease</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <Select native value={action.multiplier} onChange={(e) => { setAction({ ...action, multiplier: e.target.value }); }} >
                                <option value="5">5 Multiple</option>
                                <option value="10">10 Multiple</option>
                                <option value="20">20 Multiple</option>
                                <option value="50">50 Multiple</option>
                                <option value="100">100 Multiple</option>
                            </Select>
                        </FormControl>
                    </>
                )}
                
            </div>
        )
    }

    const deleteRule = async() => {
        await RuleState.deleteRule(id);
        setIsDialogOpen(false);
    }

    const updateRule = () => {
        let canUpdate = true;
        if(action.valueType === 'array') {
            if(!action.donationValue.hasOwnProperty('recurringDonationAmount')) {
                setRecurringDonationAmountError('Please enter valid recurring donation amounts');
                setError(true);
                canUpdate = false;
            }
            if(!action.donationValue.hasOwnProperty('singleDonationAmount')) {
                setSingleDonationAmountError('Please enter valid single donation amounts');
                setError(true);
                canUpdate = false;
            }
            
        } else if(action.valueType === 'percent' && action.donationValue.hasOwnProperty('donationAmountPercent') && action.donationValue.donationAmountPercent === "") {
            setDonationAmountError('Please enter value in percentage');
            canUpdate = false;
        }  
        
        if(canUpdate) {
            AddRuleState.ruleJson = {
                expression: newQuery,
                action: {
                    valueType: action.valueType,
                    donationValue: action.valueType === 'array' 
                        ? { 
                            singleDonationAmount: action.donationValue.singleDonationAmount.replace(/,\s*$/, ""), 
                            recurringDonationAmount: action.donationValue.recurringDonationAmount.replace(/,\s*$/, "")
                            } 
                        : { donationAmountPercent: action.donationValue.donationAmountPercent},
                    vary: action.vary,
                    multiplier: action.multiplier
                }
            }
            RuleState.updateRule(id);
            setOldQuery(newQuery);
            setShowSaveButton(true);
            setRandomKeyQB(Math.random());
        }
    }

    const resetRule = () => {
        setRandomKeyQB(Math.random());
        setNewQuery(oldQuery);
        setAction(oldAction);
        setShowSaveButton(true);
        setError(false);
        RuleState.receiveUpdate();
    }

    const getRuleDependencies = async(id) => {
        await RuleState.getRuleDependencies(id);
        setViewDependent(true);
    }

    const beforeDeleteProcess = async(id) => {
        await RuleState.getRuleDependencies(id);
        setIsDialogOpen(true);
    }

    const loadDependencySegment = (name) => {
        return (
            <React.Fragment>
                {(viewDependent && RuleState.ruleDependencies.length < 1) && (
                    <p style={{fontSize: '1rem'}}>This rule has not been applied on any form. </p>
                )}

                {RuleState.ruleDependencies.length > 0 && (
                    <>                                            
                        <h3>{name + " rule has been applied on the following forms:"}</h3>
                        <ul>
                            {RuleState.ruleDependencies.map((item) => (                                                
                                <li>{item}</li>                                                
                            ))}
                        </ul>
                    </>
                )}
                
                {(isDialogOpen && RuleState.ruleDependencies.length > 0) && (
                    <>
                        <h4><span style={{color: 'red'}}>Note: </span>To delete this rule, follow these steps:</h4>
                        <ol>
                            <li>Remove the rule from the above forms</li>
                            <li>Publish the Form</li>
                            <li>Delete this rule</li>
                        </ol>
                    </>
                )}
            </React.Fragment>
        )
    }

    return (
        <Grid className='ruleEditorCard' item>
            {console.log('render: ', action.donationValue)}
            <Card variant='outlined'>
                <CardContent>
                    <Typography variant='h6' color='textSecondary' gutterBottom style={{ flexGrow: "8" }}>{name}</Typography>
                </CardContent>
                <Divider />

                <CardContent>
                    <div style={{display: 'flex', flexDirection: 'row', rowGap: '2' }}>
                        <div style={{width: '49%'}}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div><h4>Conditions</h4></div>
                                <div><Tooltip title='These are the conditions that must occur for this rule to apply. All conditions must be met.'><HelpIcon style={{ height: 20 }} /></Tooltip></div>                        
                            </div>
                            <QueryBuilderComponent key={randomKeyForQB} showQuery={false} isDisabled={false} fieldList={getFieldList()} onChangeEvent={handleQueryBuilderChange} defaultQueryString={newQuery} />
                        </div>
                        <div style={{width: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Divider orientation='vertical' style={{ justifyContent: 'center', alignItems: 'center' }} />
                        </div>
                        <div style={{width: '49%'}}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div><h4>Resulting Gift Array Amounts</h4></div>
                                <div><Tooltip title='This action occurs when all conditions are met. This will occur each time the conditions are met.'><HelpIcon style={{ height: 20 }} /></Tooltip></div>
                            </div>
                            {error && <div style={{color: 'red', padding: '0px 0 15px 0'}}>Please check the donation values</div>}
                            {renderActionPanel()}
                        </div>
                    </div>
                </CardContent>
                <Divider /> 
                <CardContent>
                    <h4>Query</h4>
                    <p><b>Old Condition: </b>{oldQuery}</p>
                    <p><b>New Condition: </b>{newQuery}</p>
                </CardContent>
                {isSuperAdmin && (<>
                <Divider /> 
                <CardActions style={{alignItems: 'flex-end', display: 'flex', justifyContent: 'right'}}>
                    <Button color='primary' size="small" onClick={() => getRuleDependencies(id)}>View Dependent</Button>
                    <Button color='primary' size="small" onClick={() => { CopyRuleState.modalOpen = true; CopyRuleState.sourceName = name; CopyRuleState.sourceId = id; }}>Copy</Button>
                    <Button color='primary' size="small" onClick={() => { beforeDeleteProcess(id) }}>Delete</Button>
                    <Button color='primary' size="small" onClick={() => { updateRule() }} disabled={showSaveButton}>Save</Button>
                    <Button color='primary' size="small" onClick={() => { resetRule() }} disabled={showSaveButton}>Abandon Changes</Button>
                </CardActions> </>)}
            </Card>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Delete Rule</DialogTitle>
                <DialogContent dividers>
                    <React.Fragment>
                        Are you sure you want to delete this rule?
                    </React.Fragment>      
                    {loadDependencySegment(name)}             
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color='primary' onClick={() => setIsDialogOpen(false) }>Cancel</Button>
                    <Button onClick={() => deleteRule() } color='secondary' disabled={RuleState.enableDeleteButton} >Delete</Button>                                
                </DialogActions>
            </Dialog>
            <Dialog open={viewDependent}>
                <DialogTitle>Dependent of <i>{name}</i> Rule</DialogTitle>
                <DialogContent dividers>
                    {loadDependencySegment(name)}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color='primary' onClick={() => setViewDependent(false) }>Ok</Button>                                
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default view(RuleBuilder);