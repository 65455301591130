import React, {Component} from "react";
import {view} from "@risingstack/react-easy-state";
import AddFieldState from "../../State/AddFieldState";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormPageEditorState from "../../State/FormPageEditorState";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import PageEditorState from "../../State/FormPageEditorState";

class AddThankYouField extends Component{

    handleClose(){
        AddFieldState.closeThankYou();
    }

    handleSubmit(){
        PageEditorState.addThankYouField(AddFieldState.thankYouValue);
        // reset the thankYouValue state value to 0
        AddFieldState.thankYouValue = 0;
        this.handleClose();
    }

    getFieldList() {
        const fields = [];
        const fieldIndex = [];

        FormPageEditorState.pages.forEach(page => {
            page.components.forEach(component => {
                if(component.id === "thankYouPageCustomHTML")
                {
                    if(
                        (typeof component.thankYouAllowed === 'undefined' || component.thankYouAllowed) // Either the component is allowed on the thank you page, or it's not defined -- defaulting to allowed
                        && !fieldIndex.includes(component.dataId) // The field is not already in the field index
                        && !PageEditorState.confirmationFields.includes(component.dataId) // The field is not already on the thank you page
                    ){
                        fields.push({
                            key: component.dataId,
                            label: component.customName || component.thankYouName || component.name
                        });
                        fieldIndex.push(component.dataId);
                    }
                }
                else
                {

                    if(
                        (typeof component.thankYouAllowed === 'undefined' || component.thankYouAllowed) // Either the component is allowed on the thank you page, or it's not defined -- defaulting to allowed
                        && !fieldIndex.includes(component.id) // The field is not already in the field index
                        && !PageEditorState.confirmationFields.includes(component.id) // The field is not already on the thank you page
                    ){
                        fields.push({
                            key: component.id,
                            label: component.customName || component.thankYouName || component.name
                        });
                        fieldIndex.push(component.id);
                    }
                }
            });
        });

        FormPageEditorState.modals.forEach(modal => {
            modal.components.forEach(component => {
                if(
                    (typeof component.thankYouAllowed === 'undefined' || component.thankYouAllowed) // Either the component is allowed on the thank you page, or it's not defined -- defaulting to allowed
                    && !fieldIndex.includes(component.id) // The field is not already in the field index
                    && !PageEditorState.confirmationFields.includes(component.id) // The field is not already on the thank you page
                ){
                    fields.push({
                        key: component.id,
                        label: component.customName || component.thankYouName || component.name
                    });
                    fieldIndex.push(component.id);
                }
            });
        });

        // if(fields.length > 0){
        //     AddFieldState.thankYouValue = fields[0].key;
        // }
        return fields;
    }

    render() {
        const fieldList = this.getFieldList();
        return (
            <Dialog onClose={() => this.handleClose()} open={AddFieldState.isThankYouOpen}>
                <DialogTitle>Add Field</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>Select the field you would like to add</DialogContentText>
                    <Select
                        value={AddFieldState.thankYouValue}
                        id='add-field-select'
                        style={{width: '100%'}}
                        onChange={(event) => AddFieldState.thankYouValue = event.target.value}
                    >
                        <MenuItem value={0}><em>--Select the field--</em></MenuItem>
                        {fieldList.map((field, index) => (
                            <MenuItem key={index} value={field.key}>{field.label}</MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} disabled={AddFieldState.thankYouValue === 0} color='primary'>Add Field</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AddThankYouField)