import { store } from "@risingstack/react-easy-state";

const AddModalState = store({
    isOpen: false,
    value: '',
    open: () => {
        AddModalState.isOpen = true;
    },
    close: () => {
        AddModalState.isOpen = false;
    },
    toggleOpen: () => {
        AddModalState.isOpen = !AddModalState.isOpen
    }
});

export default AddModalState;