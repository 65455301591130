import {store} from '@risingstack/react-easy-state';
import PageEditorState from "./FormPageEditorState";

const EditPageTriggersState = store({
    isOpen: false,
    triggers: [],
    page: 0,
    open: (page) => {
        EditPageTriggersState.page = page;
        EditPageTriggersState.triggers = PageEditorState.pages[page] && PageEditorState.pages[page].triggers ? PageEditorState.pages[page].triggers : [];
        EditPageTriggersState.isOpen = true;
    },
    close: () => {
        EditPageTriggersState.isOpen = false;
    },
    toggleOpen: () => {
        EditPageTriggersState.isOpen = !EditPageTriggersState.isOpen
    }
});

export default EditPageTriggersState;