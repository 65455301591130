import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import CopyFormState from "../../../State/Form/CopyFormState";
import FormListState from "../../../State/FormListState";
import ClientState from '../../../State/ClientState';

class CopyForm extends Component {

    state = {
        isError: false,
        toClient: "none",
        toClientValidate: false
    }

    formNameMaxLength = 49

    async handleSubmit() {
        let isFormValidate = true;
        if (CopyFormState.dynamicElementName.trim() === "") {
            isFormValidate = false;
            this.setState({ isError: true });
        }
        
        if(CopyFormState.toClient && this.state.toClient === 'none') {
            isFormValidate = false;
            this.setState({ toClientValidate: true });
        }

        if(isFormValidate)
        {
            this.setState({ isError: false, toClientValidate: false });
            CopyFormState.targetClient = CopyFormState.toClient ? this.state.toClient : "";
            await FormListState.copyForm(CopyFormState.sourceId, CopyFormState.dynamicElementName, CopyFormState.sourceName, CopyFormState.toClient ? this.state.toClient : "");
        }
    }

    handleClose() {
        this.setState({
            isError: false,
            toClient: "none",
            toClientValidate: false
        });
        CopyFormState.modalOpen = false;
        CopyFormState.toClient = false;
        CopyFormState.targetClient = "";
        CopyFormState.dynamicElementName = ""; 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === "name") {
            CopyFormState.dynamicElementName = event.target.value;
            if(event.target.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
            }
        }

        if(CopyFormState.toClient && event.target.name === "toClient") {
            if (event.target.value !== "none") {
                this.setState({ toClientValidate: false });
                FormListState.getDynamicElementName(CopyFormState.sourceName, event.target.value);
            } else {
                this.setState({ toClientValidate: true });
            }
        }
    }

    render() {
        return (
            <>

                <Dialog onClose={() => this.handleClose()} open={CopyFormState.modalOpen}>
                    <DialogTitle>Copy Form {CopyFormState.toClient ? 'To Another Client' : ''}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the form a name to get started.</DialogContentText>
                        {CopyFormState.toClient &&
                            <React.Fragment>
                                <FormControl fullWidth error={ this.state.toClientValidate }>
                                    <InputLabel id={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}>Client</InputLabel>
                                    <Select 
                                        autoFocus
                                        fullWidth 
                                        labelId={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`} 
                                        id={`client-select${this.state.toClientValidate ? `-error` : ``}`} 
                                        name='toClient' 
                                        value={this.state.toClient} 
                                        onChange={(event) => this.handleChange(event)}
                                        >
                                            <MenuItem value="none" key="0">Please Select</MenuItem>
                                            {ClientState.clientList.map((client, key) => (
                                                <MenuItem value={client} key={key+1}>{client}</MenuItem>
                                            ))}
                                    </Select>
                                    { this.state.toClientValidate && 
                                        <FormHelperText>Please Select Client</FormHelperText>
                                    }
                                </FormControl>
                            </React.Fragment>
                        }
                        <TextField
                            margin='dense'
                            label={"Form Name"}
                            name='name'
                            type='text'
                            value={CopyFormState.dynamicElementName}
                            onChange={(event) => this.handleChange(event)}
                            fullWidth
                            inputProps={{ maxLength: this.formNameMaxLength }}
                            helperText={this.state.isError ? `Please Enter Form Name` : ""}
                            error={this.state.isError}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!FormListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'>
                                Copy Form
                        </Button>
                        }
                        {FormListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'
                                disabled={true}>
                                Copy Form  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    FormListState.isDuplicateCreative && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={FormListState.isDuplicateCreative} autoHideDuration={10000}
                        onClose={() => FormListState.isDuplicateCreative = false}>

                        <Alert variant="filled" severity="error" onClose={() => FormListState.isDuplicateCreative = false}>
                            {FormListState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>

        );
    }

}

export default view(CopyForm);

