import { store } from '@risingstack/react-easy-state';
import AssetManagerSocket from "../Sockets/AssetManagerSocket";
import _ from 'lodash';
import axios from 'axios';
import mime from "mime";

const AssetManagerState = store({
    historyIndex: 0,
    directoryHistory: [],
    fileList: [],
    socket: null,
    initialLoad: false,
    isOverlayOpen: false,
    currentVariable: null,
    publicUrlCallback: null,
    baseUrl: null,
    createFolderOpen: false,
    newFolderName: '',
    uploadFileCount: 0,
    uploadedFiles: 0,
    uploaderVisible: false,
    contextAction: null,
    isOpenUploadAssetsManageModal: false,
    dependencies: [],
    isDependencyLoading: false,
    initSocket: () => {
        if(!AssetManagerState.socket){
            AssetManagerSocket.init();
            AssetManagerState.socket = AssetManagerSocket.socket;
        }
        AssetManagerState.subscribeSocketEvents();
    },
    reinitSocket: (client, callback = null) => {
        if(AssetManagerState.socket){
            //AssetManagerState.socket.close();
            AssetManagerState.socket = null;
        }
        //AssetManagerSocket.socket = null;
        AssetManagerState.initSocket();

        if(typeof callback === 'function'){
            callback();
        }
    },
    changeDir: (dir) => {
        console.log('Change Dir' + dir);

        if(AssetManagerState.historyIndex !== AssetManagerState.directoryHistory.length){
            let newHistory = _.cloneDeep(AssetManagerState.directoryHistory).slice(0, AssetManagerState.historyIndex + 1);
            AssetManagerState.directoryHistory = newHistory;
            AssetManagerState.historyIndex = newHistory.length;
        }else{
            AssetManagerState.historyIndex = AssetManagerState.directoryHistory.length;
        }
        AssetManagerState.socket.emit('changeDirectory', dir);

    },
    currentDirectory: () => {
        return AssetManagerState.directoryHistory[AssetManagerState.historyIndex];
    },
    refresh: () => {
        AssetManagerState.socket.emit('clientRefresh');
    },
    previousDirectory: () => {
        if(AssetManagerState.historyIndex > 0){
            AssetManagerState.historyIndex = AssetManagerState.historyIndex - 1;
            AssetManagerState.socket.emit('changeDirectory',
                AssetManagerState.getCurrentDirectory().Prefix ?? '/'
            );
        }
    },
    nextDirectory(){
        if(AssetManagerState.historyIndex < AssetManagerState.directoryHistory.length - 1){
            AssetManagerState.historyIndex++;
            AssetManagerState.socket.emit('changeDirectory',
                AssetManagerState.getCurrentDirectory().Prefix ?? '/'
            );
        }
    },
    createDirectory(path){
        AssetManagerState.socket.emit('createFolder', path);
    },
    uploadFiles(path, fileList){
        AssetManagerState.uploadedFiles = 0;
        AssetManagerState.uploadFileCount = fileList.length;
        AssetManagerState.uploaderVisible = true;
        // eslint-disable-next-line array-callback-return
        fileList.map((file, index) => {
            const filepath = file.path.replace(/(\/)(\1+)/g, "/");
            const randomKey = Math.floor(Math.random() * 999999999);
            const mimeType = mime.getType(file.path) ?? file.type;
            AssetManagerState.socket.on('uploadSignature:'+randomKey, async function(url){
                try{
                    console.log(mimeType);
                    await axios.put(url, file, {
                        headers: {
                            'Content-Type': mimeType,
                            'x-amz-acl': 'public-read'
                        }
                    });
                } catch (e) {

                }
                AssetManagerState.uploadedFiles = AssetManagerState.uploadedFiles + 1;
                if(AssetManagerState.uploadFileCount <= AssetManagerState.uploadedFiles){
                    AssetManagerState.uploaderVisible = false;
                    AssetManagerState.refresh();
                }
            });
            AssetManagerState.socket.emit('requestUploadSignature', {
                randomKey: randomKey,
                filepath: filepath,
                type: mimeType
            });
        });
    },
    deleteFile: (filename) => {
        AssetManagerState.socket.emit('deleteFile', filename);
    },
    deleteFolder: (prefix) => {
        AssetManagerState.socket.emit('deleteFolder', prefix);
    },
    copyFile: (fileName) => {
        AssetManagerState.socket.emit('setFileCopyPath', fileName);
        AssetManagerState.contextAction = 'copy_file'
    },
    copyFolder: (folderName) => {
        AssetManagerState.socket.emit('setFolderCopyPath', folderName);
        AssetManagerState.contextAction = 'copy_folder'
    },
    cutFile: (fileName) => {
        AssetManagerState.socket.emit('setFileCutPath', fileName);
        AssetManagerState.contextAction = 'cut_file'
    },
    cutFolder: (folderName) => {
        AssetManagerState.socket.emit('setFolderCutPath', folderName);
        AssetManagerState.contextAction = 'cut_folder'
    },
    paste: (destination) => {
        AssetManagerState.socket.emit('paste', destination);
        AssetManagerState.contextAction = null;
    },
    requestPublicUrl: (filename) => {
        AssetManagerState.socket.emit('requestPublicUrl', filename);
    },
    getCurrentDirectory: () => {
        if(AssetManagerState.directoryHistory[AssetManagerState.historyIndex]){
            return AssetManagerState.directoryHistory[AssetManagerState.historyIndex];
        }else{
            return {};
        }
    },
    subscribeSocketEvents: () => {
        AssetManagerState.socket.on('serverRefresh', (data) => {
            AssetManagerState.directoryHistory[AssetManagerState.historyIndex] = data;
            console.log(data);
        });
        AssetManagerState.socket.on('publicUrl', (data) => {
            if(AssetManagerState.publicUrlCallback){
                AssetManagerState.publicUrlCallback(data.url);
            }
        });
        AssetManagerState.socket.on('baseUrl', (data) => {
            AssetManagerState.baseUrl = data.url;
        });
        AssetManagerState.socket.on('getDependencies', (dependencies) => {
            AssetManagerState.dependencies = dependencies;
            AssetManagerState.isDependencyLoading = false;
        });
    },
    getDependencies: (type = "", assetName = "") => {
        AssetManagerState.isDependencyLoading = true;
        AssetManagerState.socket.emit('getDependencies', { type, assetName });
        
    },
});

export default AssetManagerState;