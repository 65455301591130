import PrivateMetaState from "../State/PrivateMetaState";
import UIState from '../State/UIState';
import { getUserInfo } from "./auth";

export function camelToTitle(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

// TODO: Replace logic of this function to get the actual client
export function getClientInitials(client) {
    return client || 'RKD';
}

// Function will replace the space with the underscore
export function spaceWithUnderscore(str) {
    return (str !== undefined && str !== "") ? str.replace(/ /g, "_").toLowerCase() : str;
}

// List Of timezone is use for the glonal timezone settings
export const TIMEZONE_LIST = [
    {
        label: "Eastern",
        value: "America/New_York"
    }
]

// Function will pass the standerd error messgae based on the type which can be use in multipe place
export function getErrorMessage(type = "customField") {
    if (type === "customField") {
        return 'Only alpha numeric and underscore(_) field name is allowed without space!';
    }
    if (type === 'form') {
        return 'Only alpha numeric, dash(-) and underscore(_) field name is allowed without space!';
    }

    return type;
}

//Function will return the Form viewer URL
export function mapDomain(endpoint) {
    let domain;
    if (process.env.NODE_ENV === 'development' || [true, 'true'].includes(process.env.REACT_APP_STAGE) || UIState.ignoreClientDomains) {
        domain = process.env.REACT_APP_FORMRENDERER_URL;
    } else {
        domain = PrivateMetaState.data.domain;
    }

    if (domain.slice(-1) === '/') {
        domain = domain.slice(0, -1); // remove last char
    }
    if (endpoint.slice(0, 1) === '/') {
        endpoint.slice(1); // remove first char
    }

    return domain + "/" + endpoint;
}

export function getUrlParam(name) {
    // eslint-disable-next-line no-useless-escape
    let results = new RegExp('[\?&]' + name + '=([^&#]*)', 'i').exec(window.location.href);
    if (results == null) {
        return null;
    } else {
        return results[1] || 0;
    }
}

// Function will download the file with provided data and store it with provided extension.
export function downloadFile(type, fileName, ext, fileData, version = "0.0.1") {
    var today = new Date(new Date().toUTCString().slice(0, -3));
    var date = ((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate() + '-' + today.getFullYear();
    var time = (today.getHours() < 10 ? '0' : '') + today.getHours() + "-" + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes() + "-" + (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();
    var dateTime = date + '_-_' + time;
    const blob = new Blob([fileData], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = type + "_-_" + fileName + "_-_v" + version + "_-_" + dateTime + "." + ext;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// Function will update the change log of HTML, CSS and JS of forms.
export function updateVersion(type, versions = {}) {
    // eslint-disable-next-line no-redeclare
    var versions = versions;       //Get the versions
    var today = new Date(new Date().toUTCString().slice(0, -3));
    var date = ((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate() + '-' + today.getFullYear();
    var time = (today.getHours() < 10 ? '0' : '') + today.getHours() + "-" + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes() + "-" + (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();
    var dateTime = date + ' ' + time;

    const parsedUser = getUserInfo();       //Get logged-in user info
    var data = {
        id: 1,
        updatedBy: parsedUser.nickname,
        updatedAt: dateTime,
        version: "0.0.1"
    }

    if (versions !== null && versions.hasOwnProperty(type)) {
        let lastElement = versions[type].slice(-1);
        data.id = (lastElement[0].id + 1);
        data.version = getNewVerison(lastElement[0].version);
        if (versions[type].length === 5) {
            versions[type].shift();
        }
        versions[type].push(data);
    }
    else {
        if (versions === null) {
            versions = {};
        }
        versions[type] = [data];
    }

    return versions;

}

// Function will return the last element from array
export function getLastElement(elementsArray = []) {
    if (elementsArray.length > 0) {
        let lastElement = elementsArray.slice(-1);
        return lastElement[0];
    }
    else {
        return null;
    }
}

// Function will return the latest version.
export function getNewVerison(version = "0.0.0", workingOnLength = null) {
    var splitVersion = version.split(".");

    if (workingOnLength >= 0) {
        var splitVersionLength = splitVersion.length;
        // eslint-disable-next-line no-redeclare
        var workingOnLength = workingOnLength !== null ? workingOnLength : splitVersionLength - 1;
        var lastValue = parseInt(splitVersion[workingOnLength]);
        if (lastValue === 9) {
            splitVersion[workingOnLength] = 0;
            version = splitVersion.join(".");
            return getNewVerison(version, workingOnLength - 1);
        }
        else {
            splitVersion[workingOnLength] = lastValue + 1;
            version = splitVersion.join(".");
            return version;
        }
    }
    else {
        splitVersion = ["1"].concat(splitVersion);
        version = splitVersion.join(".");
        return version;
    }
}

// Function wil retun the string with capitalize the first letter.
export function capitalizeFirstLetter(string = "", splitWith = "", joinWith = "") {
    if (typeof string !== "undefined" && string !== "") {
        // split the string the by splitWith separator.
        const arr = string.split(splitWith);

        //loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

        }

        //Join all the elements of the array back into a string 
        //using a joinWith as a separator. 
        const str2 = arr.join(" ");

        return str2;
    }

    return string;
}

// List Of timezone is use for the glonal timezone settings
export const CONSTANT = {
    variableExistsColor: "#d3cece",
    variableExistsWithDiffValueColor: "#ecb857",
    variableExistsText: "Variables are already exists.",
    variableExistsWithDiffValueText: "Variables are already exists with different value."
}

export const manageVariableArray = (variables = []) => {
    if (variables.length > 0) {
        variables = variables.map(({ key, value }) => ({ key, value }));
    }
    return variables;
}

export function pendoInitialization(clientData, userData) {
    console.log("Pendo.io: Pendo initialization called.");
    
    let visitorData = {};
    let accountData = {};
    let environment = '';
    let prefix = '';

    if(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)) {
      environment = 'local';
      prefix = 'LOCAL';
    } else if(process.env.REACT_APP_STAGE === "true" || process.env.REACT_APP_STAGE === true) {
      environment = 'development';
      prefix = 'STAGE';
    } else {
      environment = 'production';
      prefix = '';
    }

    if(Object.keys(clientData).length > 0) {
      accountData.id = `${prefix ? prefix + `_` : ``}Form_Builder_${clientData.data.acronym}`;
      accountData.name = clientData.data.name;
      accountData.client = clientData.data.acronym;
      accountData.clientType = clientData.data.clientType;
      accountData.environment = environment;
    }

    if(Object.keys(userData).length > 0) {
      visitorData.id = `${prefix ? prefix + `|` : ``}${userData.sub}`;
      visitorData.name = userData.name;
      visitorData.nickName = userData.nickname;
      visitorData.email = userData.email;
      visitorData.role = userData['http://one.rkd.io/role'];
      visitorData.type = userData['http://one.rkd.io/app_metadata'].type;
      visitorData.client = clientData.data.acronym;
      visitorData.environment = environment;

    }

    window.pendo.initialize({
      visitor: visitorData,
      account: accountData
    });
    return true;
}
