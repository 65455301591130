import {store} from '@risingstack/react-easy-state';

const CopyCustomFieldState = store({
    sourceId: null,
    sourceName: null,
    modalOpen: false,
    toClient: false,
    targetClient: "",
    dynamicElementName: "",
    toggleOpen() {
        CopyCustomFieldState.modalOpen = !CopyCustomFieldState.modalOpen;
    }
});

export default CopyCustomFieldState;