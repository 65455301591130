import React from 'react';

import './App.css';

import './QueryBuilder.css';

import MainTemplate from "./Views/MainTemplate";

import BaseSocket from "./Sockets/BaseSocket";

import UIState from "./State/UIState";

import { getUrlParam } from "./lib"

BaseSocket.init();


//Check for URL Param
const checkIgnoreClientDomains = 'ignoreClientDomains';
if (getUrlParam(checkIgnoreClientDomains)) {
  window.localStorage.setItem(checkIgnoreClientDomains, 'true');
}
if (window.localStorage.getItem(checkIgnoreClientDomains)) {
  UIState.ignoreClientDomains = true;
}


function App() {
  return (
    <div>
      <MainTemplate />
    </div>
  );
}

export default App;
