import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import PageEditorState from "../../State/PageEditorState";
import ComponentEditorState from "../../State/ComponentEditorState";
import FormPageEditorState from "../../State/FormPageEditorState";
import Grid from "@material-ui/core/Grid";
import TextField from "./FieldTypes/TextField";
import Button from "@material-ui/core/Button";
import Folder from "@material-ui/icons/Folder";
import AssetManagerState from "../../State/AssetManagerState";
import GlobalSettingsState from "../../State/GlobalSettingsState";
import DeleteVariableState from "../../State/DeleteVariableState";

import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
class VariableEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentAddVariableName: "",
            isAllowAddVariable: true,
            emptyError: false
        }
    }

    getVariables = () => {
        switch (this.props.type) {
            case 'page':
                return PageEditorState.variables;
            case 'component':
                return ComponentEditorState.variables;
            case 'form':
                return FormPageEditorState.variables;
            default:
                return;
        }
    };

    addVariable = () => {
        let newVariable = [];
        switch (this.props.type) {
            case 'page':
                PageEditorState.addVariable();
                newVariable = PageEditorState.variables.filter(e => e.id === "new").length;
                break;
            case 'component':
                ComponentEditorState.addVariable();
                newVariable = ComponentEditorState.variables.filter(e => e.id === "new").length;
                break;
            case 'form':
                FormPageEditorState.addVariable();
                newVariable = FormPageEditorState.variables.filter(e => e.id === "new").length;
                break;
            default:
                break;
        }

        this.setState({ isAllowAddVariable: newVariable >= 1 ? false : true });
    };

    handleSave = async () => {

        let newVariable = [];
        switch (this.props.type) {
            case 'page':
                newVariable = PageEditorState.variables.filter(e => e.key === "").length;
                if (newVariable >= 1) {
                    this.setState({ emptyError: true })
                } else {
                    this.setState({ isAllowAddVariable: true });
                    PageEditorState.emitUpdate();

                }

                break;
            case 'component':
                newVariable = ComponentEditorState.variables.filter(e => e.key === "").length;
                if (newVariable >= 1) {
                    this.setState({ emptyError: true })
                } else {
                    this.setState({ isAllowAddVariable: true });
                    ComponentEditorState.emitUpdate();
                }
                break;
            case 'form':
                newVariable = FormPageEditorState.variables.filter(e => e.key === "").length;
                if (newVariable >= 1) {
                    this.setState({ emptyError: true })
                } else {
                    this.setState({ isAllowAddVariable: true });
                    FormPageEditorState.emitUpdate();
                }

                break;
            default:
                break;
        }

    };


    handleBlur = async (currentValue) => {
        if (currentValue !== "") {
            this.setState({ emptyError: false });
        }
        this.setState({ currentAddVariableName: currentValue });
        let type = "";
        if (this.props.type === "form") {
            type = "forms";
        } else if (this.props.type === "page") {
            type = "pages";
        } else if (this.props.type === "component") {
            type = "components";
        }
        await GlobalSettingsState.isVariableAlreadyExists(currentValue, type, this.props.currentId, true)

    }

    removeVariable = async (key, clickType, index) => {

        GlobalSettingsState.initSocket();
        let tmpType = this.props.type;
        let type = "";
        if (tmpType === "form") { type = "forms"; }
        else if (tmpType === "page") { type = "pages"; }
        else if (tmpType === "component") { type = "components"; }

        await GlobalSettingsState.checkIsVariableInUse(key, type, this.props.currentId, true);
        DeleteVariableState.isOpen = true;
        DeleteVariableState.type = clickType;
        DeleteVariableState.selectedVariable = key;
        DeleteVariableState.selectedVariableIndex = index;
        DeleteVariableState.deleteType = "individual";
        DeleteVariableState.deleteFrom = type;
    };

    openAssetManager = (currentVariable) => {
        AssetManagerState.initSocket();
        AssetManagerState.currentVariable = currentVariable;
        AssetManagerState.isOverlayOpen = true;
    };

    render() {
        const variables = this.getVariables();
        const thumbnailsExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        return (
            <>
                <Button onClick={() => this.addVariable()} disabled={!this.state.isAllowAddVariable} color='primary'>Add Variable</Button>
                {variables.map((variable, index) => {
                    return (
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={4}>
                                <TextField
                                    type='text'
                                    label='Variable Name'
                                    value={variable.key}
                                    InputProps={{ readOnly: (variable.id === "new") ? false : true }}
                                    className="varialble-input"

                                    onChange={(value) => {
                                        variable.key = value;
                                        if (variable.id === "new") {
                                            this.handleBlur(value);
                                        }
                                    }}
                                    disabled={(variable.id === "new") ? false : true}
                                    onBlur={(value) => {
                                        if (variable.id === "new") {
                                            this.handleBlur(value.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label='Variable Value'
                                    value={variable.value}
                                    defaultValue={''}
                                    onChange={(value) => variable.value = value}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "center" }}>
                                <IconButton edge="end" aria-label="View" onClick={() => this.openAssetManager(variable)}>
                                    <Folder />
                                </IconButton>
                                <IconButton edge="end" aria-label="View" onClick={() => this.removeVariable(variable.key, 'view', index)}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton edge="end" onClick={() => this.removeVariable(variable.key, 'delete', index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>

                            {(thumbnailsExtensions.includes(variable.value.substring(variable.value.lastIndexOf('.') + 1, variable.value.length))) &&
                                <Grid item xs={1}>
                                    <img src={variable.value} alt="thumbnail" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '5px', height: '50px', maxWidth: '100%' }} />
                                </Grid>
                            }
                        </Grid>
                    );
                }
                )}
                {GlobalSettingsState.isCheckExistsError && <Typography variant='subtitle1' color='error' gutterBottom><b>{this.state.currentAddVariableName}</b>  variable name is already exists, please use another name</Typography>}
                {this.state.emptyError && <Typography variant='subtitle1' color='error' gutterBottom>Please enter variable name</Typography>}


                <Button
                    onClick={() => this.handleSave()}
                    color='primary'
                    disabled={GlobalSettingsState.isCheckExistsError}
                >Save Changes</Button>
            </>
        );
    }

}

export default view(VariableEditor);