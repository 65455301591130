export default {
    dashboardAPI: () => {
        let hostname = 'https://deployapi.charitable.one';
        if(
            process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_STAGE === 'true' ||
            process.env.REACT_APP_STAGE === true
        ){
            hostname = 'https://stagingdashboard.charitable.one';
            // hostname = 'http://localhost:3020';
        }
        return hostname;
    },
    creativeAPI: () => {
        // This is new creativeAPI function which points to legacy-donation-form-api Repository

        let hostname = 'https://dstx9sykad.execute-api.us-east-1.amazonaws.com/production';
        if(
            process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_STAGE === 'true' ||
            process.env.REACT_APP_STAGE === true
        ){
            hostname = 'https://1p3h0e9sgl.execute-api.us-east-1.amazonaws.com/development';
            // hostname = 'http://localhost:3010';
        }
        return hostname;
    }
};