import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AddFieldState from "../../State/AddFieldState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import FormPageEditorState from "../../State/FormPageEditorState";
import EditFieldState from "../../State/EditFieldState";
import AddCustomFieldState from "../../State/AddCustomFieldState";
import Divider from "@material-ui/core/Divider";
import {Droppable, Draggable} from "react-beautiful-dnd";
import EditFormModalState from "../../State/EditFormModalState";
import EditModalTriggersState from "../../State/EditModalTriggersState";

class Modal extends Component{

    state = {
        random: 0,
        disableIfLIDEANAModal: false
    }

    componentDidMount() {
        FormPageEditorState.rerender = () => this.setState({
            random: Math.random()
        }, () => this.forceUpdate());
        
        const modal = FormPageEditorState.modals[this.props.index];
        const defaultLIDEANAFielName = FormPageEditorState.errorAfterNAttempsSettings.case.field_id;
        if(modal.name.includes(defaultLIDEANAFielName)){
            // console.log("%%% modal.js->componentDidMount:modal", modal);
            this.setState({disableIfLIDEANAModal: true});
        }
    }

    handleDelete = (modal, component) => {
        FormPageEditorState.deleteComponent(modal, component, 'modal');
        this.forceUpdate();
    };

    handleEdit = (modal, component) => {
        EditFieldState.open(modal, component, 'modal');
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        border: isDragging ? '1px #000 solid' : 'none',
        background: isDragging ? '#FFF' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    getListStyle = isDraggingOver => ({

    });

    render(){
        const modal = FormPageEditorState.modals[this.props.index];

        return(
            <Grid className='pageEditorCard' item>
                <Card variant='outlined'>
                    <CardContent>
                        <Typography variant='h6' color='textSecondary' gutterBottom>{this.props.name}</Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <List dense={true}>

                            <Droppable droppableId={`field_list:modal:${this.props.index}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={this.getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {modal.components.map((component, componentIndex) => (
                                            <Draggable key={componentIndex} draggableId={`${this.props.index}:${componentIndex}:modal`} index={componentIndex}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={this.getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )
                                                        }>
                                                        <ListItem style={{paddingRight:"80px"}}>
                                                            <ListItemText primary={component.customName || component.name} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(this.props.index, componentIndex)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                                <IconButton edge="end" aria-label="edit" onClick={() => this.handleEdit(this.props.index, componentIndex)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </List>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        {
                            !this.state.disableIfLIDEANAModal &&
                            <>
                                <Button color='primary' size="small" onClick={() => EditFormModalState.open(this.props.index)}>Edit Modal</Button>
                                <Button color='primary' size="small" onClick={() => EditModalTriggersState.open(this.props.index)}>Edit Triggers</Button>
                            </>
                        }
                        <Button color='primary' size="small" onClick={() => AddFieldState.open(this.props.index, 'modal')}>Add Field</Button>
                        <Button color='primary' size="small" onClick={() => AddCustomFieldState.open(this.props.index, 'modal')}>Add Custom Field</Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

}

export default view(Modal);