import React from 'react';
import {view} from '@risingstack/react-easy-state'
import {Route} from "react-router-dom";
import PageList from "./PageList";
import EditPage from "./EditPage";

export default view(function() {
    return (
        <>
            <Route path='/pages' component={PageList} />
            <Route path='/page/:id' component={EditPage} />
        </>
    );
});