import React from 'react';
import {view} from '@risingstack/react-easy-state'
import {Route} from "react-router-dom";
import GlobalSettings from "./GlobalSettings";



export default view(function() {
    return (
        <>
            <Route path='/globalSettings' component={GlobalSettings} />
        </>
    );
});