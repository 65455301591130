import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreateNewState from "../../State/CreateNewState";
import FormListState from "../../State/FormListState";
import ComponentListState from "../../State/ComponentListState";
import PageListState from "../../State/PageListState";
import CustomFieldListState from "../../State/CustomFieldListState";
import ComponentCustomTypes from "../../State/ComponentCustomTypes";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import { getErrorMessage } from "../../lib";


class NewItem extends Component {

    state = {
        isError: false,
        customError: false
    }

    formNameMaxLength = 49

    componentDidMount() {
        CustomFieldListState.initSocket();
    }

    handleClose = () => {
        CreateNewState.close();
        CreateNewState.value = '';
        this.setState({ isError: false, customError: false });
    };

    handleSubmit = async () => {

        if (CreateNewState.itemType === 'form') {
            // eslint-disable-next-line no-useless-escape
            let regex = /[^a-zA-Z0-9_\-\/]/
            if (CreateNewState.value.trim() === "") {
                this.setState({ isError: true });
            } else if (CreateNewState.value !== "" && regex.test(CreateNewState.value)) {
                this.setState({ isError: true, customError: true });
            } else {
                await FormListState.emitUpdate();
                this.setState({ isError: false, customError: false });
                this.handleClose();
            }
        } else if (CreateNewState.itemType === 'component') {
            if (CreateNewState.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                ComponentListState.emitUpdate();
                this.handleClose();
            }
        } else if (CreateNewState.itemType === 'page') {
            if (CreateNewState.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                PageListState.emitUpdate();
                this.handleClose();
            }
        } else if (CreateNewState.itemType === 'Custom Field') {
            // eslint-disable-next-line no-useless-escape
            let regex = new RegExp("^[a-zA-Z0-9_\s]+$");
            if (CreateNewState.value.trim() === "") {
                this.setState({ isError: true });
            } else if (CreateNewState.value !== "" && !regex.test(CreateNewState.value)) {
                this.setState({ isError: true, customError: true });
            } else {
                CustomFieldListState.emitUpdate();
                this.setState({ isError: false, customError: false });
                this.handleClose();
            }
        }
    };

    ucFirst = (data) => {
        data = data.toLowerCase();
        return data.charAt(0).toUpperCase() + data.slice(1);
    };

    handleChange = (event) => {
        CreateNewState.value = event.target.value;
        if (event.target.value.trim() === "") {
            this.setState({ isError: true });
        } else {
            this.setState({ isError: false });
        }
    }

    render() {
        let errorMessage = this.state.isError ? `Please Enter ${this.ucFirst(CreateNewState.itemType) + " Name"}` : "";
        let customErrorMessage = getErrorMessage(CreateNewState.itemType === 'form' ? 'form' : 'customField');
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={CreateNewState.isOpen} disableBackdropClick={true}>
                    <DialogTitle>Create New {this.ucFirst(CreateNewState.itemType)}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the {CreateNewState.itemType} a name to get started.</DialogContentText>
                        <TextField
                            autoFocus
                            margin='dense'
                            label={this.ucFirst(CreateNewState.itemType) + " Name"}
                            type='text'
                            value={CreateNewState.value}
                            onChange={(event) => this.handleChange(event)}
                            fullWidth
                            inputProps={{ maxLength: this.formNameMaxLength }}
                            id="standard-error-helper-text"
                            helperText={!this.state.customError ? errorMessage : customErrorMessage}
                            error={this.state.isError ? true : false}
                        />

                        {CreateNewState.itemType === 'Custom Field' && <>
                            <DialogContentText style={{ marginTop: 10 }}>Select a Custom Field Type</DialogContentText>
                            <Select
                                value={CustomFieldListState.newCustomFieldId}
                                id='add-field-select'
                                style={{ width: '100%' }}
                                onChange={(event) => CustomFieldListState.newCustomFieldId = event.target.value}
                            >
                                {ComponentCustomTypes.types.map((componentType, index) => (
                                    <MenuItem key={index} value={index}>{componentType.name}</MenuItem>
                                ))}
                            </Select>
                        </>}
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!FormListState.isLoading && <Button onClick={() => this.handleSubmit()} color='primary'>Create {this.ucFirst(CreateNewState.itemType)}</Button>}
                        {FormListState.isLoading && <Button onClick={() => this.handleSubmit()} disabled={true} color='primary'>Create {this.ucFirst(CreateNewState.itemType)} &nbsp; <CircularProgress size={18} /></Button>}
                    </DialogActions>
                </Dialog>
                {FormListState.isDuplicateCreative && <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={FormListState.isDuplicateCreative} autoHideDuration={10000}
                    onClose={() => FormListState.isDuplicateCreative = false}>

                    <Alert variant="filled" severity="error" onClose={() => FormListState.isDuplicateCreative = false}>
                        {FormListState.errorMessage}
                    </Alert>
                </Snackbar>
                }

            </>
        );
    }

}

export default view(NewItem);