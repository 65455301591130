import BaseSocket from "./BaseSocket";
import {getIdToken} from "../lib/auth";
import ClientState from "../State/ClientState";

const AssetManagerSocket = {
    socket: null,
    clientSetCallback: null,
    init: () => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }

        let component = AssetManagerSocket.socket;
        if(!component){
            component = BaseSocket.socket.subscribe('assetManager');
        }
        component.on('update', function(data){
            console.log(data);
        });
        AssetManagerSocket.socket = component;
        AssetManagerSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        AssetManagerSocket.socket.emit('update', data);
    },
    setClient: (client = null, callback = null) => {
        if(AssetManagerSocket.socket){
            if(!client){
                client = ClientState.currentClient;
            }
            if(typeof callback === 'function'){
                AssetManagerSocket.socket.on('clientSet', () => callback());
            }
            AssetManagerSocket.socket.emit('setClient', client);
        } else if(typeof callback === 'function'){
            callback();
        }
    }
};

export default AssetManagerSocket;