import { store } from "@risingstack/react-easy-state";
import RuleSocket from "../Sockets/RuleSocket";
import AddRuleState from "./AddRuleState";
import ClientState from "./ClientState";
import CopyRuleState from "./Rule/CopyRuleState";

const RuleState = store({
    socket: null,
    newRuleId: 0,
    data: [],    
    isLoading: false,
    errorMessage: null,
    isDynamicElementNameLoading: false,
    isDependencyLoading: false,
    isDuplicateRule: false,
    ruleDependencies: [],
    enableDeleteButton: false,
    initSocket: () => {
        if (!RuleState.socket) {
            RuleSocket.init();
            RuleState.socket = RuleSocket.socket;
        } else {
            RuleState.socket.emit('requestUpdate');
        }
        RuleState.receiveUpdate();
    },
    reinitSocket: (client, callback = null) => {
        if (RuleState.socket) {
            RuleState.socket.close();
            RuleState.socket = null;
        }
        RuleSocket.socket = null;
        RuleState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    emitUpdate: () => {
        RuleState.socket.emit('create', {
            name: AddRuleState.ruleName,
            rule_state: JSON.stringify(AddRuleState.ruleJson)
        });        
    },
    deleteRule: (sourceId) => {
        RuleState.socket.emit('delete', { sourceId });
    },
    updateRule: (ruleId) => {
        RuleState.socket.emit('update', { ruleId, ruleId, data: AddRuleState.ruleJson  });
    },
    receiveUpdate: () => {
        RuleState.socket.on('serverUpdate', (data) => {
            if (data) {
                console.log('server update data: ', data);
                RuleState.data = data;
                AddRuleState.ruleJson = {expression: "", action: { }}
            }
        });

        RuleState.socket.on('createAccept', (data) => {
            RuleState.isLoading = false;
            RuleState.modalOpen = false;
            
            RuleState.newRuleId = data.id;
        });  

        RuleState.socket.on('createDecline', (data) => {
            RuleState.isDuplicateRule = true;
            RuleState.errorMessage = `Copy Failed! The Rule name is already in use ${CopyRuleState.toClient ? `for ${CopyRuleState.targetClient} client` : ``}.`;
            RuleState.isLoading = false;
        });

        RuleState.socket.on('getDynamicElementName', (data) => {
            if(!data.isDuplicate) {
                CopyRuleState.dynamicElementName = data.dynamicElementName
            }
            RuleState.isDynamicElementNameLoading = false;
        });   
        
        RuleState.socket.on('copyAccept', (data) => {
            RuleState.isLoading = false;
            CopyRuleState.modalOpen = false;
            CopyRuleState.dynamicElementName = "";
            window.localStorage.setItem(ClientState.clientContextVariable, CopyRuleState.targetClient);
            if(CopyRuleState.targetClient !== ClientState.currentClient) {
                window.location = '/globalSettings';
            }
        })

        RuleState.socket.on('onGetRuleDependencies', (data) => {
            RuleState.isDependencyLoading = false;
            RuleState.ruleDependencies = data;
            RuleState.enableDeleteButton = data.length;
        })
    },
    copyRule: async (sourceId, targetName, sourceName, targetClient) => {
        console.log('request: ', sourceId, targetName, sourceName, targetClient);
        try {

            RuleState.isLoading = true;        
            RuleState.socket.emit('copy', {
                sourceId,
                targetName,
                targetClient
            });
        } catch (e) {
            console.error('Failed to copy rule', e);
            RuleState.isLoading = false;
            RuleState.errorMessage = e.message !== undefined ? e.message : "Copy Failed!";
        }

    },
    getDynamicElementName: (sourceName, targetClient) =>{
        RuleState.isDynamicElementNameLoading = true;
        RuleState.socket.emit('getDynamicElementName', {
            sourceName,
            targetClient
        });
    },
    getRuleDependencies: (id) => {
        RuleState.isDependencyLoading = true;
        RuleState.socket.emit('getRuleDependencies', {
            index: id
        });
    }
});

export default RuleState;