import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PageEditorState from "../../State/FormPageEditorState";
import AddCustomFieldState from "../../State/AddCustomFieldState";
import CustomFieldListState from "../../State/CustomFieldListState";
import Divider from "@material-ui/core/Divider";

class AddCustomField extends Component{

    handleClose = () => {
        AddCustomFieldState.close();
    };

    handleSubmit = () => {
        PageEditorState.addCustomComponent(AddCustomFieldState.object, AddCustomFieldState.value, AddCustomFieldState.objectType);
        this.handleClose();
    };

    render(){
        return (
            <Dialog onClose={() => this.handleClose()} open={AddCustomFieldState.isOpen}>
                <DialogTitle>Add Custom Field</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>Select the field you would like to add</DialogContentText>
                    <Select
                        value={AddCustomFieldState.value}
                        id='add-field-select'
                        style={{width: '100%'}}
                        onChange={(event) => AddCustomFieldState.value = event.target.value}
                    >
                        {CustomFieldListState.data.map((componentType, index) => (
                            <MenuItem key={index} value={index}>{componentType.name}</MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Add Field</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AddCustomField);