import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state';
import Button from "@material-ui/core/Button";
import PageEditorState from "../../State/FormPageEditorState";
import ClientState from "../../State/ClientState";
import { mapDomain } from "../../lib";

class PreviewPage extends Component {
    
    render() {
        return (
            <Button
                onClick={() => window.open(mapDomain(`form?preview=true&cl=${ClientState.currentClient}&df=${PageEditorState.formId}`))}
                color='primary'
                style={{ float: 'right' }}
            >Preview</Button>
        );
    }

}

export default view(PreviewPage);