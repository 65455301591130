import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import { capitalizeFirstLetter } from "../../lib";
import AddGlobalVariable from "../../State/AddGlobalVariableState";
import ClientState from "../../State/ClientState";
import GlobalSettingsState from "../../State/GlobalSettingsState"
import GlobalVariableState from "../../State/GlobalVariableState";
import ListOfDependencies from '../Dialogs/Delete/ListOfDependencies';

class AddGlobalVariableElement extends Component {

    state = {
        isError: false,
    }
    componentDidMount() {
        GlobalSettingsState.initSocket();
        GlobalSettingsState.checkIsVariableExists(`${ClientState.currentClient.toLowerCase()}_${AddGlobalVariable.data.name}`);
    }

    handleClose = () => {
        AddGlobalVariable.close();
        GlobalSettingsState.globalVariableDepends = [];
    };


    handleAddVariable = async () => {
        GlobalVariableState.data.push({
            variable_key: `${ClientState.currentClient.toLowerCase()}_${AddGlobalVariable.data.name}`,
            variable_value: AddGlobalVariable.data.value
        })
        GlobalVariableState.emitUpdate();
        AddGlobalVariable.close();
    };

    render() {
        
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    onClose={() => this.handleClose()} open={AddGlobalVariable.isOpen}
                >
                    <DialogTitle>Add Global Variable</DialogTitle>
                    <Divider />
                    <DialogContent>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    margin='dense'
                                    label='Variable Name'
                                    type='text'
                                    disabled
                                    value={`${ClientState.currentClient.toLowerCase()}_${AddGlobalVariable.data.name || ""}`}
                                    // onChange={(event) => variable.variable_key = event.target.value}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    margin='dense'
                                    label='Variable Value'
                                    type='text'
                                    value={AddGlobalVariable.data.value || ""}
                                    onChange={(event) => AddGlobalVariable.data.value = event.target.value}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {/* <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(index)}>
                                    <DeleteIcon />
                                </IconButton> */}
                            </Grid>
                        </Grid>
                        <React.Fragment>
                            {GlobalSettingsState.isDependencyLoading && <p>Loading...</p>}
                            {!GlobalSettingsState.isDependencyLoading && Object.entries(GlobalSettingsState.globalVariableDepends).map(([element, dependency]) => (
                                <React.Fragment>
                                    <h3>{"This variable name is already exists in the following " + capitalizeFirstLetter(element, "_", " ") + ":"}</h3>
                                    <ListOfDependencies viewType="table" dependenciesOf={element} dependencies={dependency} />
                                </React.Fragment>
                            ))
                            }
                        </React.Fragment>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        <Button
                            onClick={() => this.handleAddVariable()}
                            color='primary'
                            disabled={(GlobalSettingsState.isDependencyLoading || Object.keys(GlobalSettingsState.globalVariableDepends).length > 0 ) ?  true : false}
                        >Add Variable</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default view(AddGlobalVariableElement);