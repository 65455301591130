import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
// import { performLogout } from "../../lib/auth";

const list = [
    {
        primaryText: "Custom Fields",
        icon: "list",
        link: "/customFields"
    },
    {
        primaryText: "Forms",
        icon: "list",
        link: "/"
    },
    {
        primaryText: "Components",
        icon: "list",
        link: "/components"
    },
    {
        primaryText: "Pages",
        icon: "list",
        link: "/pages"
    },
    {
        primaryText: "Asset Manager",
        icon: "list",
        link: "/assets"
    }
];

const globalList = [
    {
        primaryText: "Global Settings",
        icon: "settings",
        link: "/globalSettings"
    }
];
class SidebarContent extends Component {

    state = {
        selected: "/"
    };

    render() {
        const { history } = this.props;
        return (
            <List>
                {list.map(({ primaryText, icon, link }, i) => (
                    <ListItem
                        key={primaryText}
                        selected={link === this.state.selected}
                        button
                        onClick={() => {
                            this.setState({
                                selected: link
                            });
                            history.push(link)
                        }}
                    >
                        <ListItemIcon>
                            <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={primaryText}
                            primaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                ))}
                <Divider style={{ margin: "12px 0" }} />
                {globalList.map(({ primaryText, icon, link }, i) => (
                    <ListItem
                        key={primaryText}
                        selected={link === this.state.selected}
                        button
                        onClick={() => {
                            this.setState({
                                selected: link
                            });
                            history.push(link)
                        }}
                    >
                        <ListItemIcon>
                            <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={primaryText}
                            primaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                ))}
                <Divider style={{ margin: "12px 0" }} />
                {/* <ListItem button onClick={() => performLogout()}>
                    <ListItemIcon>
                        <Icon>power_settings_new</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Sign Out"}
                        primaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem> */}
            </List>
        );
    }
};

export default withRouter(view(SidebarContent));
