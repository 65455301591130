import React, {Component} from 'react';
import { view } from '@risingstack/react-easy-state';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AssetPreviewState from "../../State/AssetPreviewState";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

class AssetPreview extends Component{

    render(){
        return (
            <Dialog onClose={() => AssetPreviewState.isOpen = false} open={AssetPreviewState.isOpen}>
                <DialogTitle style={{width: 600}}>Asset Preview</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        {AssetPreviewState.type === 'image' && <Grid item xs={12} style={{textAlign: 'center'}}>
                            <img src={AssetPreviewState.assetLink} style={{maxWidth: '100%'}} alt="Logo img"/>
                        </Grid>}
                        <Grid item xs={6}>
                            <strong>Name:</strong> {AssetPreviewState.name}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Size:</strong> {AssetPreviewState.size}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Mime Type:</strong> {AssetPreviewState.mimeType}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Last Modified:</strong> {AssetPreviewState.lastModified}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Link:</strong> <a href={AssetPreviewState.assetLink}  rel="noopener noreferrer" target='_blank'>{AssetPreviewState.assetLink}</a>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => AssetPreviewState.isOpen = false} color='secondary'>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AssetPreview);