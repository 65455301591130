import { store } from "@risingstack/react-easy-state";

const AddGlobalVariable = store({
    isOpen: false,
    data: {},
    open: () => {
        AddGlobalVariable.isOpen = true;
    },
    close: () => {
        AddGlobalVariable.isOpen = false;
    },
    // toggleOpen: () => {
    //     AddGlobalVariable.isOpen = !AddPageState.isOpen
    // }
});

export default AddGlobalVariable;