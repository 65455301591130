import {store} from "@risingstack/react-easy-state";

const AssetPreviewState = store({
    type: '',
    isOpen: false,
    mimeType: '',
    size: 0,
    assetLink: '',
    name: '',
    lastModified: ''
});

export default AssetPreviewState;