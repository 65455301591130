import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteState from "../../../State/Common/DeleteState";
import FormListState from "../../../State/FormListState";
import CustomFieldListState from "../../../State/CustomFieldListState";
import ComponentListState from "../../../State/ComponentListState";
import PageListState from "../../../State/PageListState";
import ListOfDependencies from './ListOfDependencies';
import AssetManagerState from '../../../State/AssetManagerState';
import { capitalizeFirstLetter } from '../../../lib/index';

class DeleteForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sourceId: null,
            assetName: null,
        }
    }
    
    async componentDidUpdate(prevProps, prevState)
    {   
        let sourceType = DeleteState.type;
        let sourceId = DeleteState.sourceId;
        let sourceName = "";
        if(DeleteState.type === "form")
        {
            sourceName = DeleteState.formName;
        } else if (DeleteState.type === "custom_field") {
            
        } else if (DeleteState.type === "component") {
            
        } else if (DeleteState.type === "page") {
            
        }

        if(['form', 'component', 'page'].includes(DeleteState.type))
        {
            if(this.state.sourceId !== DeleteState.sourceId)
            {
                if(!AssetManagerState.isDependencyLoading){
                    await PageListState.getDependencies(sourceType, sourceId, sourceName);
                    this.setState({ sourceId : DeleteState.sourceId });
                }
                
            }
        }
        else if(['assetManager', 'assetManagerFolder'].includes(DeleteState.type))
        {
            if(this.state.assetName !== DeleteState.assetName)
            {
                this.setState({ assetName : DeleteState.assetName }, async () => {
                    if(!AssetManagerState.isDependencyLoading){
                        await AssetManagerState.getDependencies(DeleteState.type === 'assetManager' ? "file" : "folder", DeleteState.assetName);
                    }
                    
                });
            }
        } 
    }

    async handleSubmit() {
        if (DeleteState.type === "form") {
            await FormListState.deleteForm(DeleteState.sourceId, DeleteState.formName);
            this.props.getInitialData();
            this.handleClose();
        } else if (DeleteState.type === "custom_field") {
            await CustomFieldListState.deleteField(DeleteState.sourceId);
            this.props.getInitialData();
            this.handleClose();
        } else if (DeleteState.type === "component") {
            await ComponentListState.deleteComponent(DeleteState.sourceId);
            this.props.getInitialData();
            this.handleClose();
        } else if (DeleteState.type === "page") {
            await PageListState.deletePage(DeleteState.sourceId);
            this.props.getInitialData();
            this.handleClose();
        }
        else if(DeleteState.type === "assetManager") {
            await AssetManagerState.deleteFile(DeleteState.assetName);
            this.handleClose();
        }
        else if(DeleteState.type === "assetManagerFolder") {
            await AssetManagerState.deleteFolder(DeleteState.assetName);
            this.handleClose();
        }

    }

    handleClose() {
        DeleteState.modalOpen = false;
        DeleteState.type = null;
        this.setState({ assetName: null });
        AssetManagerState.dependencies = [];
    }

    render() {
        let deleteName = "";
        let elementName = DeleteState.formName;
        let dependencyLength = PageListState.dependencies.length;
        let isDependencyLoading = PageListState.isDependencyLoading;
        if (DeleteState.type === "form") {
            deleteName = "Form";
        } else if (DeleteState.type === "custom_field") {
            deleteName = "Field";
        } else if (DeleteState.type === "component") {
            deleteName = "Component";
        } else if (DeleteState.type === "page") {
            deleteName = "Page";
        } else if (DeleteState.type === "assetManager") {
            deleteName = "File";
            elementName = DeleteState.assetName;
            dependencyLength = Object.keys(AssetManagerState.dependencies).length;
            isDependencyLoading = AssetManagerState.isDependencyLoading;
        } else if (DeleteState.type === "assetManagerFolder") {
            deleteName = "Folder";
            elementName = DeleteState.assetName;
            dependencyLength = Object.keys(AssetManagerState.dependencies).length > 0 && (AssetManagerState.dependencies.Contents.length > 1 || AssetManagerState.dependencies.CommonPrefixes.length > 0) ? 1 : 0;
            isDependencyLoading = AssetManagerState.isDependencyLoading;
        }

        let checkDependencyType = ["form", "component", "assetManager", "assetManagerFolder"];

        return (
            <>

                <Dialog onClose={() => this.handleClose()} open={DeleteState.modalOpen}>
                    <DialogTitle id="alert-dialog-title">
                        {DeleteState.isDeleteModal ? `Delete ` : `Dependent of `}
                        <span className="highlight-delete-title">{elementName} </span>
                        {deleteName} {DeleteState.isDeleteModal ? `?` : ``}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        { DeleteState.isDeleteModal &&
                            <React.Fragment>
                                <DialogContentText id="alert-dialog-description">
                                    {DeleteState.type === "form" && `Are you sure you want to delete this form? Deleting this form will remove all published content for this form.`}
                                    {DeleteState.type === "custom_field" && `Are you sure you want to delete this custom field?`}
                                    {DeleteState.type === "component" && `Are you sure you want to delete this component? Deleting this component will remove all published content related to the component.`}
                                    {DeleteState.type === "page" && `Are you sure you want to delete this page? Deleting this page will remove all published content related to the page.`}
                                    {DeleteState.type === "assetManager" && `Are you sure you want to delete this file? Deleting this file will remove all published content related to the file.`}
                                    {DeleteState.type === "assetManagerFolder" && `Are you sure you want to delete this folder? Deleting this folder will remove all published content related to the folder.`}
                                </DialogContentText>
                            </React.Fragment>
                        }

                        { isDependencyLoading && checkDependencyType.includes(DeleteState.type) &&
                            <h3>Loading...</h3>
                        }

                        { !isDependencyLoading && checkDependencyType.includes(DeleteState.type) && !['assetManager', 'assetManagerFolder'].includes(DeleteState.type) && dependencyLength > 0 &&
                            <React.Fragment>
                                <h3>{"This " + DeleteState.type + " is a dependency for the following pages:"}</h3>
                                <ListOfDependencies viewType="table" dependenciesOf="Page" dependencies={PageListState.dependencies} />
                            </React.Fragment>
                        }

                        { !isDependencyLoading && checkDependencyType.includes(DeleteState.type) && !['assetManager', 'assetManagerFolder'].includes(DeleteState.type) && dependencyLength === 0 &&
                            <React.Fragment>
                                <h3>{"This " + DeleteState.type + " is not yet used into any pages."}</h3>
                            </React.Fragment>
                        }

                        { !isDependencyLoading && checkDependencyType.includes(DeleteState.type) && ['assetManager', 'assetManagerFolder'].includes(DeleteState.type) && dependencyLength > 0 &&
                            <React.Fragment>
                                { ['assetManager'].includes(DeleteState.type) && Object.entries(AssetManagerState.dependencies).map(([element, dependency]) => (
                                    <React.Fragment>
                                        <h3>{"This file is a dependency for the following " + capitalizeFirstLetter(element, "_", " ") + ":"}</h3>
                                        <ListOfDependencies viewType="table" dependenciesOf={element} dependencies={dependency} />
                                    </React.Fragment>
                                )) 
                                }

                                { ['assetManagerFolder'].includes(DeleteState.type) && 
                                    <React.Fragment>
                                        <h3>{"This folder contains the below Folders / Files:"}</h3>
                                        <ListOfDependencies viewType="tree" dependenciesOf={elementName} dependencies={AssetManagerState.dependencies} />
                                    </React.Fragment>
                                
                                }
                            </React.Fragment>
                        }

                        { !isDependencyLoading && checkDependencyType.includes(DeleteState.type) && ['assetManager'].includes(DeleteState.type) && dependencyLength === 0 &&
                            <React.Fragment>
                                <h3>{"This file is not yet used into any forms, components and pages."}</h3>
                            </React.Fragment>
                        }

                        { !isDependencyLoading && checkDependencyType.includes(DeleteState.type) && ['assetManagerFolder'].includes(DeleteState.type) && dependencyLength === 0 &&
                            <React.Fragment>
                                <h3>{"This folder is empty."}</h3>
                            </React.Fragment>
                        }

                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='primary'>Cancel</Button>
                        {(DeleteState.isDeleteModal && !DeleteState.isLoading) &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                disabled={( (checkDependencyType.includes(DeleteState.type) && dependencyLength > 0) || (isDependencyLoading) ? true : false )}
                                color='secondary'>
                                {`Delete ${deleteName}`}
                        </Button>
                        }
                        {(DeleteState.isDeleteModal && DeleteState.isLoading) &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='secondary'
                                disabled={true}>
                                {`Delete ${deleteName}`}  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>


                {
                    FormListState.errorMessage !== null && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true} autoHideDuration={10000}
                        onClose={() => FormListState.errorMessage = null}>

                        <Alert variant="filled" severity="error" onClose={() => FormListState.errorMessage = null}>
                            {FormListState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>

        );
    }

}

export default view(DeleteForm);

