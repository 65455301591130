import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {view} from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import NotificationState from "../../State/NotificationState";
import ClientState from "../../State/ClientState";
import PageEditorState from "../../State/PageEditorState";
import { mapDomain } from "../../lib";


class NotificationDialog extends Component{

    handleClose = () => {
        NotificationState.close();
    };

    render(){
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={NotificationState.isOpen}>
                    <DialogTitle>{NotificationState.title}</DialogTitle>
                    <DialogContent>
                        {NotificationState.content}
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='primary'>OK</Button>
                        {NotificationState.title === 'Page Published' && (
                            <Button onClick={() => window.open(mapDomain(`page?cl=${ClientState.currentClient}&page=${PageEditorState.componentId}`))} color='primary'>View Live</Button>
                        )}
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default withRouter(view(NotificationDialog));