import { store } from '@risingstack/react-easy-state';
import { getUserInfo } from "../lib/auth";
import hostname from "../lib/hostname";
import axios from 'axios'

import ComponentListSocket from "../Sockets/ComponentListSocket";
import ComponentSocket from "../Sockets/ComponentSocket";
import CustomFieldListSocket from "../Sockets/CustomFieldListSocket";
import CustomFieldSocket from "../Sockets/CustomFieldSocket";
import FormListSocket from "../Sockets/FormListSocket";
import FormSocket from "../Sockets/FormSocket";
import GlobalVariableSocket from "../Sockets/GlobalVariableSocket";
import PageListSocket from "../Sockets/PageListSocket";
import PageSocket from "../Sockets/PageSocket";
import GlobalSettingsSocket from "../Sockets/GlobalSettingsSocket";
import ComponentListState from "./ComponentListState";
import CustomFieldListState from "./CustomFieldListState";
import FormListState from "./FormListState";
import GlobalVariableState from "./GlobalVariableState";
import PageListState from "./PageListState";
import GlobalSettingsState from "./GlobalSettingsState";
import AssetManagerState from "./AssetManagerState";
import PrivateMetaState from './PrivateMetaState';
import RuleState from './RuleState';

const ClientState = store({
    currentClient: null,
    clientList: [],
    multiClient: false,
    hasInit: false,
    eventQueue: [],
    clientContextVariable: 'clientContext',
    defaultClient: 'RKD',
    clientType: "super",
    init: async () => {
        if (!ClientState.hasInit) {
            ClientState.hasInit = true;
            const userInfo = getUserInfo();
            if(Object.keys(userInfo['http://one.rkd.io/app_metadata']).length > 0) {
                ClientState.clientType = userInfo['http://one.rkd.io/app_metadata'].type;
            }
            if (userInfo['http://one.rkd.io/role'] === 'admin') {
                try {
                    const clientList = await axios.get(`${hostname.dashboardAPI()}/clients/getClients`);
                    ClientState.clientList = clientList.data.message.sort();
                    ClientState.multiClient = true;

                    const localStorageClient = window.localStorage.getItem(ClientState.clientContextVariable);
                    if(localStorageClient &&  ClientState.clientList.includes(localStorageClient)){
                        ClientState.changeClient(localStorageClient);
                    } else {
                        window.localStorage.setItem(ClientState.clientContextVariable, ClientState.defaultClient);
                        ClientState.changeClient(ClientState.defaultClient);
                    }
                } catch (e) {
                    console.error(e);
                    console.log('unable to get client list');
                }
            } else {
                ClientState.changeClient(userInfo['http://one.rkd.io/role']);
                ClientState.clientList = [userInfo['http://one.rkd.io/role']];
            }
        }
    },
    changeClient: (client) => {
        if(!ClientState.currentClient){
            ClientState.currentClient = client;
            ClientState.eventQueue = [
                //AssetManagerSocket.setClient,
                ComponentListSocket.setClient,
                ComponentSocket.setClient,
                CustomFieldListSocket.setClient,
                CustomFieldSocket.setClient,
                FormListSocket.setClient,
                FormSocket.setClient,
                GlobalVariableSocket.setClient,
                PageListSocket.setClient,
                PageSocket.setClient,
                GlobalSettingsSocket.setClient,
                PrivateMetaState.reinit,
                AssetManagerState.reinitSocket,
                ComponentListState.reinitSocket,
                CustomFieldListState.reinitSocket,
                FormListState.reinitSocket,
                GlobalVariableState.reinitSocket,
                PageListState.reinitSocket,
                GlobalSettingsState.reinitSocket,
                RuleState.reinitSocket
            ];
            ClientState.processNextQueuedEvent();
        } else {
            window.localStorage.setItem(ClientState.clientContextVariable, client);
            window.location = '/';
        }
    },
    processNextQueuedEvent() {
        if (ClientState.eventQueue.length > 0) {
            const nextEvent = ClientState.eventQueue.pop();
            nextEvent(ClientState.currentClient, () => ClientState.processNextQueuedEvent());
        }
    }
});

export default ClientState;