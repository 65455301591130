import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import UIState from "../../State/UIState";

import {
    Header,
    CollapseBtn,
    CollapseIcon,
    SidebarTrigger,
    SidebarTriggerIcon
} from "@mui-treasury/layout";
import CreateNewState from "../../State/CreateNewState";
import GlobalVariableState from "../../State/GlobalVariableState";
import AssetManagerState from "../../State/AssetManagerState";
import UploadFileModalState from "../../State/UploadFileModalState";
import UploadFileModal from "../../Components/Dialogs/UploadFileModal";
import GlobalSettingsState from "../../State/GlobalSettingsState";

class AppBar extends Component {
    render() {
        const { collapseClass, sidebarTriggerClass } = this.props;
        return (
            <>
                <UploadFileModal />
                <Header>
                    <MaterialAppBar position='static'>
                        <Toolbar>
                            <CollapseBtn className={collapseClass}>
                                <CollapseIcon style={{ color: '#fff' }} />
                            </CollapseBtn>
                            <SidebarTrigger className={sidebarTriggerClass}>
                                <SidebarTriggerIcon />
                            </SidebarTrigger>
                            <Typography variant='h6' style={{ flexGrow: 1 }}>{UIState.pageTitle}</Typography>
                            {/* <Button color='inherit'><Icon className='fa fa-list' style={{margin: 2}} />List Forms</Button> */}
                            <Button color='inherit' onClick={() => { GlobalSettingsState.isCheckExistsErrorGlobal = false; GlobalVariableState.open() }}><Icon style={{ width: '100%', margin: 2 }} className='fa fa-code' /></Button>
                            <Button color='inherit' onClick={() => AssetManagerState.isOpenUploadAssetsManageModal ? UploadFileModalState.toggleOpen() : CreateNewState.open()}><Icon className='fa fa-plus-circle' style={{ margin: 2 }} /></Button>
                        </Toolbar>
                    </MaterialAppBar>
                </Header>
            </>
        );
    }
}

export default view(AppBar);