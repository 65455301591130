import { store } from '@risingstack/react-easy-state';
import TextBox from '../ComponentCustomTypes/TextBox';
import Select from '../ComponentCustomTypes/Select';
import Checkbox from '../ComponentCustomTypes/Checkbox.json';
import Radio from '../ComponentCustomTypes/Radio.json';
import TextArea from '../ComponentCustomTypes/TextArea.json';
import DateField from '../ComponentCustomTypes/Date.json';
import CheckboxInput from '../ComponentCustomTypes/CheckboxAndTextBox.json';

const ComponentCustomTypes = store({
    types: [
        TextBox,
        Select,
        Checkbox,
        Radio,
        TextArea,
        DateField,
        CheckboxInput    ]
});

export default ComponentCustomTypes;