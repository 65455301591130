import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import _ from 'lodash'

import PageEditorState from "../../State/FormPageEditorState";
import EditFormPageState from "../../State/EditFormPageState";
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";

class AddPage extends Component {

    state = {
        isError: false
    }

    handleClose = () => {
        EditFormPageState.close();
        this.setState({ isError: false });
    };

    handleOpen = () => {
        EditFormPageState.open();
    };

    handleSubmit = () => {
        if (EditFormPageState.value.trim() === "") {
            this.setState({ isError: true });
        } else {
            PageEditorState.pages[EditFormPageState.page].name = EditFormPageState.value;
            PageEditorState.emitUpdate();
            this.handleClose();
        }

    };

    handleDelete = () => {
        _.pullAt(PageEditorState.pages, [EditFormPageState.page]);
        PageEditorState.emitUpdate();
        this.handleClose();
    };

    render() {
        return (
            <Dialog onClose={() => this.handleClose()} open={EditFormPageState.isOpen}>
                <DialogTitle>Edit Element</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText style={{ marginBottom: "0px" }}>Selected Element Type</DialogContentText>
                    {EditFormPageState.pageType !== "" && <DialogContentText style={{ marginBottom: "0px" }}>{EditFormPageState.pageType}</DialogContentText>}

                    <TextField
                        autoFocus
                        margin='dense'
                        label='Element Name'
                        type='text'
                        fullWidth
                        value={EditFormPageState.value}
                        onChange={(event) => {
                            EditFormPageState.value = event.target.value;
                            this.setState({ isError: event.target.value.trim() === "" ? true : false });

                        }}
                        helperText={this.state.isError ? "Please enter element name" : ""}
                        error={this.state.isError ? true : false}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleDelete()} color='secondary'>Delete Element</Button>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withRouter(view(AddPage));