import React, { Component } from 'react';
import { view } from "@risingstack/react-easy-state";
import Grid from '@material-ui/core/Grid';
import _ from 'lodash'
import TextField from "./TextField";
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import NumberField from "./NumberField";
import { camelToTitle } from "../../../lib";

class ArrayField extends Component {

    getFormControl = (field, index) => {
        const { option, savedOption } = this.props;

        if (savedOption.values.length === 0) {
            savedOption.values = option.values;
        }

        let fieldProps = {
            label : camelToTitle(field.field),
            value : savedOption.values[index][field.field],
            defaultValue : '',
            onChange : (value) => savedOption.values[index][field.field] = value
        };

        if (field.helperText) {
            fieldProps.helperText = field.helperText; 
        }
        
        return (
            <>
                {field.type === 'number' && <NumberField {...fieldProps} />}
                {field.type === 'text' && <TextField {...fieldProps} />}
            </>
        );
    };

    getRowFields = (row, rowIndex) => {
        const { option } = this.props;
        const fields = _.cloneDeep(option.fields);

        const formattedRow = fields.map((field) => {
            return {
                value: row[field.field],
                ...field
            };
        });

        const cols = 12 / formattedRow.length;

        return (
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Grid container spacing={2}>
                        {formattedRow.map((field, index) => (
                            <Grid item xs={cols > 3 ? cols : 3} key={index}>{this.getFormControl(field, rowIndex)}</Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => this.addRowAtIndex(rowIndex)}>
                        <AddBoxIcon />
                    </Button>
                    {rowIndex !== 0 && (
                        <Button onClick={() => this.removeRow(rowIndex)}>
                            <IndeterminateCheckBoxIcon />
                        </Button>
                    )}
                </Grid>
            </Grid>
        );
    };

    getGeneratedNewRow = () => {
        const newValue = {};
        this.props.option.fields.forEach((field) => {
            newValue[field.field] = "";
        });
        return newValue;
    }

    addRow = () => {
        const newFirstElement = this.getGeneratedNewRow();
        this.props.savedOption.values = [newFirstElement].concat(this.props.savedOption.values);
    };

    addRowAtIndex = (index) => {
        const newArray = this.props.savedOption.values.slice(0, index + 1);
        newArray.push(this.getGeneratedNewRow());
        this.props.savedOption.values.slice(index + 1).forEach((item) => {
            newArray.push(item);
        });
        this.props.savedOption.values = newArray;
    };

    removeRow = (index) => {
        if (this.props.savedOption.values.length < 2) {
            this.props.savedOption.values = [];
        } else {
            const newArray = this.props.savedOption.values.slice(0, index);
            this.props.savedOption.values.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            this.props.savedOption.values = newArray;
        }
    };

    render() {
        const { savedOption } = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button onClick={() => this.addRow()}>
                            <AddBoxIcon />
                        </Button>
                    </Grid>
                </Grid>
                {savedOption.values.map((value, index) => this.getRowFields(value, index))}
            </>
        );
    }

}

export default view(ArrayField);