import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import { DataGrid } from '@mui/x-data-grid';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Redirect, withRouter } from "react-router-dom";
import FormListState from "../../../State/FormListState";
import CreateNewState from "../../../State/CreateNewState";
import UIState from "../../../State/UIState";
import ClientState from "../../../State/ClientState";
import CopyFormState from "../../../State/Form/CopyFormState";
import CopyForm from "../../../Components/Dialogs/Copy/CopyForm";
import DeleteForm from "../../../Components/Dialogs/Delete/DeleteForm";
import DeleteState from "../../../State/Common/DeleteState";
import { mapDomain } from "../../../lib";
import EmbedModalState from "../../../State/EmbedModalState";

function CustomNoRowsOverlay() {

    return (
        <div className='overlayComponent'>No forms found.</div>
    );
}

class FormList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { field: 'id', headerName: 'Form Id', width: 150 },
                {
                    field: 'name',
                    headerName: 'Form Name',
                    width: 420,
                    renderCell: (params) => {
                        return (
                            <React.Fragment>
                                {params.row.name}&nbsp;
                                {params.row.is_draft ? <span style={{ color: 'red' }}>(Draft)</span> : null}
                            </React.Fragment>
                        )
                    }
                },
                { field: 'created_at', headerName: 'Created At', width: 200, type: 'date' },
                { field: 'updated_at', headerName: 'Updated At', width: 200, type: 'date' },
                {
                    field: 'Actions',
                    description: 'You can perform varios actions on the forms.',
                    sortable: false,
                    filterable: false,
                    width: 150,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        const triggerCopyForm = (sourceId, sourceName) => {
                            CopyFormState.sourceId = sourceId;
                            CopyFormState.sourceName = sourceName;
                            CopyFormState.modalOpen = true;
                        };

                        const triggerDeleteForm = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = true;
                        }

                        const triggerViewDependencies = (sourceId, formName, type) => {
                            DeleteState.sourceId = sourceId;
                            DeleteState.formName = formName;
                            DeleteState.type = type;
                            DeleteState.modalOpen = true;
                            DeleteState.isDeleteModal = false;
                        }

                        const triggerCopyFormToClient = (sourceId, sourceName) => {
                            CopyFormState.sourceId = sourceId;
                            CopyFormState.sourceName = sourceName;
                            CopyFormState.modalOpen = true;
                            CopyFormState.toClient = true;
                        };

                        return (
                            <React.Fragment>
                                <Button onClick={() => this.props.history.push(`/form/${params.row.id}`)}><Icon className='fa fa-edit list-icon' /></Button>
                                <PopupState variant="popover" popupId={`action-menu-${params.row.id}`}>
                                    {(popupState) => (
                                        <React.Fragment>
                                            <Button {...bindTrigger(popupState)}>
                                                <Icon className='fa fa-chevron-circle-down list-icon' />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem onClick={() => window.open(mapDomain(`form?cl=${ClientState.currentClient}&df=${params.row.id}`))}>View Live</MenuItem>
                                                <MenuItem onClick={() => window.open(mapDomain(`form?cl=${ClientState.currentClient}&df=${params.row.id}&preview=true`))}>View Preview</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => {
                                                    EmbedModalState.showModal('Form',
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/form' data-cl='${ClientState.currentClient}' data-df='${params.row.id}' data-preview='true' ></script>`,
                                                        `<script id='rkd-form-embed' src='${process.env.REACT_APP_EMBED_SCRIPT}' type='text/javascript' data-hash='/form' data-cl='${ClientState.currentClient}' data-df='${params.row.id}' ></script>`, '', mapDomain(`form?cl=${ClientState.currentClient}&df=${params.row.id}`));
                                                }}>Embed</MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => { popupState.close(); triggerCopyForm(params.row.id, params.row.name); }}>Copy Form</MenuItem>
                                                { ClientState.clientType === "super" &&
                                                    <MenuItem onClick={() => { popupState.close(); triggerCopyFormToClient(params.row.id, params.row.name); }}>Copy Form To Another Client</MenuItem>
                                                }
                                                <MenuItem onClick={() => { popupState.close(); triggerDeleteForm(params.row.id, params.row.name, "form"); }}>Delete Form</MenuItem>
                                                <MenuItem onClick={() => { popupState.close(); triggerViewDependencies(params.row.id, params.row.name, "form"); }}>View Dependent</MenuItem>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </React.Fragment>
                        );
                    }
                },

            ],
            rows: [],
            sortModel: [
                {
                    field: 'id',
                    sort: 'desc',
                },
            ]
        };
    }

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rows.length !== FormListState.data.length) {
            this.getInitialData();
        }
    }

    getInitialData = () => {
        FormListState.initSocket();
        CreateNewState.itemType = 'form';
        UIState.setPageTitle('Forms');

        setTimeout(() => {
            this.setState({ rows: FormListState.data });
        }, 1000);
    }

    setSortModel = (model) => {
        this.setState({ sortModel: model });
    }

    render() {
        const redirectToPage = FormListState.newFormId;
        const hasRedirect = !!(redirectToPage);
        FormListState.newFormId = 0;

        return (
            <React.Fragment>
                {hasRedirect && <Redirect to={`/form/${redirectToPage}`} />}
                {!hasRedirect &&
                    <Paper>
                        <div style={{ height: 'calc(100vh - 65px)', width: '100%' }}>
                            <DataGrid
                                rows={this.state.rows}
                                columns={this.state.columns}
                                hideFooterSelectedRowCount={true}
                                disableSelectionOnClick={true}
                                hideFooterPagination={true}
                                sortModel={this.state.sortModel}
                                onSortModelChange={(model) => this.setSortModel(model)}
                                components={{
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    NoResultsOverlay: CustomNoRowsOverlay
                                }}
                            />
                        </div>
                    </Paper>
                }
                <CopyForm />
                <DeleteForm getInitialData={this.getInitialData} />
            </React.Fragment>
        );
    }
}

export default withRouter(view(FormList));