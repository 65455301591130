import React from 'react';
import {view} from '@risingstack/react-easy-state'
import AssetManager from "./AssetManager";
import {Route} from "react-router-dom";

export default view(function() {
    return (
        <>
            <Route path='/assets' component={AssetManager} />
        </>
    );
});