import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AddFieldState from "../../State/AddFieldState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditFormPageState from "../../State/EditFormPageState";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import FormPageEditorState from "../../State/FormPageEditorState";
import EditFieldState from "../../State/EditFieldState";
import AddCustomFieldState from "../../State/AddCustomFieldState";
import Divider from "@material-ui/core/Divider";
import {Droppable, Draggable} from "react-beautiful-dnd";
import DeleteFormElement from "../../Components/Dialogs/Delete/DeleteFormElement";
import DeleteStateElement from "../../State/Common/DeleteStateElement";
import EditPageTriggersState from "../../State/EditPageTriggersState";

class Page extends Component{

    state = {
        random: 0
    }

    componentDidMount() {
        FormPageEditorState.rerender = () => this.setState({
            random: Math.random()
        }, () => this.forceUpdate());
    }

    handleDelete = (page, component, name) => {
        DeleteStateElement.page = page;
        DeleteStateElement.component = component;
        DeleteStateElement.type = 'page';
        DeleteStateElement.elementName = name;
        DeleteStateElement.modalOpen = true;
        DeleteStateElement.isDeleteModal = true;
    };

    handleEdit = (page, component) => {
        EditFieldState.open(page, component, 'page');
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        border: isDragging ? '1px #000 solid' : 'none',
        background: isDragging ? '#FFF' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    getListStyle = isDraggingOver => ({

    });

    render(){
        const page = FormPageEditorState.pages[this.props.index];
        let skippedTrigger = this.props.index === 0 ? true : (FormPageEditorState.pages.length -1 === this.props.index ? true : false);

        return(
            <>
                <Grid className='pageEditorCard' item>
                    <Card variant='outlined'>
                        <CardContent>
                            <Typography variant='h6' color='textSecondary' gutterBottom>{this.props.name}</Typography>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <List dense={true}>

                                    <Droppable droppableId={`field_list:page:${this.props.index}`}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                style={this.getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {page.components.map((component, componentIndex) => (
                                                    <Draggable key={componentIndex} draggableId={`${this.props.index}:${componentIndex}:page`} index={componentIndex}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={this.getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )
                                                            }>
                                                                <ListItem style={{paddingRight:"80px", backgroundColor: (component.id === "thankYouPageCustomHTML" ?  "#f9a306" : "")}}>
                                                                    <ListItemText primary={component.customName || component.name}/>
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(this.props.index, componentIndex, (component.customName || component.name))}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                        <IconButton edge="end" aria-label="edit" onClick={() => this.handleEdit(this.props.index, componentIndex)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        )}
                                    </Droppable>
                            </List>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button color='primary' size="small" onClick={() => EditFormPageState.open(this.props.index)}>Edit Element</Button>
                            { !skippedTrigger &&
                                <Button color='primary' size="small" onClick={() => EditPageTriggersState.open(this.props.index)}>Edit Triggers</Button>
                            }
                            <Button color='primary' size="small" onClick={() => AddFieldState.open(this.props.index, 'page')}>Add Field</Button>
                            <Button color='primary' size="small" onClick={() => AddCustomFieldState.open(this.props.index, 'page')}>Add Custom Field</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <DeleteFormElement />
            </>
        );
    }

}

export default view(Page);