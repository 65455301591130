import React, { Component } from "react";
import { view } from '@risingstack/react-easy-state';
import GlobalVariableState from "../../State/GlobalVariableState";
import GlobalSettingsState from "../../State/GlobalSettingsState";
import DeleteVariableState from "../../State/DeleteVariableState";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from '@material-ui/core/Grid';
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import DeleteVariable from "./DeleteVariable";
import Typography from "@material-ui/core/Typography";

class GlobalVariable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentAddVariableName: "",
            isAllowAddVariable: true,
            emptyError: false
        }
    }


    componentDidMount() {
        GlobalVariableState.initSocket();
        GlobalSettingsState.initSocket();
        this.setState({ emptyError: false });
        GlobalSettingsState.isCheckExistsErrorGlobal = false;
    }

    handleSubmit() {

        let newVariable = GlobalVariableState.data.filter(e => e.variable_key === "").length;

        if (newVariable >= 1) {
            this.setState({ emptyError: true })
        } else {
            this.setState({ isAllowAddVariable: true });
            GlobalVariableState.emitUpdate();
            this.handleClose();
        }

    }

    handleBlur = async (currentValue) => {
        this.setState({ emptyError: currentValue !== "" ? false : true });
        this.setState({ currentAddVariableName: currentValue });
        if (currentValue !== "") {
            await GlobalSettingsState.checkIsVariableExists(currentValue);
        }
    }


    handleClose() {
        GlobalSettingsState.globalVariableDepends = [];
        this.setState({ isAllowAddVariable: true });
        this.setState({ emptyError: false });
        GlobalVariableState.close();
    }

    addVariable() {
        this.setState({ isAllowAddVariable: false });
        GlobalVariableState.data.push({
            variable_key: '',
            variable_value: ''
        });
    }

    handleDelete = async (index, type, variable = {},) => {
        this.setState({ emptyError: false });
        await GlobalSettingsState.checkIsVariableInUse(variable.variable_key, 'global_variables');
        DeleteVariableState.isOpen = true;
        DeleteVariableState.type = type;
        DeleteVariableState.selectedVariable = variable.variable_key;
        DeleteVariableState.selectedVariableIndex = index;
        DeleteVariableState.deleteType = "global";
        DeleteVariableState.deleteFrom = "global";

    }

    render() {
        
        return (
            <>
                <Dialog
                    onClose={() => this.handleClose()}
                    open={GlobalVariableState.isOpen}
                    maxWidth={"md"}
                    fullWidth={true}
                >
                    <DialogTitle>Global Variables</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Button onClick={() => this.addVariable()} color='primary' disabled={!this.state.isAllowAddVariable}>Add Variable</Button>
                        {GlobalVariableState.data.map((variable, index) => (
                            <Grid container key={index}>
                                <Grid item xs={4}>
                                    <TextField
                                        autoFocus
                                        margin='dense'
                                        label='Variable Name'
                                        type='text'
                                        onChange={(event) => {
                                            variable.variable_key = event.target.value
                                            if (variable.id === undefined) {
                                                this.handleBlur(event.target.value);
                                            }
                                        }}
                                        value={variable.variable_key}
                                        fullWidth
                                        disabled={variable.variable_key !== "" && variable.id !== undefined}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin='dense'
                                        label='Variable Value'
                                        type='text'
                                        value={variable.variable_value}
                                        onChange={(event) => variable.variable_value = event.target.value}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "center" }}>
                                    <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(index, 'view', variable)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(index, 'delete', variable)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        {GlobalSettingsState.isCheckExistsErrorGlobal && <Typography variant='subtitle1' color='error' gutterBottom><b>{this.state.currentAddVariableName}</b>  variable name is already exists, please use another name</Typography>}
                        {this.state.emptyError && <Typography variant='subtitle1' color='error' gutterBottom>Please enter variable name</Typography>}
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        <Button onClick={() => this.handleSubmit()} color='primary' disabled={GlobalSettingsState.isCheckExistsErrorGlobal} >Save</Button>
                    </DialogActions>
                </Dialog>

                {DeleteVariableState.isOpen && <DeleteVariable />}
            </>
        );
    }
}

export default view(GlobalVariable);