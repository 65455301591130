import React, {Component} from 'react';
import { view } from '@risingstack/react-easy-state';
import {camelToTitle} from "../../../lib";
import AceEditor from "react-ace";

class TextField extends Component{


    onChange = (value) => {
        const {onChange} = this.props;
        onChange(value);
    };

    render(){
        const { value, defaultValue, label } = this.props;

        return (
            <div>
                {camelToTitle(label)}
                <AceEditor
                    placeholder="Insert Custom HTML Here"
                    style={{width: '100%'}}
                    mode="html"
                    theme="tomorrow"
                    name="htmlEditor"
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={value || defaultValue}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}/>
            </div>
        );
    }

}

export default view(TextField);