import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import AddPageState from "../../State/AddPageState";
import PageEditorState from "../../State/FormPageEditorState";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


class AddPage extends Component {

    state = {
        isError: false
    }

    handleClose = () => {
        AddPageState.close();
        this.setState({ isError: false });
    };

    handleOpen = () => {
        AddPageState.open();
    };

    handleSubmit = () => {
        if (AddPageState.value.trim() === "") {
            this.setState({ isError: true });
        } else {
            PageEditorState.addPage(AddPageState.value, AddPageState.pageType);
            AddPageState.value = "";
            this.handleClose();

        }


    };

    render() {
        
        let isSectionLength = PageEditorState.pages.filter((p) => (typeof p.pageType !== "undefined" && p.pageType === "section")).length;
        let isStepLength = PageEditorState.pages.filter((p) => (typeof p.pageType !== "undefined" && p.pageType === "step")).length;
        if(PageEditorState.pages.length) {
            AddPageState.pageType = isStepLength ? 'step' : 'section';
        }

        return (
            <>
                <Button onClick={() => this.handleOpen()} color='primary'>Add Element</Button>
                <Dialog onClose={() => this.handleClose()} open={AddPageState.isOpen} maxWidth={"lg"}>
                    <DialogTitle>Add Element</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ marginBottom: "0px" }}>Select Element Type</DialogContentText>
                        <Select
                            value={AddPageState.pageType === 'step' || isStepLength > 0 ? 'step' : 'section'}
                            id='add-field-select'
                            style={{ width: '100%' }}
                            onChange={(event) => AddPageState.pageType = event.target.value}
                        >
                            <MenuItem key={1} value={'section'} disabled={isStepLength > 0 ? true : false}>Section</MenuItem>
                            <MenuItem key={2} value={'step'} disabled={isSectionLength > 0 ? true : false}>Step</MenuItem>
                        </Select>
                        <br />
                        <br />
                        <DialogContentText style={{ marginBottom: "0px" }}>Let's give your element name.</DialogContentText>
                        <TextField
                            autoFocus
                            margin='dense'
                            label='Element Name'
                            type='text'
                            fullWidth
                            value={AddPageState.value}
                            onChange={(event) => AddPageState.value = event.target.value}
                            helperText={this.state.isError ? "Please enter element name" : ""}
                            error={this.state.isError ? true : false}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        <Button onClick={() => this.handleSubmit()} color='primary'>Add Element</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default withRouter(view(AddPage));