import BaseSocket from "./BaseSocket";
import { getIdToken } from "../lib/auth";
import ClientState from "../State/ClientState";

const FormSocket = {
    socket: null,
    init: (formId) => {

        if (!BaseSocket.socket) {
            BaseSocket.init();
        }
        // const form = BaseSocket.socket.subscribe('form:'+formId);
        const form = BaseSocket.socket.subscribe('form:' + formId + "|" + ClientState.currentClient);
        form.on('update', function (data) {
            console.log(data);
        });
        FormSocket.socket = form;
        FormSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        FormSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if (FormSocket.socket) {
            if (!client) {
                client = ClientState.currentClient;
            }
            if (typeof callback === 'function') {
                FormSocket.socket.on('clientSet', () => callback());
            }
            FormSocket.socket.emit('setClient', client);
        } else if (typeof callback === 'function') {
            callback();
        }
    }
};

export default FormSocket;