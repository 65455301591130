import React, { Component } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { view } from '@risingstack/react-easy-state';
import { Grid } from "@material-ui/core";
import NumberField from "./NumberField";
import EditFieldState from "../../../State/EditFieldState";


const possibleValidators = [{
    field: "required",
    label: "Field is required"
}, {
    field: "phone",
    label: "Field is a Phone Number"
}, {
    field: "email",
    label: "Field is an Email Address",
}, {
    field: "postal",
    label: "Field is a postal/zip code"
}, {
    field: "minLength",
    label: "Field has a minimum length",
    options: [{
        field: "minChars",
        label: "Minimum number of characters",
        type: "int",
        defaultValue: 1,
    }]
}, {
    field: "maxLength",
    label: "Field has a maximum length",
    options: [{
        field: "maxChars",
        label: "Maximum number of characters",
        type: "int",
        defaultValue: 60,
    }]
}, {
    field: "alphaOnly",
    label: "Field can only contain alpha characters",
}, {
    field: "numericOnly",
    label: "Field can only contain numeric characters",
}, {
    field: "allowAccent",
    label: "Field can contain accent characters",
}, {
    field: "allowHyphenApostrophe",
    label: "Field can contain hyphen and apostrophe characters",
}];

const regExTypes = ["phone", "email", "postal", "alphaOnly", "numericOnly"];

class ValidatorField extends Component {

    handleFieldChange(field, value, event, validation_disabled = false, regEx = false) {
        if(typeof value === 'number' && Number(value) < 0) {
            EditFieldState.isError = true;            
        } else {
            EditFieldState.isError = false;
        }
        if (validation_disabled) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const values = this.props.value ? { ...this.props.value } : {}
            regExTypes.forEach(type => {
                if (values.hasOwnProperty(type) && regEx) {
                    values[type] = false;
                }
            });

            values[field] = value;
            this.props.onChange(values);
        }
    }

    getValue(field) {
        console.log("this.props.value", this.props.value);
        if (this.props.value) {
            if (typeof this.props.value[field] === "undefined") {
                if (typeof this.props.option.defaultValues[field] === "undefined") {
                    return false;
                } else {
                    this.handleFieldChange(field, this.props.option.defaultValues[field]);
                    return this.props.option.defaultValues[field]
                }
            } else {
                return this.props.value[field];
            }
        } else {
            if (typeof this.props.option.defaultValues[field] === "undefined") {
                return false;
            } else {
                this.handleFieldChange(field, this.props.option.defaultValues[field]);
                return this.props.option.defaultValues[field]
            }
        }
    }

    renderField(validator, key) {
        console.log(this.props);


        return (
            <Grid container key={key}>
                <Grid xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={validator.field}
                                checked={this.getValue(validator.field)}
                                onChange={(event) => this.handleFieldChange(validator.field, event.target.checked, event, this.props.option.disabledFields.includes(validator.field) ? true : false, regExTypes.includes(validator.field) ? true : false)}
                                color="primary"
                                className={this.props.option.disabledFields.includes(validator.field) ? "validation-disabled" : ""}
                            />
                        }
                        label={validator.label}
                        className={this.props.option.disabledFields.includes(validator.field) ? "validation-disabled" : ""}
                    />
                    {validator.options && this.getValue(validator.field) === true && validator.options.map((subField, subIndex) => (
                        <div key={subIndex}>
                            {subField.type === "int" && <NumberField
                                value={this.getValue(subField.field || subField.defaultValue)}
                                defaultValue={subField.defaultValue}
                                onChange={(value) => { this.handleFieldChange(subField.field, value) }}
                                label={subField.label}
                                error={EditFieldState.isError}
                                helperText={EditFieldState.isError ? subField.label + " must be greater than 0" : ""}
                            />}
                        </div>
                    ))}
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <>
                {possibleValidators.map((validator, index) => this.renderField(validator, index))}
            </>
        );
    }

}

export default view(ValidatorField);