import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function RecursiveTreeView(data) {

  const classes = useStyles();

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} icon={Array.isArray(nodes.children) ? <Icon className="fa fa-folder-open" /> : <Icon className="fa fa-file" /> }>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={data.dependencies.ids}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data.dependencies.treeView)}
    </TreeView>
  );
}