/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import PrivateMetaState from "../../../State/PrivateMetaState";
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../../Components/Template/TabPanel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { TIMEZONE_LIST, CONSTANT } from "../../../lib";
import GlobalSettingsState from "../../../State/GlobalSettingsState";
import FormListState from "../../../State/FormListState";
import TextField from "@material-ui/core/TextField";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';
//Table properties import

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddGlobalVariable from '../../../Components/Dialogs/AddGlobalVarialble';
import AddGlobalVariableState from '../../../State/AddGlobalVariableState';
import GlobalVariableState from '../../../State/GlobalVariableState';
import ClientState from "../../../State/ClientState";
import { getUserInfo } from "../../../lib/auth";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RuleList from "../../../Components/Rules/RuleList";

const columns = [
    { id: 'key', label: 'Name', minWidth: 170 },
    { id: 'value', label: 'Value', minWidth: 570 },
    { id: 'action', label: 'Add as Global Variable', minWidth: 170 },

];

let generalErrorDefault = "Please see the areas highlighted. We greatly appreciate your donation and apologize for this inconvenience!";
let amountErrorDefault = "Please enter a valid donation amount.";
let paymentErrorDefault = "Please check your <b>payment information</b> and try again.";

const useStyles = theme => ({
    root: {
        width: '100%',
    },
    noMargin: {
        margin: "0px",
    }

});

class GlobalSettings extends Component {

    state = {
        tab: 0,
        generalErrorDefault: "Please see the areas highlighted. We greatly appreciate your donation and apologize for this inconvenience!",
        amountErrorDefault: "Please enter a valid donation amount.",
        paymentErrorDefault: "Please check your <b>payment information</b> and try again.",
        canSave: false,
        preferences: null,
        isSuperAdmin: true,
        dialogOpen: false,
        changeRequest: {}
    };

    componentDidMount() {
        GlobalSettingsState.initSocket();
        FormListState.initSocket();
        this.setState({ canSave: false });
        
        const userInfo = getUserInfo();
        if(userInfo['http://one.rkd.io/app_metadata']['type'] !== 'super' ){
            this.setState({ isSuperAdmin: false });
        }
    }

    componentWillUpdate(nextProps) {
        console.log("nextProps.PrivateMetaState", nextProps.PrivateMetaState);
        if (this.state.preferences !== nextProps.PrivateMetaState.data.preferences) {
            // this.setState({ preferences: nextProps.PrivateMetaState.data.preferences })
            console.log("nextProps.PrivateMetaState.data.preferences", nextProps.PrivateMetaState.data.preferences);
        }
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    handleTabChange = (event, tab) => {
        this.setState({
            tab
        })
    };

    handleChange = (key, value, editId) => {
        if (editId === "") {
            GlobalSettingsState.data.push({
                config: key,
                value: value
            });
        } else {
            let objIndex = GlobalSettingsState.data.findIndex((obj) => obj.id === editId);
            GlobalSettingsState.data[objIndex].config = key;
            GlobalSettingsState.data[objIndex].value = value;
        }
        this.handleSave();
    };

    handleSave = () => {
        if (GlobalSettingsState.data.length === 0) {
            GlobalSettingsState.data.push({
                config: "timezone",
                value: "America/New_York"
            });
        }
    };

    renderTimezoneList = () => {
        let TimezoneList = TIMEZONE_LIST.map((tz, index) => {
            return (<option key={index} value={tz.value}>{`${tz.label} (${tz.value})`}</option>);
        });
        return TimezoneList;
    }

    setPaymentProvider(type, index, mode) {

        if(mode === 'live' && this.state.isSuperAdmin) {
            this.setState({
                dialogOpen: true,
                changeRequest: {
                    type: type,
                    index: index,
                    mode: mode
                }
            })
        } else if(mode !== 'live'){
            this.savePaymentProvider(type, index, mode);
        }  
    }
    
    savePaymentProvider(type, index, mode) {
        let configObject = {};
        
        if (index === 'none') {
            configObject = {
                type: 'none'
            };
        } else {
            const providerConfig = PrivateMetaState.data['payment-settings'][mode]['payment-processors'][index];
            
            configObject = {
                type: providerConfig.type,
                name: providerConfig.name
            };
    
            if (providerConfig.type === 'stripe') {
                configObject.publicKey = providerConfig.strpePublic;
            } else if (providerConfig.type === 'braintree') {
                configObject.publicKey = providerConfig.braintreePublic;
            } else if (providerConfig.type === 'auth') {
                configObject.apiLoginID = providerConfig.apiLoginID;
                configObject.clientKey = providerConfig.clientKey;
            }
        }
    
        GlobalSettingsState.setPaymentProvider(type, configObject, mode);
        this.handleSave();

    }
    
    findPaymentProvider(type, mode) {
        let provider = 'none';

        try {
            const configedProvider = GlobalSettingsState.paymentProviders[mode][type];

            if (GlobalSettingsState.paymentProviders[mode][type] && GlobalSettingsState.paymentProviders[mode][type].type !== 'none') {
                let i = 0;
                while (i < PrivateMetaState.data['payment-settings'][mode]['payment-processors'].length) {

                    const currentProvider = PrivateMetaState.data['payment-settings'][mode]['payment-processors'][i];

                    if (configedProvider.type === currentProvider.type) {

                        if (currentProvider.type === 'stripe' && currentProvider.strpePublic === configedProvider.publicKey && currentProvider.name === configedProvider.name) {
                            provider = i;

                        } else if (currentProvider.type === 'braintree' && currentProvider.braintreePublic === configedProvider.publicKey && currentProvider.name === configedProvider.name) {
                            provider = i;
                        } else if (currentProvider.type === 'ach' && currentProvider.name === configedProvider.name) {
                            provider = i;
                        } else if (currentProvider.type === 'auth' && currentProvider.name === configedProvider.name) {
                            provider = i;
                        }
                    }
                    i++;
                }
            }

        } catch (error) {

        }
        return provider;
    }

    getGeneralError = (data, type) => {
        let str = "";
        if (type === "general") {

            if (typeof data.general !== "undefined" && data.general !== "") {
                str = data.general;
            } else {
                str = generalErrorDefault;
            }
        } else if (type === "amount") {
            if (typeof data.amount !== "undefined" && data.amount !== "") {
                str = data.amount;
            } else {
                str = amountErrorDefault;
            }
        } else if (type === "payment") {
            if (typeof data.payment !== "undefined" && data.payment !== "") {
                str = data.payment;
            } else {
                str = paymentErrorDefault;
            }
        }

        return str;
    }

    handleErrorMessage = (value, type, id, data) => {

        let obj = {};
        this.setState({ canSave: true });

        generalErrorDefault = typeof data.general !== "undefined" && data.general !== "" ? data.general : generalErrorDefault;
        amountErrorDefault = typeof data.amount !== "undefined" && data.amount !== "" ? data.amount : amountErrorDefault;
        paymentErrorDefault = typeof data.payment !== "undefined" && data.payment !== "" ? data.payment : paymentErrorDefault;

        obj = this.getDefaultErrorMessages();
        if (type === "general") {
            generalErrorDefault = value;
            obj['general'] = value;

        } else if (type === "amount") {
            amountErrorDefault = value;
            obj['amount'] = value;

        } else if (type === "payment") {
            paymentErrorDefault = value;
            obj['payment'] = value;
        }

        if (id === "") {
            GlobalSettingsState.data.push({
                config: 'errorMessage',
                value: JSON.stringify(obj)
            });
        } else {
            let objIndex = GlobalSettingsState.data.findIndex((obj) => obj.id === id);
            GlobalSettingsState.data[objIndex].config = "errorMessage";
            GlobalSettingsState.data[objIndex].value = JSON.stringify(obj);
        }

    };


    saveChanges = () => {

        if (this.state.canSave) {
            let tmpObj = this.getDefaultErrorMessages();
            let objIndex = GlobalSettingsState.data.findIndex((obj) => obj.config === "errorMessage");

            if (objIndex !== -1) {
                GlobalSettingsState.data[objIndex].config = "errorMessage";
                GlobalSettingsState.data[objIndex].value = JSON.stringify(tmpObj);
            } else {
                GlobalSettingsState.data.push({
                    config: 'errorMessage',
                    value: JSON.stringify(tmpObj)
                });
            }
            GlobalSettingsState.saveChanges();
        }

    };

    getDefaultErrorMessages = () => {
        return {
            general: generalErrorDefault,
            amount: amountErrorDefault,
            payment: paymentErrorDefault
        };
    }

    checkIsValidHexa = (color) => /^#[0-9A-Fa-f]{6}$/i.test(color) ? true : false;

    renderTableData = () => {

        let defaultData = [
            { key: "preferences.logo", name: "logo", title: "Logo" },
            { key: "domain", name: "domain", title: "Domain" },
            { key: "url", name: "url", title: "Website URL" },
            { key: "preferences.mainColor", name: "mainColor", title: "Main Color" },
            { key: "preferences.secondaryColor", name: "secondaryColor", title: "Secondary Color" },
            { key: "preferences.frequencyColor", name: "frequencyColor", title: "Freqneucy Color" },
            { key: "analytics.googleGTM", name: "googleGTM", title: "Google GTM" },
            { key: "analytics.googleID", name: "googleID", title: "Google ID" },
            { key: "organization.policies", name: "policies", title: "Policy Link" }
        ];

        if (Object.keys(PrivateMetaState.data).length > 0) {
            defaultData.map((item, index) => {
                if (item.key.includes('.')) {
                    let afterSplit = item.key.split('.');
                    if (afterSplit.length === 2) {
                        let val = PrivateMetaState.data[afterSplit[0]][afterSplit[1]];
                        defaultData[index]['isColor'] = this.checkIsValidHexa(val);
                        defaultData[index]['value'] = val;
                    } else if (afterSplit.length === 3) {
                        let val = PrivateMetaState.data[afterSplit[0]][afterSplit[1]][afterSplit[2]];
                        defaultData[index]['isColor'] = this.checkIsValidHexa(val);
                        defaultData[index]['value'] = val;
                    }
                } else {
                    defaultData[index]['value'] = PrivateMetaState.data[item.key];
                }
                return 1;
            });
        }

        let defaultDataHtml = defaultData.map((item, index) => {

            let variableKeyName = `${ClientState.currentClient.toLowerCase()}_${item.name}`;
            let exists = GlobalVariableState.data.filter(el => el.variable_key === variableKeyName);
            let bgcolor = "";
            if (exists.length > 0) {
                bgcolor = CONSTANT.variableExistsColor;
                if (exists[0].variable_value !== item.value) {
                    bgcolor = CONSTANT.variableExistsWithDiffValueColor;
                }
            }
            return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ background: bgcolor }}>
                    <TableCell key={item.title} style={{ padding: "5px 15px" }} >
                        <p style={{ marginBottom: "0px" }}>{item.title}</p>
                    </TableCell>
                    <TableCell key={`${index}_value}`} style={{ padding: "5px 15px" }} >
                        <p style={{ color: item.isColor ? item.value : '' }}>{item.value}</p>
                    </TableCell>
                    <TableCell key={`${index}_action}`} style={{ padding: "5px 15px" }} >
                        {!exists.length > 0 ? (<Button title="Add as global variable">
                            <AddBoxIcon onClick={(e) => this.onClickAddIcon(item)} />
                        </Button>) : <Typography style={{ fontWeight: 600 }} variant='small' color='textSecondary' title={`Global Variable Name`}>{variableKeyName}</Typography>}



                    </TableCell>
                </TableRow>
            )
        });

        return defaultDataHtml;

    }

    onClickAddIcon = async (item) => {
        await GlobalSettingsState.checkIsVariableExists(item.name);
        AddGlobalVariableState.data = item;
        AddGlobalVariableState.isOpen = true;
    }

    render() {
        // const { match: { params } } = this.props;
        const { classes } = this.props;
        let currentTimezone = "America/New_York";
        let editId = "";
        let errorMessageData = [];
        let errorValue = {};
        let isDataLoaded = true;
        let notSetAnything = false;
        if (GlobalSettingsState.data !== undefined && GlobalSettingsState.data.length > 0) {
            let cTimeZone = GlobalSettingsState.data.filter((tz) => { return tz.config === 'timezone'; });
            currentTimezone = (cTimeZone !== undefined && cTimeZone.length > 0) ? cTimeZone[0].value : currentTimezone;
            editId = (cTimeZone !== undefined && cTimeZone.length > 0) ? cTimeZone[0].id : editId;

            errorMessageData = GlobalSettingsState.data.filter((tz) => { return tz.config === 'errorMessage'; });
            errorValue = errorMessageData.length > 0 ? JSON.parse(errorMessageData[0]['value']) : "";
            isDataLoaded = false;

        } else {
            notSetAnything = true;
        }


        return (
            <Paper style={{ padding: 15, margin: 20 }}>
                <Tabs value={this.state.tab} onChange={this.handleTabChange}>
                    <Tab label='Settings' {...this.a11yProps(0)} />
                    <Tab label='Identity' {...this.a11yProps(1)} />
                </Tabs>
                <TabPanel value={this.state.tab} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>

                            {/* PAYMENT PROVIDERS PREVIEW MODE*/}
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Payment Providers - Preview Mode</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <p>Select the payment provider for the following Payment Types</p>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Credit Cards</InputLabel>
                                        <Select native value={this.findPaymentProvider('creditCard', 'preview')} onChange={(event) => this.setPaymentProvider('creditCard', event.target.value, 'preview')} inputProps={{ id: 'credit-card-provider' }}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['preview']['payment-processors'].map((processor, index) => {
                                                if (['stripe', 'braintree', 'auth'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Apple Pay</InputLabel>
                                        <Select native value={this.findPaymentProvider('applePay', 'preview')} onChange={(event) => this.setPaymentProvider('applePay', event.target.value, 'preview')} inputProps={{ id: 'credit-card-provider' }}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['preview']['payment-processors'].map((processor, index) => {
                                                if (['stripe', 'braintree'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">PayPal</InputLabel>
                                        <Select native value={this.findPaymentProvider('paypal', 'preview')} onChange={(event) => this.setPaymentProvider('paypal', event.target.value, 'preview')} inputProps={{ id: 'credit-card-provider' }}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['preview']['payment-processors'].map((processor, index) => {
                                                if (['braintree'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Venmo</InputLabel>
                                        <Select native value={this.findPaymentProvider('venmo', 'preview')} onChange={(event) => this.setPaymentProvider('venmo', event.target.value, 'preview')} inputProps={{ id: 'credit-card-provider' }}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['preview']['payment-processors'].map((processor, index) => {
                                                if (['braintree'].includes(processor.type) && processor.supportedPaymentMethod.includes('venmo')) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            {/* PAYMENT PROVIDERS LIVE MODE*/}
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Payment Providers - Live Mode</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <p>Select the payment provider for the following Payment Types</p>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Credit Cards</InputLabel>
                                        <Select native value={this.findPaymentProvider('creditCard', 'live')} onChange={(event) => this.setPaymentProvider('creditCard', event.target.value, 'live')} inputProps={{ id: 'credit-card-provider' }} disabled={!this.state.isSuperAdmin}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['live']['payment-processors'].map((processor, index) => {
                                                if (['stripe', 'braintree', 'auth'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Apple Pay</InputLabel>
                                        <Select native value={this.findPaymentProvider('applePay', 'live')} onChange={(event) => this.setPaymentProvider('applePay', event.target.value, 'live')} inputProps={{ id: 'credit-card-provider' }} disabled={!this.state.isSuperAdmin}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['live']['payment-processors'].map((processor, index) => {
                                                if (['stripe', 'braintree'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">PayPal</InputLabel>
                                        <Select native value={this.findPaymentProvider('paypal', 'live')} onChange={(event) => this.setPaymentProvider('paypal', event.target.value, 'live')} inputProps={{ id: 'credit-card-provider' }} disabled={!this.state.isSuperAdmin}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['live']['payment-processors'].map((processor, index) => {
                                                if (['braintree'].includes(processor.type)) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel htmlFor="credit-card-provider">Venmo</InputLabel>
                                        <Select native value={this.findPaymentProvider('venmo', 'live')} onChange={(event) => this.setPaymentProvider('venmo', event.target.value, 'live')} inputProps={{ id: 'credit-card-provider' }} disabled={!this.state.isSuperAdmin}>
                                            <option aria-label="None" value="none">None (Disabled)</option>
                                            {typeof PrivateMetaState.data['payment-settings'] !== 'undefined' && PrivateMetaState.data['payment-settings']['live']['payment-processors'].map((processor, index) => {
                                                if (['braintree'].includes(processor.type) && processor.supportedPaymentMethod.includes('venmo')) {
                                                    return (<option key={index} value={index}>{processor.name} ({processor.type})</option>);
                                                }
                                            })}
                                        </Select>
                                    </FormControl> <br /><br />
                                    <Dialog open={this.state.dialogOpen}>
                                        <DialogTitle>Update Payment Settings</DialogTitle>
                                        <DialogContent dividers>
                                            Are you sure you want to change payment provider settings?
                                            <br/>
                                            It will affect the live donation forms.
                                        </DialogContent>
                                        <DialogActions>
                                            <Button autoFocus color='primary' onClick={() => this.setState({dialogOpen: false})}>No</Button>
                                            <Button onClick={() => {this.savePaymentProvider(this.state.changeRequest.type, this.state.changeRequest.index, this.state.changeRequest.mode); this.setState({dialogOpen: false})}} color='secondary'>Yes</Button>
                                        </DialogActions>
                                    </Dialog>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Timezone</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <InputLabel htmlFor="credit-card-provider">Donations are recorded in Timezone</InputLabel>
                                    <Select native value={currentTimezone} onChange={(event) => this.handleChange('timezone', event.target.value, editId)} style={{ width: "100%" }}>
                                        {this.renderTimezoneList()}
                                    </Select>
                                </CardContent>
                                <Divider />
                                <Button onClick={() => this.handleSave()} color='primary' style={{ float: 'right' }}>Save Changes</Button>
                            </Card>

                        </Grid>

                        <Grid item xs={12} ms={12} lg={12}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="info-indicator-global-setting" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3 style={{ marginRight: '15px' }}>Client Configurations</h3>
                                        <div>
                                            <div>
                                                <span className='info-icon' style={{ background: CONSTANT.variableExistsWithDiffValueColor }}></span>
                                                <span className="info-text">
                                                    {CONSTANT.variableExistsWithDiffValueText}
                                                </span>
                                            </div>
                                            <div>
                                                <span className='info-icon' style={{ background: CONSTANT.variableExistsColor }}></span>
                                                <span className="info-text">
                                                    {CONSTANT.variableExistsText}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper className={classes.root}>
                                        <TableContainer className={classes.container}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth, padding: "5px 15px" }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.renderTableData()}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded={false}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header" >
                                    <div className="info-indicator-global-setting" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3 style={{ marginRight: '15px' }}>Error Messages</h3>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper className={classes.root}>
                                        {!isDataLoaded && <CardContent>
                                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>{"You can write the html tags(ex. <b>bold</b>, <i>italic</i> <span>span</span>, <u>underline</u>, etc.) in between the error message"}</Typography>
                                            <FormControl style={{ width: '100%' }}>
                                                <TextField
                                                    margin='dense'
                                                    type='text'
                                                    fullWidth
                                                    label='General Error'
                                                    onChange={(event) => this.handleErrorMessage(event.target.value, 'general', errorMessageData.length > 0 ? errorMessageData[0].id : "", errorValue)}
                                                    defaultValue={this.getGeneralError(errorValue, 'general')}
                                                />
                                            </FormControl>
                                            <FormControl style={{ width: '100%' }}>
                                                <TextField
                                                    margin='dense'
                                                    type='text'
                                                    fullWidth
                                                    label='Amount Error'
                                                    onChange={(event) => this.handleErrorMessage(event.target.value, 'amount', errorMessageData.length > 0 ? errorMessageData[0].id : "", errorValue)}
                                                    defaultValue={this.getGeneralError(errorValue, 'amount')}
                                                />
                                            </FormControl>
                                            <FormControl style={{ width: '100%' }}>
                                                <TextField
                                                    margin='dense'
                                                    type='text'
                                                    fullWidth
                                                    label='Payment Error'
                                                    onChange={(event) => this.handleErrorMessage(event.target.value, 'payment', errorMessageData.length > 0 ? errorMessageData[0].id : "", errorValue)}
                                                    defaultValue={this.getGeneralError(errorValue, 'payment')}
                                                />
                                            </FormControl>
                                        </CardContent>}
                                        {notSetAnything && <div style={{ padding: "10px" }}>Please select any payment processor</div>}
                                        <Divider />
                                        <Button onClick={() => this.saveChanges()} color='primary' style={{ float: 'left' }}>Save Changes</Button>
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                    </Grid>
                </TabPanel>

               {/* DIS Settings panel */}

                <TabPanel  value={this.state.tab} index={1}>
                    <RuleList />
                </TabPanel>
                {
                    AddGlobalVariableState.isOpen &&
                    <AddGlobalVariable value={this.state.tab} index={0} />
                }
            </Paper >
        );
    }
}

export default withStyles(useStyles)(view(GlobalSettings));
