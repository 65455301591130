import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

class AppLoadingTemplate extends Component{

    render(){
        return (
            <Dialog open={true}>
                <DialogTitle>Loading, Please Wait!</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 30}}>
                        <CircularProgress />
                    </div>
                    <DialogContentText>
                        Please wait while form builder loads. This could take a moment.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

}

export default view(AppLoadingTemplate);