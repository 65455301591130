import {store} from "@risingstack/react-easy-state";

const AddRuleState = store({
    isOpen: false,
    ruleName: '',
    ruleJson: {expression: "", action: { valueType: "percent", donationValue: "", vary: "increase", multiplier: "10" }},
    open: () => {
        AddRuleState.isOpen = true;
    },
    close: () => {
        AddRuleState.isOpen = false;
    },
    toggleOpen: () => {
        AddRuleState.isOpen = !AddRuleState.isOpen
    }
});

export default AddRuleState;