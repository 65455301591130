import { store } from '@risingstack/react-easy-state';
import CustomFieldListSocket from "../Sockets/CustomFieldListSocket";
import CreateNewState from "./CreateNewState";
import ComponentCustomTypes from "./ComponentCustomTypes";
import CopyCustomFieldState from "../State/CustomField/CopyCustomFieldState";
import ClientState from "../State/ClientState";

const CustomFieldListState = store({
    socket: null,
    data: [],
    newCustomFieldId: 0,
    isLoading: false,
    isDuplicateCustomField: false,
    errorMessage: null,
    isDynamicElementNameLoading: false,
    initSocket: () => {
        if (!CustomFieldListState.socket) {
            CustomFieldListSocket.init();
            CustomFieldListState.socket = CustomFieldListSocket.socket;
        }
        CustomFieldListState.receiveUpdate();
    },
    reinitSocket: (client, callback = null) => {
        if (CustomFieldListState.socket) {
            CustomFieldListState.socket.close();
            CustomFieldListState.socket = null;
        }
        CustomFieldListSocket.socket = null;
        CustomFieldListState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    emitUpdate: () => {

        let currentData = ComponentCustomTypes.types[CustomFieldListState.newCustomFieldId].options;
        // eslint-disable-next-line array-callback-return
        currentData.map((opt) => {
            if (opt.field === 'name') {
                return opt.value = CreateNewState.value;
            }
        });
        ComponentCustomTypes.types[CustomFieldListState.newCustomFieldId].options = currentData;
        CustomFieldListState.socket.emit('create', {
            name: CreateNewState.value,
            data: ComponentCustomTypes.types[CustomFieldListState.newCustomFieldId]
        });
    },
    copyCustomField: (sourceId, targetName, targetClient) => {
        CustomFieldListState.isLoading = true;
        CustomFieldListState.socket.emit('copy', {
            sourceId,
            targetName,
            targetClient
        });
    },
    deleteField: (sourceId) => {
        CustomFieldListState.socket.emit('delete', { sourceId });
    },
    receiveUpdate: () => {
        CustomFieldListState.socket.on('serverUpdate', (data) => {
            CustomFieldListState.data = data;
        });
        CustomFieldListState.socket.on('createAccept', (data, targetClient = "") => {
            CustomFieldListState.isLoading = false;
            CopyCustomFieldState.modalOpen = false;
            CopyCustomFieldState.dynamicElementName = "";
            if(CopyCustomFieldState.toClient) {
                window.localStorage.setItem(ClientState.clientContextVariable, CopyCustomFieldState.targetClient);
                window.location = '/customFields';
            } else {
                CustomFieldListState.newFormId = data.id;
            }
        });
        CustomFieldListState.socket.on('createDecline', (data, targetClient = "") => {
            CustomFieldListState.isDuplicateCustomField = true;
            CustomFieldListState.errorMessage = `Copy Failed! The custom fields name is already in use ${CopyCustomFieldState.toClient ? `for ${CopyCustomFieldState.targetClient} client` : ``}.`;
            CustomFieldListState.isLoading = false;
        });
        CustomFieldListState.socket.on('getDynamicElementName', (data) => {
            if(!data.isDuplicate) {
                CopyCustomFieldState.dynamicElementName = data.dynamicElementName
            }
            CustomFieldListState.isDynamicElementNameLoading = false;
        });
    },
    requestUpdate: () => {
        CustomFieldListState.socket.emit('requestUpdate');
    },
    getDynamicElementName: (sourceName, targetClient) =>{
        CustomFieldListState.isDynamicElementNameLoading = true;
        CustomFieldListState.socket.emit('getDynamicElementName', {
            sourceName,
            targetClient
        });
    }
});

export default CustomFieldListState;