import {store} from '@risingstack/react-easy-state';
import PageEditorState from "./FormPageEditorState";

const EditModalTriggersState = store({
    isOpen: false,
    triggers: [],
    modal: 0,
    open: (modal) => {
        EditModalTriggersState.modal = modal;
        EditModalTriggersState.triggers = PageEditorState.modals[modal] ? PageEditorState.modals[modal].triggers : [];
        EditModalTriggersState.isOpen = true;
        console.log('isOpen', EditModalTriggersState.isOpen);
    },
    close: () => {
        EditModalTriggersState.isOpen = false;
    },
    toggleOpen: () => {
        EditModalTriggersState.isOpen = !EditModalTriggersState.isOpen
    }
});

export default EditModalTriggersState;