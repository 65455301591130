import BaseSocket from "./BaseSocket";
import {getIdToken} from "../lib/auth";
import ClientState from "../State/ClientState";

const GlobalVariableSocket = {
    socket: null,
    init: () => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }

        const channel = ClientState.currentClient || Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

        const globalVariables = BaseSocket.socket.subscribe(`globalVariables:${channel}`);
        globalVariables.on('update', function(data){
            console.log(data);
        });
        GlobalVariableSocket.socket = globalVariables;
        GlobalVariableSocket.socket.emit('auth', {
            token: getIdToken(),
            client: ClientState.currentClient
        });
    },
    emitUpdate: (data) => {
        GlobalVariableSocket.socket.emit('update', data);
    },
    setClient: (client, callback = null) => {
        if(GlobalVariableSocket.socket){
            if(!client){
                client = ClientState.currentClient;
            }
            if(typeof callback === 'function'){
                GlobalVariableSocket.socket.on('clientSet', () => callback());
            }
            GlobalVariableSocket.socket.emit('setClient', client);
        } else if(typeof callback === 'function'){
            callback();
        }
    }
};

export default GlobalVariableSocket;