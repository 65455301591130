import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import PageEditorState from "../../../State/PageEditorState";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../../Components/Template/TabPanel";
import CSSEditor from "../../../Components/Editors/CSSEditor";
import Button from "@material-ui/core/Button";
import JSEditor from "../../../Components/Editors/JSEditor";
import CreateNewState from "../../../State/CreateNewState";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ComponentListState from "../../../State/ComponentListState";
import FormListState from "../../../State/FormListState";
import VariableEditor from "../../../Components/Editors/VariableEditor";
import UIState from "../../../State/UIState";
import PublishPage from "../../../Components/Buttons/PublishPage";
import ClientState from "../../../State/ClientState";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import AbandonPageChanges from "../../../Components/Buttons/AbandonPageChanges";
import { mapDomain, downloadFile, updateVersion, getLastElement } from "../../../lib";

class EditPage extends Component {

    state = {
        tab: 0,
        tabs: ['pageConfig', 'pageMeta', 'scss', 'js', 'variables'],
        checkedCSSDownload: true,
        checkedJSDownload: true,
        latestVersion: null
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        const { tab, tabs } = this.state;
        PageEditorState.setPage(params.id);
        PageEditorState.config.googleAnalytics = true;
        // set default sidebar location to right
        PageEditorState.config.sidebarLocation = (typeof PageEditorState.config.sidebarLocation !== "undefined") ? PageEditorState.config.sidebarLocation : 'right';
        CreateNewState.itemType = 'page';
        ComponentListState.initSocket();
        FormListState.initSocket();
        UIState.setPageTitle('Edit Element');
        let latestVersion = PageEditorState.versions !== null ? getLastElement(PageEditorState.versions[tabs[tab]]) : null;
        if (latestVersion) {
            this.setState({ latestVersion: latestVersion });
        }
    }

    componentWillUnmount() {
        PageEditorState.socket.close();
        PageEditorState.socket = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { tab, tabs } = this.state;
        if (PageEditorState.versions !== null && getLastElement(PageEditorState.versions[tabs[tab]]) !== null && this.state.latestVersion !== getLastElement(PageEditorState.versions[tabs[tab]])) {
            this.setState({ latestVersion: getLastElement(PageEditorState.versions[tabs[tab]]) });
        }
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    handleTabChange = (event, tab) => {
        this.setState({
            tab,
            latestVersion: null
        })
    };

    handleChange = (key, value) => {
        PageEditorState.config[key] = value;
        this.handleSave();
    };

    handleSave = (fileType = '') => {
        const { tab, tabs, latestVersion } = this.state;

        if (tabs[tab] === 'scss' && this.state.checkedCSSDownload) {
            downloadFile('page', PageEditorState.name, tabs[tab], PageEditorState.css, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if (tabs[tab] === 'js' && this.state.checkedJSDownload) {
            downloadFile('page', PageEditorState.name, tabs[tab], PageEditorState.js, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }

        PageEditorState.versions = updateVersion(tabs[tab], PageEditorState.versions);
        this.setState({ latestVersion: getLastElement(PageEditorState.versions[tabs[tab]]) });

        PageEditorState.emitUpdate();
    };

    downloadFile = async (fileType) => {

        const { tab, tabs, latestVersion } = this.state;
        if (tabs[tab] === 'scss') {
            // I am assuming that "PageEditorState.css" is an object and I wrote it to file as SCSS.
            downloadFile('page', PageEditorState.name, tabs[tab], PageEditorState.css, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
        else if (tabs[tab] === 'js') {
            // I am assuming that "PageEditorState.js" is an object and I wrote it to file as JS.
            downloadFile('page', PageEditorState.name, tabs[tab], PageEditorState.js, (latestVersion !== null ? latestVersion.version : "0.0.1"));
        }
    }

    handleDownloadCheckbox = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    renderToggle() {
        return (<Grid xs={6} md={6} lg={3} item>
            <br />
            <Card variant='outlined'>
                <CardContent>
                    <Typography variant='h6' color='textSecondary' gutterBottom align="center">Set Full Width</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch
                                color="primary"
                                checked={(typeof PageEditorState.config.headerFullWidth !== 'undefined' && PageEditorState.config.headerFullWidth !== null && PageEditorState.config.headerFullWidth !== '') ? PageEditorState.config.headerFullWidth : false}
                                onChange={(event) => this.handleChange('headerFullWidth', event.target.checked)}
                            />}
                            label="Header"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Switch
                                color="primary"
                                checked={(typeof PageEditorState.config.footerFullWidth !== 'undefined' && PageEditorState.config.footerFullWidth !== null && PageEditorState.config.footerFullWidth !== '') ? PageEditorState.config.footerFullWidth : false}
                                onChange={(event) => this.handleChange('footerFullWidth', event.target.checked)}
                            />}
                            label="Footer"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Switch
                                color="primary"
                                checked={(typeof PageEditorState.config.formSidebarFullWidth !== 'undefined' && PageEditorState.config.formSidebarFullWidth !== null && PageEditorState.config.formSidebarFullWidth !== '') ? PageEditorState.config.formSidebarFullWidth : false}
                                onChange={(event) => this.handleChange('formSidebarFullWidth', event.target.checked)}
                            />}
                            label="Form & Sidebar"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Switch
                                color="primary"
                                checked={(typeof PageEditorState.config.afterHeaderFullWidth !== 'undefined' && PageEditorState.config.afterHeaderFullWidth !== null && PageEditorState.config.afterHeaderFullWidth !== '') ? PageEditorState.config.afterHeaderFullWidth : false}
                                onChange={(event) => this.handleChange('afterHeaderFullWidth', event.target.checked)}
                            />}
                            label="After Header"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Switch
                                color="primary"
                                checked={(typeof PageEditorState.config.beforeFooterFullWidth !== 'undefined' && PageEditorState.config.beforeFooterFullWidth !== null && PageEditorState.config.beforeFooterFullWidth !== '') ? PageEditorState.config.beforeFooterFullWidth : false}
                                onChange={(event) => this.handleChange('beforeFooterFullWidth', event.target.checked)}
                            />}
                            label="Before Footer"
                            labelPlacement="start"
                        />
                    </FormControl>
                </CardContent>
            </Card>
        </Grid>)
    }

    addMetaTag = () => {
        PageEditorState.meta.extraTags.push({
            name: '',
            value: '',
        })
    }

    removeMetaTag = (index) => {
        if (PageEditorState.meta.extraTags.length < 2) {
            PageEditorState.meta.extraTags = [];
        } else {
            PageEditorState.meta.extraTags.splice(index, 1);
        }
    }

    renderSidebar = (label, name) => {
        return (
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel htmlFor="selection-provider">{label}</InputLabel>
                <Select
                    value={PageEditorState.config[name] || 'right'}
                    onChange={(event) => this.handleChange(name, event.target.value)}
                    inputProps={{ id: 'selection-provider' }}
                >
                    <MenuItem value='left'>Left</MenuItem>
                    <MenuItem value='right'>Right</MenuItem>
                </Select>
            </FormControl>
        );
    }

    renderComponentSelection = (label, name) => {
        return (
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel htmlFor="selection-provider">{label}</InputLabel>
                <Select value={PageEditorState.config[name] || 0}
                    onChange={(event) => this.handleChange(name, event.target.value)}
                    inputProps={{ id: 'selection-provider' }}
                >
                    <MenuItem key="none" value={0}>None</MenuItem>
                    {ComponentListState.data.map((component) => {
                        return (<MenuItem key={component.id} value={component.id}>
                            {component.name}&nbsp;
                            {component.is_draft ? <span style={{ color: 'red' }}>(Draft)</span> : null}
                        </MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    }

    renderDonationFormSelection = (label, name) => {
        return (
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel htmlFor="selection-provider">{label}</InputLabel>
                <Select value={PageEditorState.config[name] || 0}
                    onChange={(event) => this.handleChange(name, event.target.value)}
                    inputProps={{ id: 'selection-provider' }}
                >
                    <MenuItem key="none" value={0}>None</MenuItem>
                    {FormListState.data.map((form) => {
                        return (<MenuItem key={form.id} value={form.id}>
                            {form.name}&nbsp;
                            {form.is_draft ? <span style={{ color: 'red' }}>(Draft)</span> : null}
                        </MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    }

    render() {
        const { match: { params } } = this.props;
        const { history } = this.props;
        const { latestVersion } = this.state;
        let updatedDateTime = latestVersion ? latestVersion.updatedAt.split(" ") : "Not Updated";
        console.log("PageEditorState");
        console.log(PageEditorState);
        setTimeout(() => {
            if (PageEditorState.name === "") {
                history.push('/pages');
            }
        }, 1000);
        return (
            <Paper style={{ padding: 15, margin: 20 }}>
                <Typography variant='h6' gutterBottom>Page Name: {PageEditorState.name}</Typography>
                <PublishPage />
                <AbandonPageChanges />
                <Button
                    onClick={() => window.open(mapDomain(`page?cl=${ClientState.currentClient}&page=${params.id}&preview=true`))}
                    color='primary'
                    style={{ float: 'right' }}
                >Preview Page</Button>
                <Tabs value={this.state.tab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab label='Page Config' {...this.a11yProps(0)} />
                    <Tab label='Page Meta' {...this.a11yProps(1)} />
                    <Tab label='CSS' {...this.a11yProps(2)} />
                    <Tab label='Javascript' {...this.a11yProps(3)} />
                    <Tab label='Variables' {...this.a11yProps(4)} />
                </Tabs>
                <TabPanel value={this.state.tab} index={0}>
                    {this.renderSidebar('Sidebar Location', 'sidebarLocation')}
                    {this.renderComponentSelection('Header Component', 'headerComponent')}
                    {this.renderComponentSelection('Sidebar Component', 'sidebarComponent')}
                    {this.renderComponentSelection('Footer Component', 'footerComponent')}
                    {this.renderDonationFormSelection('Donation Form', 'form')}
                    {this.renderComponentSelection('After Header Component', 'afterHeaderComponent')}
                    {this.renderComponentSelection('Before Footer Component', 'beforeFooterComponent')}
                    {this.renderToggle()}
                </TabPanel>
                <TabPanel value={this.state.tab} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Page Title & Description</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <TextField
                                        label='Page Title'
                                        fullWidth
                                        value={PageEditorState.meta.title}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.title = event.target.value}
                                    />
                                    <TextField
                                        label='Page Description'
                                        fullWidth
                                        value={PageEditorState.meta.description}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.description = event.target.value}
                                    />
                                </CardContent>
                                <Divider />
                                <div>
                                    <Button onClick={() => this.handleSave()} color='primary' style={{ float: 'right' }}>Save Changes</Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Open Graph Tags</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <TextField
                                        label='Open Graph Title'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:title"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:title"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph Description'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:description"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:description"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph Type'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:type"]}
                                        defaultValue={'website'}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:type"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph url'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:url"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:url"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph Image'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:image"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:image"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph Twitter Card'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:twitter:card"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:twitter:card"] = event.target.value}
                                    />
                                    <TextField
                                        label='Open Graph Twitter Site'
                                        fullWidth
                                        value={PageEditorState.meta.ogTags["og:twitter:site"]}
                                        defaultValue={''}
                                        onChange={(event) => PageEditorState.meta.ogTags["og:twitter:site"] = event.target.value}
                                    />
                                </CardContent>
                                <Divider />
                                <div>
                                    <Button onClick={() => this.handleSave()} color='primary' style={{ float: 'right' }}>Save Changes</Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6' color='textSecondary' gutterBottom>Other Meta Tags</Typography>
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    <Typography variant='p' color='textSecondary'>
                                        If you would like to define more meta tags for your page, you can add them here.
                                    </Typography>
                                    <Button onClick={() => this.addMetaTag()} color='primary' style={{ float: 'right' }}>Add Tag</Button>
                                    {PageEditorState.meta.extraTags.map((extraTag, index) => (
                                        <Grid container spacing={2} key={index}>
                                            <Grid row xs={3}>
                                                <TextField
                                                    label='Tag Name'
                                                    fullWidth
                                                    value={extraTag.name}
                                                    defaultValue={''}
                                                    onChange={(event) => extraTag.name = event.target.value}
                                                />
                                            </Grid>
                                            <Grid row xs={8}>
                                                <TextField
                                                    label='Tag Value'
                                                    fullWidth
                                                    value={extraTag.value}
                                                    defaultValue={''}
                                                    onChange={(event) => extraTag.value = event.target.value}
                                                />
                                            </Grid>
                                            <Grid row xs={1}>
                                                <Button onClick={() => this.removeMetaTag(index)} color='primary' style={{ float: 'right' }}>Remove</Button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </CardContent>
                                <Divider />
                                <div>
                                    <Button onClick={() => this.handleSave()} color='primary' style={{ float: 'right' }}>Save Changes</Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={this.state.tab} index={2}>
                    <CSSEditor editing='page' />
                    <Button onClick={() => this.handleSave('scss')} color='primary'>Save Changes</Button>
                    <FormControlLabel
                        style={{ marginLeft: "15px", marginRight: "0px" }}
                        control={
                            <Checkbox
                                checked={this.state.checkedCSSDownload}
                                onChange={this.handleDownloadCheckbox}
                                name="checkedCSSDownload"
                                color="primary"
                            />
                        }
                    />
                    <Button onClick={() => this.downloadFile('scss')} color='primary'>Download CSS</Button>
                    <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                </TabPanel>
                <TabPanel value={this.state.tab} index={3}>
                    <JSEditor editing='page' />
                    <Button onClick={() => this.handleSave('js')} color='primary'>Save Changes</Button>
                    <FormControlLabel
                        style={{ marginLeft: "15px", marginRight: "0px" }}
                        control={
                            <Checkbox
                                checked={this.state.checkedJSDownload}
                                onChange={this.handleDownloadCheckbox}
                                name="checkedJSDownload"
                                color="primary"
                            />
                        }
                    />
                    <Button onClick={() => this.downloadFile('js')} color='primary'>Download JS</Button>
                    <label className="latestVersion">Version: {latestVersion ? latestVersion.version : 'Not Updated'} | Updated By: {latestVersion ? latestVersion.updatedBy : 'Not Updated'} | Updated At: {latestVersion ? updatedDateTime[0] + " " + updatedDateTime[1].replace(/-/g, ":") + " (UTC)" : 'Not Updated'}</label>
                </TabPanel>
                <TabPanel value={this.state.tab} index={4}>
                    <VariableEditor type='page' currentId={PageEditorState.componentId} />
                </TabPanel>
            </Paper>
        );
    }
}

export default view(EditPage);