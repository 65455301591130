import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from '@material-ui/core/LinearProgress';
import AssetManagerState from "../../State/AssetManagerState";
import Divider from "@material-ui/core/Divider";

class UploadProgress extends Component{

    render(){
        return (
            <Dialog open={AssetManagerState.uploaderVisible}>
                <DialogTitle>Uploading Files</DialogTitle>
                <Divider />
                <DialogContent style={{width: 400}}>
                    <LinearProgress variant="determinate" value={(AssetManagerState.uploadFileCount === 0) ? 100 : (AssetManagerState.uploadedFiles / AssetManagerState.uploadFileCount) * 100} />
                </DialogContent>
            </Dialog>
        );
    }

}

export default view(UploadProgress);