import { store } from "@risingstack/react-easy-state";

const DeleteVariable = store({
    isOpen: false,
    data: {},
    type: "", // type can be "view" or "delete"
    selectedVariable: "",
    selectedVariableIndex: "",
    deleteType: "global", // type can be "global" for delete global variable, "individual" for delete specific form, component and pages variable
    deleteFrom: "global", // possible values, "global", "forms", "pages", "components",
    open: () => {
        DeleteVariable.isOpen = true;
    },
    close: () => {
        DeleteVariable.isOpen = false;
    }
});

export default DeleteVariable;