import React from 'react';
import {view} from '@risingstack/react-easy-state'
import {Route} from "react-router-dom";
import ComponentList from "./ComponentList";
import EditComponent from "./EditComponent";

export default view(function() {
    return (
        <>
            <Route path='/components' component={ComponentList} />
            <Route path='/component/:id' component={EditComponent} />
        </>
    );
});