import { store } from '@risingstack/react-easy-state'
import GlobalSettingsSocket from "../Sockets/GlobalSettingsSocket";

const GlobalSettingsState = store({
    isOpen: false,
    data: [],
    paymentProviders: { preview: {}, live: {} },
    deletes: [],
    socket: null,
    globalVariableDepends: [],
    deleteVariableDepends: [],
    existsVariableDepends: [],
    isDependencyLoading: true,
    isDeleteLoading: false,
    isCheckExistsLoading: false,
    isCheckExistsError: false,
    isCheckExistsErrorGlobal: false,
    isCheckExistsLoadingForGlobal: false,
    initSocket: () => {
        if (!GlobalSettingsState.socket) {
            GlobalSettingsSocket.init();
            GlobalSettingsState.socket = GlobalSettingsSocket.socket;
            GlobalSettingsState.receiveUpdate();
        }
    },
    emitUpdate: () => {
        // console.log("GlobalSettingsState->emitUpdate", GlobalSettingsState.data);
        GlobalSettingsState.socket.emit('update', GlobalSettingsState.data);
    },
    reinitSocket: (client, callback = null) => {
        if (GlobalSettingsState.socket) {
            GlobalSettingsState.socket.close();
            GlobalSettingsState.socket = null;
        }
        GlobalSettingsSocket.socket = null;
        GlobalSettingsState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    receiveUpdate: () => {
        GlobalSettingsState.socket.on('serverUpdate', (data) => {
            GlobalSettingsState.data = data; // this duplicates the array data
            // console.log("GlobalSettingsState->receiveUpdate",data);
            const paymentProvidersPreview = data.filter(item => item.config.indexOf('paymentProviders-preview') !== -1);
            const paymentProvidersLive = data.filter(item => item.config.indexOf('paymentProviders-live') !== -1);
            // console.log("GlobalSettingsState->receiveUpdate->paymentProviders-preview", paymentProvidersPreview);
            // console.log("GlobalSettingsState->receiveUpdate->paymentProviders-live", paymentProvidersLive);
            paymentProvidersPreview.forEach(element => {
                var type = element.config.replace('paymentProviders-preview-', '');
                var value = JSON.parse(element.value);
                // console.log(type);
                GlobalSettingsState.paymentProviders.preview[type] = value;
            });
            paymentProvidersLive.forEach(element => {
                var type = element.config.replace('paymentProviders-live-', '');
                var value = JSON.parse(element.value);
                // console.log(type);
                GlobalSettingsState.paymentProviders.live[type] = value;
            });
        });
        GlobalSettingsState.socket.on('getDependencies', (data) => {
            GlobalSettingsState.isCheckExistsLoadingForGlobal = false;
            if (Object.keys(data).length > 0) {
                GlobalSettingsState.isCheckExistsErrorGlobal = true;
            } else {
                GlobalSettingsState.isCheckExistsErrorGlobal = false;
            }

            GlobalSettingsState.isDependencyLoading = false;
            GlobalSettingsState.globalVariableDepends = data;

        });
        GlobalSettingsState.socket.on('getVariableDependencies', (data) => {
            GlobalSettingsState.deleteVariableDepends = data;
            GlobalSettingsState.isDeleteLoading = false;
        });
        GlobalSettingsState.socket.on('getExistsDependencies', (data) => {
            GlobalSettingsState.isCheckExistsLoading = false;
            if (Object.keys(data).length > 0) {
                GlobalSettingsState.isCheckExistsError = true;
            } else {
                GlobalSettingsState.isCheckExistsError = false;
            }
            GlobalSettingsState.existsVariableDepends = data;

        });
    },
    open: () => {
        GlobalSettingsState.isOpen = true;
        GlobalSettingsState.socket.emit('requestedUpdate');
    },
    close: () => {
        GlobalSettingsState.isOpen = false;
    },
    toggleOpen: () => {
        GlobalSettingsState.isOpen = !GlobalSettingsState.isOpen
    },
    async setPaymentProvider(type, configObject, mode) {
        GlobalSettingsState.paymentProviders[mode][type] = configObject;
        // console.log("GlobalSettingsState->setPaymentProvider",GlobalSettingsState.paymentProviders);

        // eslint-disable-next-line array-callback-return
        let obj = await GlobalSettingsState.data.find((o, i) => {
            // console.log("GlobalSettingsState.data.find:", o.config);
            if (o.config === 'paymentProviders-' + mode + '-' + type) {
                // console.log("GlobalSettingsState.data.find", o);
                // console.log("GlobalSettingsState->setPaymentProvider->data.find at", i);
                GlobalSettingsState.data[i].value = JSON.stringify(GlobalSettingsState.paymentProviders[mode][type]);
                return true; // stop searching
            }
        });

        // console.log("GlobalSettingsState.data.find obj value", obj);
        if (typeof obj === "undefined") {
            // console.log("GlobalSettingsState.data.not->found-then-add");
            GlobalSettingsState.data.push({
                config: "paymentProviders-" + mode + '-' + type,
                value: JSON.stringify(GlobalSettingsState.paymentProviders[mode][type])
            });
        }
        GlobalSettingsState.emitUpdate();
    },
    async saveChanges() {
        GlobalSettingsState.emitUpdate();
    },
    performDelete: (index) => {
        if (GlobalSettingsState.data[index].id) {
            GlobalSettingsState.deletes.push(GlobalSettingsState.data[index].id);
        }

        if (GlobalSettingsState.data.length < 2) {
            GlobalSettingsState.data = [];
        } else {
            const newArray = GlobalSettingsState.data.slice(0, index);
            GlobalSettingsState.data.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            GlobalSettingsState.data = newArray;
        }
    },
    checkIsVariableExists: (variableName) => {
        GlobalSettingsState.isDependencyLoading = true;
        GlobalSettingsState.socket.emit('checkIsVariableExists', {
            variableName
        });
    },
    isVariableAlreadyExists: (variableName, type = "", id = "", isIndividual = false) => {
        GlobalSettingsState.isDependencyLoading = true;
        GlobalSettingsState.socket.emit('isVariableAlreadyExists', {
            variableName,
            type,
            id,
            isIndividual
        });
    },
    checkIsVariableInUse: (variableName, type = "", id = "", isIndividual = false) => {
        GlobalSettingsState.isCheckExistsLoading = true;
        GlobalSettingsState.isCheckExistsLoadingForGlobal = true;

        GlobalSettingsState.socket.emit('checkIsVariableInUse', {
            variableName,
            type,
            id,
            isIndividual
        });
    },
});

export default GlobalSettingsState;