import { store } from '@risingstack/react-easy-state'
import CustomFieldSocket from "../Sockets/CustomFieldSocket";
import NotificationState from "./NotificationState";
import CustomFieldListState from "../State/CustomFieldListState";
import hostname from "../lib/hostname";
import { getClientInitials, getErrorMessage } from "../lib";
import ClientState from '../State/ClientState';
import axios from 'axios';

const EditCustomFieldState = store({
    customFieldId: 0,
    data: {},
    fieldName: '',
    socket: null,
    isLoading: false,
    isError: false,
    errorMessge: '',
    setCustomField: (fieldId) => {
        EditCustomFieldState.customFieldId = fieldId;
        EditCustomFieldState.initSocket(fieldId);
        EditCustomFieldState.receiveUpdate();
    },
    initSocket: (fieldId) => {
        if (fieldId && !EditCustomFieldState.socket) {
            CustomFieldSocket.init(fieldId);
            EditCustomFieldState.socket = CustomFieldSocket.socket;
        }
    },
    receiveUpdate: () => {
        EditCustomFieldState.socket.on('serverUpdate', (data) => {
            EditCustomFieldState.data = data.data;
            EditCustomFieldState.fieldName = data.name;
        });
    },
    save: async () => {
        EditCustomFieldState.data.name = EditCustomFieldState.fieldName;

        // fetch custom field value
        let customField = EditCustomFieldState.data.options.find((opt) => { return opt.field === 'name'; });
        if (typeof customField.value !== "undefined" && customField.value !== '' && !EditCustomFieldState.isError) {
            try {
                // eslint-disable-next-line no-useless-escape
                let regex = new RegExp("^[a-zA-Z0-9_\s]+$");
                if (!regex.test(customField.value)) {
                    EditCustomFieldState.isError = true;
                    EditCustomFieldState.errorMessge = getErrorMessage('customField');
                    return false;
                }

                // store the custom field to S3
                EditCustomFieldState.isError = false;
                EditCustomFieldState.isLoading = true;
                let status = process.env.REACT_APP_NODE_ENV === 'development' ? 'preview' : 'live';
                let formData = new FormData();
                formData.append('acronym', getClientInitials(ClientState.currentClient));
                formData.append('fieldName', customField.value);
                formData.append('status', status);

                const apiHost = hostname.creativeAPI();

                let url = `${apiHost}/creatives/createCustomFieldFromFormBuilder`;
                const { data } = await axios.post(url, formData);
                if (data.status === "success") {
                    EditCustomFieldState.isLoading = false;
                    NotificationState.open('The custom field has been updated successfully!', 'Custom Field Updated');
                } else {
                    EditCustomFieldState.isLoading = false;
                    NotificationState.open(data.message, 'Custom Field Updated Failed');
                }

            } catch (e) {
                EditCustomFieldState.isLoading = false;
                NotificationState.open(e.message, 'Custom Field Updated Failed');
            }
        } else {
            EditCustomFieldState.isError = true;
            EditCustomFieldState.errorMessge = 'Please Enter Name';
        }

        if (!EditCustomFieldState.isError) {
            EditCustomFieldState.data.id = `custom_${customField.value}`;
            EditCustomFieldState.socket.emit('clientUpdate', {
                data: EditCustomFieldState.data,
                name: EditCustomFieldState.fieldName
            });
            setTimeout(() => { CustomFieldListState.requestUpdate(); }, 1000);
        }
    }
});

export default EditCustomFieldState;