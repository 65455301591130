import React from 'react';
import {view} from '@risingstack/react-easy-state'
import {Route} from "react-router-dom";
import CustomFieldList from "./CustomFieldList";
import EditCustomField from "./EditCustomField";

export default view(function() {
    return (
        <>
            <Route path='/customFields' component={CustomFieldList} />
            <Route path='/customField/:id' component={EditCustomField} />
        </>
    );
});