import {store} from "@risingstack/react-easy-state";

const NotificationState = store({
    title: '',
    content: '',
    isOpen: false,
    open: (message, title = 'Notification') => {
        NotificationState.content = message;
        NotificationState.title = title;
        NotificationState.isOpen = true;
    },
    close: () => {
        NotificationState.isOpen = false;
    }
});

export default store(NotificationState);