import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import ThankYouPage from "./ThankYouPage";
import Grid from "@material-ui/core/Grid";

class ThankYouList extends Component {

    render(){
        const { thankYouPages } = this.props;

        return (<Grid container spacing={2}>
            {thankYouPages.map((thankYouPage, index) => (
                <ThankYouPage index={index} {...thankYouPage} />
            ))}
        </Grid>)
    }

}

export default view(ThankYouList);