import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state';
import Button from "@material-ui/core/Button";
import PageEditorState from "../../State/PageEditorState";

class PublishPage extends Component{

    onClick() {
        PageEditorState.publishPage();
    }

    render() {
        return (
            <Button
                onClick={() => this.onClick()}
                color='primary'
                style={{float: 'right'}}
            >Publish Page</Button>
        );
    }

}

export default view(PublishPage);