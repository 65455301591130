import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { capitalizeFirstLetter } from "../../lib";
import DeleteVariableState from "../../State/DeleteVariableState";
import GlobalSettingsState from "../../State/GlobalSettingsState"
import GlobalVariableState from "../../State/GlobalVariableState";
import ListOfDependencies from '../Dialogs/Delete/ListOfDependencies';
import PageEditorState from "../../State/PageEditorState";
import ComponentEditorState from "../../State/ComponentEditorState";
import FormPageEditorState from "../../State/FormPageEditorState";

class DeleteVariable extends Component {

    state = {
        isError: false,
    }
    componentDidMount() {
        GlobalSettingsState.initSocket();
        GlobalVariableState.initSocket();
    }

    handleClose = () => {
        DeleteVariableState.close();
        GlobalSettingsState.deleteVariableDepends = [];
    };


    handleDeleteVariable = async (currentDeleteType = "global") => {
        if (currentDeleteType === "individual") {
            GlobalSettingsState.isCheckExistsError = false;
            switch (DeleteVariableState.deleteFrom) {
                case 'pages':
                    PageEditorState.removeVariable(DeleteVariableState.selectedVariableIndex);
                    break;
                case 'components':
                    ComponentEditorState.removeVariable(DeleteVariableState.selectedVariableIndex);
                    break;
                case 'forms':
                    FormPageEditorState.removeVariable(DeleteVariableState.selectedVariableIndex);
                    break;
                default:
                    break;
            }
        } else if (currentDeleteType === "global") {
            GlobalSettingsState.isCheckExistsErrorGlobal = false;
            GlobalVariableState.performDelete(DeleteVariableState.selectedVariableIndex);
        }
        DeleteVariableState.close();

    };

    render() {
        let msg = DeleteVariableState.type === "delete" ? <h4>Are you sure you want to delete <span className="highlight-delete-title">{DeleteVariableState.selectedVariable} </span> variable?</h4> : <h4><span className="highlight-delete-title">{DeleteVariableState.selectedVariable} </span> variable is not used anywhere.</h4>;
        let currentDeleteType = DeleteVariableState.deleteType;

        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    onClose={() => this.handleClose()} open={DeleteVariableState.isOpen}
                >
                    <DialogTitle>
                        {DeleteVariableState.type === "delete" ? "Delete " : "Dependent of "}
                        <span className="highlight-delete-title">{DeleteVariableState.selectedVariable} </span>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <React.Fragment>
                            {GlobalSettingsState.isDeleteLoading && <p>Loading...</p>}
                            {!GlobalSettingsState.isDeleteLoading && Object.keys(GlobalSettingsState.deleteVariableDepends).length === 0 && msg}
                            {!GlobalSettingsState.isDeleteLoading && Object.entries(GlobalSettingsState.deleteVariableDepends).map(([element, dependency]) => (
                                <>
                                    {typeof DeleteVariableState.deleteType !== "undefined" && DeleteVariableState.deleteType === "individual" ?
                                        <>
                                            <ListOfDependencies
                                                viewType="deleteIndividualTable"
                                                deleteType={DeleteVariableState.deleteType}
                                                dependenciesOf={element}
                                                dependencies={dependency}
                                            />
                                        </>
                                        :
                                        <>
                                            <h3>{"This variable name is already use in the following " + capitalizeFirstLetter(element, "_", " ") + ":"}</h3>
                                            <ListOfDependencies
                                                viewType="deletetable"
                                                dependenciesOf={element}
                                                dependencies={dependency}
                                            />
                                        </>
                                    }
                                </>
                            ))
                            }
                        </React.Fragment>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {DeleteVariableState.type === "delete" && <Button
                            onClick={() => this.handleDeleteVariable(currentDeleteType)}
                            color='primary'
                            disabled={(GlobalSettingsState.isDeleteLoading || Object.keys(GlobalSettingsState.deleteVariableDepends).length > 0) ? true : false}
                        >Delete Variable</Button>
                        }
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default view(DeleteVariable);