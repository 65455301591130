import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DeleteStateElement from "../../../State/Common/DeleteStateElement";
import FormPageEditorState from "../../../State/FormPageEditorState";

class DeleteFormElement extends Component {

    handleSubmit() {
        if (DeleteStateElement.type === "page") {
            FormPageEditorState.deleteComponent(DeleteStateElement.page, DeleteStateElement.component, DeleteStateElement.type);
            this.forceUpdate();
            this.handleClose();
        }
    }

    handleClose() {
        DeleteStateElement.modalOpen = false;
        DeleteStateElement.type = null;
    }

    render() {

        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={DeleteStateElement.modalOpen}>
                    <DialogTitle id="alert-dialog-title">
                        {DeleteStateElement.type === "page" && `Delete ${DeleteStateElement.elementName} Field?`}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {DeleteStateElement.type === "page" && `Are you sure you want to delete this field?`}
                        </DialogContentText>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='primary'>Cancel</Button>
                        <Button onClick={() => this.handleSubmit()} color='secondary'>Delete Field</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default view(DeleteFormElement);

