import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state';
import Button from "@material-ui/core/Button";
import ComponentEditorState from "../../State/ComponentEditorState";

class PublishComponent extends Component{

    onClick() {
        ComponentEditorState.publishComponent();
    }

    render() {
        return (
            <Button
                onClick={() => this.onClick()}
                color='primary'
                style={{float: 'right'}}
            >Publish Component</Button>
        );
    }

}

export default view(PublishComponent);