import { store } from '@risingstack/react-easy-state';
import BillingFirstName from '../ComponentTypes/BillingFirstName';
import BillingLastName from '../ComponentTypes/BillingLastName';
import BillingEmail from '../ComponentTypes/BillingEmail';
import BillingPhone from '../ComponentTypes/BillingPhone';
import BillingStreetAddress1 from '../ComponentTypes/BillingStreetAddress1.json';
import BillingStreetAddress2 from '../ComponentTypes/BillingStreetAddress2.json';
import BillingCity from '../ComponentTypes/BillingCity';
import BillingState from '../ComponentTypes/BillingState';
import BillingZip from '../ComponentTypes/BillingZip';
import Salutation from '../ComponentTypes/Salutation.json'
import TributeHonoreeFirstName from '../ComponentTypes/TributeHonoreeFirstName';
import TributeHonoreeLastName from '../ComponentTypes/TributeHonoreeLastName';
import TributeRecipientFirstName from '../ComponentTypes/TributeRecipientFirstName';
import TributeRecipientLastName from '../ComponentTypes/TributeRecipientLastName';
import TributeCity from '../ComponentTypes/TributeCity';
// import TributeHonoreeEmail from '../ComponentTypes/TributeHonoreeEmail';
import TributeRecipientEmail from '../ComponentTypes/TributeRecipientEmail';
import TributeRecipientPhoneNumber from '../ComponentTypes/TributeRecipientPhoneNumber.json';
import TributeStreetAddress1 from '../ComponentTypes/TributeStreetAddress1.json';
// import TributeStreetAddress2 from '../ComponentTypes/TributeStreetAddress2.json';
import TributeState from '../ComponentTypes/TributeState';
import TributeZip from '../ComponentTypes/TributeZip';
import TributeMessage from '../ComponentTypes/TributeMessage';
import PaymentInfo from '../ComponentTypes/PaymentInfo';
import GiftArrayBtn from '../ComponentTypes/GiftArrayBtn.json';
import RecurringGift from '../ComponentTypes/RecurringGift.json';
import CoverFeeOption from '../ComponentTypes/CoverFeeOption.json';
// import CustomTextbox from '../ComponentTypes/CustomTextbox';
import HorizontalRule from '../ComponentTypes/HorizontalRule.json';
import TitleText from '../ComponentTypes/TitleText.json';
import TributeCheckbox from '../ComponentTypes/TributeCheckbox.json';
import TributeType from '../ComponentTypes/TributeType.json';
import TributeNotification from '../ComponentTypes/TributeNotification.json';
import AnonymousCheckbox from '../ComponentTypes/AnonymousCheckbox.json';
import Source from '../ComponentTypes/Source.json';
import TributeNotificationType from '../ComponentTypes/TributeNotificationType.json';
import GiftNotes from '../ComponentTypes/GiftNotes.json'

// import CompanyDonorCheckbox from '../ComponentTypes/CompanyDonorCheckbox.json';
// import CompanyDonorName from '../ComponentTypes/CompanyDonorName.json';
// import CompanyMatchingCheckbox from '../ComponentTypes/CompanyMatchingCheckbox.json';
// import CompanyMatchingName from '../ComponentTypes/CompanyMatchingName.json';
import RememberMe from '../ComponentTypes/RememberMe.json';

import CompanyCheckboxAndTextbox from '../ComponentTypes/CompanyCheckboxTextBox.json';
import CompanyMatchingCheckboxTextbox from '../ComponentTypes/CompanyMatchingCheckboxTextbox.json';

import BillingCountry from '../ComponentTypes/BillingCountry';
import TributeCountry from '../ComponentTypes/TributeCountry';

import CustomHTML from '../ComponentTypes/CustomHTML.json';
import ThankYouPageCustomHTML from '../ComponentTypes/ThankYouPageCustomHTML.json';

import LightboxIfDonationErrorAfterNAttempts from '../ComponentTypes/LightboxIfDonationErrorAfterNAttempts.json';

import TributeTriggerButton from '../ComponentTypes/TributeTriggerButton.json';

const ComponentTypes = store({
    types: [
        BillingFirstName,
        BillingLastName,
        BillingEmail,
        BillingPhone,
        BillingStreetAddress1,
        BillingStreetAddress2,
        BillingCity,
        BillingState,
        BillingZip,
        Salutation,
        GiftArrayBtn,
        RecurringGift,
        CoverFeeOption,
        PaymentInfo,
        TributeCheckbox,
        TributeHonoreeFirstName,
        TributeHonoreeLastName,
        TributeRecipientFirstName,
        TributeRecipientLastName,
        TributeRecipientPhoneNumber,
        TributeCity,
        // TributeHonoreeEmail,
        TributeRecipientEmail,
        TributeStreetAddress1,
        //TributeStreetAddress2,
        TributeState,
        TributeType,
        TributeZip,
        TributeMessage,
        TributeNotification,
        TributeNotificationType,
        // CustomTextbox,
        HorizontalRule,
        TitleText,
        AnonymousCheckbox,
        Source,
        GiftNotes,
        // CompanyDonorCheckbox,
        // CompanyDonorName,
        // CompanyMatchingCheckbox,
        // CompanyMatchingName,
        CompanyCheckboxAndTextbox,
        CompanyMatchingCheckboxTextbox,
        RememberMe,
        CustomHTML,
        BillingCountry,
        TributeCountry,
        LightboxIfDonationErrorAfterNAttempts,
        ThankYouPageCustomHTML,
        TributeTriggerButton
    ]
});

export default ComponentTypes;