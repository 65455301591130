import React, {Component} from 'react';
import {view} from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import PageListState from "../../../State/PageListState";
import CopyPageState from "../../../State/Page/CopyPageState";
import ClientState from '../../../State/ClientState';

class CopyPage extends Component{

    state = {
        isError: false,
        toClient: "none",
        toClientValidate: false
    }

    handleSubmit() {
        let isFormValidate = true;
        if(CopyPageState.dynamicElementName.trim() === "") {
            this.setState({ isError: true });
            isFormValidate = false;
        }

        if(CopyPageState.toClient && this.state.toClient === "none") {
            this.setState({ toClientValidate: true });
            isFormValidate = false;
        }

        if(isFormValidate) {
            CopyPageState.targetClient = CopyPageState.toClient ? this.state.toClient : "";
            PageListState.copyPage(CopyPageState.sourceId, CopyPageState.dynamicElementName, CopyPageState.toClient ? this.state.toClient : "");
        }
    }

    handleClose() {
        this.setState({
            isError: false,
            toClient: "none",
            toClientValidate: false
        });
        CopyPageState.modalOpen = false;
        CopyPageState.toClient = false;
        CopyPageState.targetClient = false;
        CopyPageState.dynamicElementName = ""; 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if(event.target.name === "name") {
            CopyPageState.dynamicElementName = event.target.value;
            if(event.target.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
            }
        }

        if(CopyPageState.toClient && event.target.name === "toClient") {
            if (event.target.value !== "none") {
                this.setState({ toClientValidate: false });
                PageListState.getDynamicElementName(CopyPageState.sourceName, event.target.value);        
            } else {
                this.setState({ toClientValidate: true });
            }
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={CopyPageState.modalOpen}>
                    <DialogTitle>Copy Page {CopyPageState.toClient ? 'To Another Client' : ''}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the page a name to get started.</DialogContentText>
                        {CopyPageState.toClient &&
                            <React.Fragment>
                                <FormControl fullWidth error={ this.state.toClientValidate }>
                                    <InputLabel id={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}>Client</InputLabel>
                                    <Select
                                        autoFocus
                                        fullWidth
                                        labelId={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}
                                        id={`client-select${this.state.toClientValidate ? `-error` : ``}`}
                                        name='toClient'
                                        value={this.state.toClient}
                                        onChange={(event) => this.handleChange(event)}
                                    >
                                        <MenuItem value="none" key="0">Please Select</MenuItem>
                                        {ClientState.clientList.map((client, key) => (
                                            <MenuItem value={client} key={key}>{client}</MenuItem>
                                        ))}
                                    </Select>
                                    { this.state.toClientValidate && 
                                        <FormHelperText>Please Select Client</FormHelperText>
                                    }
                                </FormControl>
                            </React.Fragment>
                        }
                        <TextField
                            margin='dense'
                            label={"Page Name"}
                            name='name'
                            type='text'
                            value={CopyPageState.dynamicElementName}
                            onChange={(event) => this.handleChange(event)}
                            helperText={this.state.isError ? "Please Enter Page Name" : ""}
                            error={this.state.isError}
                            fullWidth
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!PageListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'>
                                Copy Page
                        </Button>
                        }
                        {PageListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'
                                disabled={true}>
                                Copy Page  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    PageListState.isDuplicatePage && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={PageListState.isDuplicatePage} autoHideDuration={10000}
                        onClose={() => PageListState.isDuplicatePage = false}>

                        <Alert variant="filled" severity="error" onClose={() => PageListState.isDuplicatePage = false}>
                            {PageListState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>
        );
    }

}

export default view(CopyPage);

