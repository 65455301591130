import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Grid from '@material-ui/core/Grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssetManagerState from "../../State/AssetManagerState";
import FileIcon from "./FileIcon";
import AssetPreviewState from "../../State/AssetPreviewState";
import Dropzone from "react-dropzone";

import './FileList.css';

import mime from "mime";
import DeleteForm from "../Dialogs/Delete/DeleteForm";
import DeleteState from "../../State/Common/DeleteState";

class FolderList extends Component{

    state = {
        dragEnter: false
    };

    openPreview = (file) => {
        const currentDir = AssetManagerState.getCurrentDirectory();
        const mimeType = mime.getType(file.Key);
        AssetPreviewState.isOpen = true;
        AssetPreviewState.mimeType = mimeType;
        AssetPreviewState.size = file.Size;
        AssetPreviewState.assetLink = AssetManagerState.baseUrl + file.Key;
        AssetPreviewState.name = file.Key.replace(currentDir.Prefix, '');
        AssetPreviewState.lastModified = file.LastModified;
        if(mimeType === null) {
            AssetPreviewState.type = 'unknown';
        } else if(mimeType.includes('html') || mimeType.includes('css') || mimeType.includes('javascript')){
            AssetPreviewState.type = 'html';
        } else if(mimeType.includes('pdf')){
            AssetPreviewState.type = 'pdf';
        } else if(mimeType.includes('image')){
            AssetPreviewState.type = 'image';
        } else {
            AssetPreviewState.type = 'unknown';
        }
    };

    handleUpload = (acceptedFiles) => {
        this.setState({dragEnter: false})
        const currentDir = AssetManagerState.getCurrentDirectory();
        AssetManagerState.uploadFiles(currentDir.Prefix, acceptedFiles);
    };

    handleDelete = (fileName) => {
        DeleteState.type = 'assetManager';
        DeleteState.assetName = fileName;
        DeleteState.modalOpen = true;
        DeleteState.isDeleteModal = true;
    };

    handleViewDependencies = (fileName) => {
        DeleteState.type = 'assetManager';
        DeleteState.assetName = fileName;
        DeleteState.modalOpen = true;
        DeleteState.isDeleteModal = false;
    };

    render(){
        const { files } = this.props;
        const currentDir = AssetManagerState.getCurrentDirectory();
        return (
                <React.Fragment>
                    <Dropzone
                        onDrop={(acceptedFiles) => this.handleUpload(acceptedFiles)}
                        noClick={true}
                        noKeyboard={true}
                        preventDropOnDocument={true}
                        onDragEnter={() => this.setState({dragEnter: true})}
                        onDragLeave={() => this.setState({dragEnter: false})}
                    >
                        {({getRootProps, getInputProps}) => (
                            <Grid container style={{ padding: 5, minHeight: '100px'}} {...getRootProps()} className={this.state.dragEnter ? 'dragEnter' : ''}>
                                <input {...getInputProps} type='hidden' />
                                {files.map((file, index) => (
                                    <>
                                        {file.Key !== '/' && file.Key.replace(currentDir.Prefix, '') !== '.folder' &&
                                            <Grid item md={2} xs={12} key={index}>
                                                <ContextMenuTrigger id={`file-context-trigger-${index}`}>
                                                    <List component="nav" aria-label="main mailbox folders">
                                                        <ListItem className="align-items-start" button onClick={() => this.openPreview(file)}>
                                                            <ListItemIcon>
                                                                <FileIcon filename={file.Key} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className='word-break-all'
                                                                primary={file.Key.replace(currentDir.Prefix, '')}
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </ContextMenuTrigger>
                                                <ContextMenu id={`file-context-trigger-${index}`}>
                                                    <MenuItem onClick={() => AssetManagerState.copyFile(file.Key.replace(currentDir.Prefix, ''))}>Copy File</MenuItem>
                                                    <MenuItem onClick={() => AssetManagerState.cutFile(file.Key.replace(currentDir.Prefix, ''))}>Cut File</MenuItem>
                                                    {/* <MenuItem onClick={() => AssetManagerState.deleteFile(file.Key.replace(currentDir.Prefix, ''))}>Delete File</MenuItem> */}
                                                    <MenuItem onClick={() =>this.handleViewDependencies(file.Key.replace(currentDir.Prefix, ''))}>View Dependent</MenuItem>
                                                    <MenuItem onClick={() =>this.handleDelete(file.Key.replace(currentDir.Prefix, ''))}>Delete File</MenuItem>
                                                </ContextMenu>
                                            </Grid>
                                        }
                                    </>
                                ))}
                            </Grid>
                        )}
                    </Dropzone>
                    <DeleteForm />
                </React.Fragment>
        );
    }

}

export default view(FolderList);