/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { capitalizeFirstLetter } from '../../../lib/index';
import RecursiveTreeView from '../../../Views/RecursiveTreeView';

class ListOfDependencies extends Component {

  renderTreeView(obj, parent = "", treeView = {}, ids = {}) {
    Object.keys(obj).map((key, value) => {
      if (key !== 'Contents') {
        obj[key].map((prefix, index) => {
          let pieces = prefix.Prefix.split('/');
          let last = pieces[pieces.length - 2];
          let child = {
            id: 'parent_' + new Date().getMilliseconds() + Math.floor((Math.random() * 100) + 1),
            name: last,
            children: []
          };
          ids.push(child.id);
          eval("treeView" + parent + ".push(child)");
        });
      }
      else {

        obj[key].map((content, index) => {
          if (index !== 0) {
            let pieces = content.Key.split(/[s/]+/);
            let last = pieces[pieces.length - 1];
            let child = {
              id: 'parent_' + new Date().getMilliseconds() + Math.floor((Math.random() * 100) + 1),
              name: last
            };
            ids.push(child.id);
            eval("treeView" + parent + ".push(child)");
          }
        });
      }
    });

    return { treeView: treeView, ids: ids };

  }

  renderDraftAndLive = (item, type) => {
    console.log(typeof item, 'type--', type);
    if (item !== null) {
      if (typeof item === "string") {
        return this.checkElementType(item !== null && item !== "" ? item.toLowerCase() : "");
      } else if (typeof item !== "undefined" && item.length > 0) {
        return this.checkElementType(item.join().toLowerCase());
      }
    } else {
      return '-';
    }

  }

  checkElementType(string) {
    let elementTypeObj = [
      { key: 'pages', name: "FORM STEPS" },
      { key: 'css', name: "CSS" },
      { key: 'javascript', name: "JAVASCRIPT" },
      { key: 'js', name: "JAVASCRIPT" },
      { key: 'html', name: "HTML" },
    ];

    let tmpArray = [];

    elementTypeObj.map((item) => {
      if (string.indexOf(item.key) !== -1) {
        tmpArray.push(item.name);
      }
      return;
    });
    return tmpArray.join(', ');
  }

  render() {
    return (
      <>
        {this.props.viewType === "table" &&
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{capitalizeFirstLetter(this.props.dependenciesOf, "_", " ")} ID</TableCell>
                <TableCell>{capitalizeFirstLetter(this.props.dependenciesOf, "_", " ")} Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.dependencies.map((dependency, index) => (
                <TableRow key={index + 1}>
                  <TableCell>{dependency.id}</TableCell>
                  <TableCell>{dependency.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }

        {this.props.viewType === "tree" &&
          <RecursiveTreeView dependencies={this.renderTreeView(this.props.dependencies, '.children', { id: this.props.dependenciesOf, name: this.props.dependenciesOf, children: [] }, [this.props.dependenciesOf])} />
        }

        {this.props.viewType === "deletetable" &&
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{capitalizeFirstLetter(this.props.dependenciesOf, "_", " ")} ID</TableCell>
                <TableCell>{capitalizeFirstLetter(this.props.dependenciesOf, "_", " ")} Name</TableCell>
                <TableCell>Used In</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.dependencies.map((dependency, index) => (
                <TableRow key={index + 1}>
                  <TableCell>{dependency.id}</TableCell>
                  <TableCell>{dependency.name}</TableCell>
                  <TableCell>{this.renderDraftAndLive(dependency.draft_state_reference, this.props.dependenciesOf)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }

        {this.props.viewType === "deleteIndividualTable" &&
          <>
            <h4>
            Variable name is already used in&nbsp;
              {this.props.dependencies.map((dependency, index) => (
               <span className="highlight-delete-title">{this.renderDraftAndLive(dependency.draft_state_reference, this.props.dependenciesOf)}</span> 
              ))}
            </h4>
          </>

        }
      </>

    );
  }
}

export default ListOfDependencies;