import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Folder from '@material-ui/icons/Folder';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Refresh from '@material-ui/icons/Refresh';
import AssetManagerState from "../../../State/AssetManagerState";
import FolderList from "../../../Components/AssetManager/FolderList";
import FileList from "../../../Components/AssetManager/FileList";
import CreateFolder from "../../../Components/Dialogs/CreateFolder";
import UploadProgress from "../../../Components/Dialogs/UploadProgress";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import UIState from "../../../State/UIState";

class AssetManager extends Component{

    componentDidMount() {
        AssetManagerState.initSocket();
        AssetManagerState.isOpenUploadAssetsManageModal = true;
        UIState.setPageTitle('Asset Manager');
    }

    componentWillUnmount() {
        AssetManagerState.isOpenUploadAssetsManageModal = false;
    }

    render() {
        return (
            <>
                <CreateFolder />
                <UploadProgress />
                <ContextMenuTrigger id={`file-list-context-trigger`}>
                    <Grid container spacing={2} className="context-layout-width">
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{paddingLeft: 10, paddingTop: 7}}>
                                            <Button onClick={() => AssetManagerState.previousDirectory()}><ArrowLeft /></Button>
                                            <Button onClick={() => AssetManagerState.nextDirectory()}><ArrowRight /></Button>
                                            <Button onClick={() => AssetManagerState.refresh()}><Refresh /></Button>
                                            <Button><Folder onClick={() => AssetManagerState.createFolderOpen = true} /></Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Current Directory"
                                            value={AssetManagerState.getCurrentDirectory().Prefix ?? '/'}
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Folder />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="context-layout-width">
                        <Grid item xs={12}>
                            <Paper>
                                <FolderList folders={AssetManagerState.getCurrentDirectory().CommonPrefixes ?? []} />
                                <FileList files={AssetManagerState.getCurrentDirectory().Contents ?? []}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <ContextMenu id={`file-list-context-trigger`}>
                        <MenuItem onClick={() => AssetManagerState.refresh()}>Refresh</MenuItem>
                        {(AssetManagerState.contextAction === 'copy_file' || AssetManagerState.contextAction === 'cut_file') &&
                        <MenuItem onClick={() => AssetManagerState.paste(AssetManagerState.getCurrentDirectory().Prefix)}>Paste File</MenuItem>}
                        {(AssetManagerState.contextAction === 'copy_folder' || AssetManagerState.contextAction === 'cut_folder') &&
                        <MenuItem onClick={() => AssetManagerState.paste(AssetManagerState.getCurrentDirectory().Prefix)}>Paste Folder</MenuItem>}
                    </ContextMenu>
                </ContextMenuTrigger>
            </>
        );
    }

}

export default view(AssetManager);