import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

import FormPageEditorState from "../../State/FormPageEditorState";

class PublishForm extends Component {

    onClick = async () => {
        await FormPageEditorState.publishForm();
        FormPageEditorState.isLoading = false;
    }

    render() {
        return (
            <>
                {!FormPageEditorState.isLoading &&
                    <Button
                        onClick={() => this.onClick()}
                        color='primary'
                        style={{ float: 'right' }}
                    >Publish Form</Button>
                }
                {FormPageEditorState.isLoading &&
                    <Button
                        disabled={true}
                        color='primary'
                        style={{ float: 'right' }}
                    >Publish Form &nbsp; <CircularProgress size={18} /></Button>
                }
            </>
        );
    }

}

export default view(PublishForm);