import { store } from '@risingstack/react-easy-state';

const EmbedModalState = store({
    previewModeCode: null,
    liveModeCode: null,
    isVisible: false,
    message: null,
    embedType: null,
    iframeURL: "",
    showModal: (embedType, previewMode, liveMode, message, iframeURL = "") => {
        EmbedModalState.embedType = embedType;
        EmbedModalState.previewModeCode = previewMode;
        EmbedModalState.liveModeCode = liveMode;
        EmbedModalState.message = message;
        EmbedModalState.isVisible = true;
        EmbedModalState.iframeURL = iframeURL;
    },
    toggleModal: () => {
        EmbedModalState.isVisible = !EmbedModalState;
    },
    hideModel: () => {
        EmbedModalState.isVisible = false;
    }
});

export default EmbedModalState;