import { store } from '@risingstack/react-easy-state';

const CreateNewState = store({
    itemType: '',
    derivedField: '',
    isOpen: false,
    value: '',
    open: () => {
        CreateNewState.isOpen = true;
    },
    close: () => {
        CreateNewState.isOpen = false;
    },
    toggleOpen: () => {
        CreateNewState.isOpen = !CreateNewState.isOpen
    }
});

export default CreateNewState;