import { store } from '@risingstack/react-easy-state';
import axios from 'axios';
import hostname from "../lib/hostname";
import { getClientInitials } from "../lib";

const PrivateMetaState = store({
    data: {},
    emailData: {
        emails: [],
        emailLists: []
    },
    campaignList: [],
    clientData: {},
    getForClient: async (client) => {
        const apiHost = hostname.dashboardAPI();
        if (PrivateMetaState[client]) {
            return PrivateMetaState[client];
        } else {
            const { data } = await axios.get(`${apiHost}/clients/getClientConfigEmailData?client=${client}`);
            let dataPaymentSettings = await axios.get(`${apiHost}/clients/getClientConfigData?client=${client}&mode=live`);
            dataPaymentSettings = dataPaymentSettings.data.data['payment-settings'];
            // 
            data.data['payment-settings'].preview = Object.assign({}, dataPaymentSettings);
            data.data['payment-settings'].live = Object.assign({}, dataPaymentSettings);
            
            // Formatting payment-processors to split preview and live modes 
            data.data['payment-settings'].preview['payment-processors'] = [];  
            data.data['payment-settings'].live['payment-processors'] = [];
            // Get preview payment-processors
            let items = dataPaymentSettings['payment-processors'].filter(item => item.status.indexOf('test') !== -1);
            data.data['payment-settings'].preview['payment-processors'] = items;  
            // Get live payment-processors
            items = dataPaymentSettings['payment-processors'].filter(item => item.status.indexOf('live') !== -1);
            data.data['payment-settings'].live['payment-processors'] = items;  
            
            PrivateMetaState[client] = data;
            return PrivateMetaState[client];
        }
    },
    init: async (client = null, callback = null, forceInit = false) => {
        if (!client) {
            client = getClientInitials(client);
        }
        if (Object.keys(PrivateMetaState.data).length === 0 || forceInit) {
            try {
                const apiHost = hostname.creativeAPI();

                // TODO: Replace RKD with the current client
                const { data, clientEmails } = await PrivateMetaState.getForClient(client);

                PrivateMetaState.data = data;
                if (typeof clientEmails !== 'undefined') {
                    PrivateMetaState.emailData = clientEmails;
                }

                // fetch campaigns records by client name
                const resData = await axios.get(`${apiHost}/campaigns/getCampaigns/${client}`);
                if (typeof resData.data !== 'undefined' && resData.data.length > 0) {
                    PrivateMetaState.campaignList = resData.data;
                }
            } catch (e) {
                console.error('Failed to retrieve client private meta', e);
            }
        }

        if (typeof callback === 'function') {
            callback();
        }
    },
    reinit: (client = null, callback = null) => {
        PrivateMetaState.init(client, callback, true);
    }
});

export default PrivateMetaState;
