import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import CopyRuleState from "../../../State/Rule/CopyRuleState";
import RuleState from '../../../State/RuleState';
import ClientState from '../../../State/ClientState';

class CopyRule extends Component {

    state = {
        isError: false,
        toClient: 'none',
        toClientValidate: false
    }

    // formNameMaxLength = 49

    async handleSubmit() {
        let isRuleValidate = true;
        if (CopyRuleState.dynamicElementName.trim() === "") {
            isRuleValidate = false;
            this.setState({ isError: true });
        }
        
        if(this.state.toClient === 'none') {
            isRuleValidate = false;
            this.setState({ toClientValidate: true });
        }

        if(isRuleValidate) {
            this.setState({ isError: false, toClientValidate: false });
            CopyRuleState.targetClient = this.state.toClient;
            await RuleState.copyRule(CopyRuleState.sourceId, CopyRuleState.dynamicElementName, CopyRuleState.sourceName, this.state.toClient);
            this.setState({ toClient: ClientState.currentClient});
        }
    }

    handleClose() {
        this.setState({
            isError: false,
            toClient: "none",
            toClientValidate: false
        });
        CopyRuleState.modalOpen = false;
        CopyRuleState.targetClient = "";
        CopyRuleState.dynamicElementName = ""; 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === "name") {
            CopyRuleState.dynamicElementName = event.target.value;
            if(event.target.value.trim() === "") {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
            }
        } else {
            this.setState({ isError: false });
        }

        if(event.target.name === "toClient") {
            console.log('client id: ', event.target.value);
            if (event.target.value !== "none") {
                this.setState({ toClientValidate: false });
                RuleState.getDynamicElementName(CopyRuleState.sourceName, event.target.value);
            } else {
                this.setState({ toClientValidate: true });
            }
        } else {
            this.setState({ toClientValidate: false });
        }
    }

    render() {
        return (
            <>

                <Dialog onClose={() => this.handleClose()} open={CopyRuleState.modalOpen}>
                    <DialogTitle>Copy Rule To Another Client</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the rule a name to get started.</DialogContentText>                        
                        <React.Fragment>
                            <FormControl fullWidth error={ this.state.toClientValidate }>
                                <InputLabel id={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}>Client</InputLabel>
                                <Select 
                                    autoFocus
                                    fullWidth 
                                    labelId={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`} 
                                    id={`client-select${this.state.toClientValidate ? `-error` : ``}`} 
                                    name='toClient' 
                                    value={this.state.toClient} 
                                    onChange={(event) => this.handleChange(event)}
                                    >
                                        <MenuItem value="none" key="0">Please Select</MenuItem>
                                        {CopyRuleState.clientList.map((client, key) => (
                                            <MenuItem value={client} key={key+1}>{client}</MenuItem>
                                        ))}
                                </Select>
                                { this.state.toClientValidate && 
                                    <FormHelperText>Please Select Client</FormHelperText>
                                }
                            </FormControl>
                        </React.Fragment>
                        
                        <TextField
                            margin='dense'
                            label={"Rule Name"}
                            name='name'
                            type='text'
                            value={CopyRuleState.dynamicElementName}
                            onChange={(event) => this.handleChange(event)}
                            fullWidth
                            // inputProps={{ maxLength: this.formNameMaxLength }}
                            helperText={this.state.isError ? `Please Enter Rule Name` : ""}
                            error={this.state.isError}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!CopyRuleState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'>
                                Copy Rule
                        </Button>
                        }
                        {CopyRuleState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'
                                disabled={true}>
                                Copy Rule  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    RuleState.isDuplicateRule && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={RuleState.isDuplicateRule} autoHideDuration={10000}
                        onClose={() => RuleState.isDuplicateRule = false}>

                        <Alert variant="filled" severity="error" onClose={() => RuleState.isDuplicateRule = false}>
                            {RuleState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>

        );
    }

}

export default view(CopyRule);

