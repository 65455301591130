import React, {Component} from 'react';
import { view } from '@risingstack/react-easy-state';
import MaterialTextField from "@material-ui/core/TextField";
import {camelToTitle} from "../../../lib";

class NumberField extends Component{

    render(){
        const { value, defaultValue, onChange, label } = this.props;

        return (
            <MaterialTextField
                {...this.props}
                margin='dense'
                label={camelToTitle(label)}
                type='Number'
                fullWidth
                value={value || defaultValue}
                onChange={(event) => onChange(parseInt(event.target.value))}
            />
        );
    }

}

export default view(NumberField);