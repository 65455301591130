import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import AddTriggerState from "../../State/AddTriggerState";
import PageEditorState from "../../State/FormPageEditorState";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

class AddTrigger extends Component{

    handleClose = () => {
        AddTriggerState.isOpen = false;
    };

    handleSubmit = () => {
        PageEditorState.createTrigger(AddTriggerState.triggerName);
        AddTriggerState.triggerName = '';
        this.handleClose();
    };

    render(){
        return (
            <Dialog onClose={() => this.handleClose()} open={AddTriggerState.isOpen}>
                <DialogTitle>Create Trigger</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Trigger Name'
                        type='text'
                        value={AddTriggerState.triggerName}
                        onChange={(event) => AddTriggerState.triggerName = event.target.value}
                        fullWidth
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Create Trigger</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AddTrigger);