import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AddFieldState from "../../State/AddFieldState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditFormPageState from "../../State/EditFormPageState";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import FormPageEditorState from "../../State/FormPageEditorState";
import EditFieldState from "../../State/EditFieldState";
import AddCustomFieldState from "../../State/AddCustomFieldState";
import Divider from "@material-ui/core/Divider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteFormElement from "../Dialogs/Delete/DeleteFormElement";
import DeleteStateElement from "../../State/Common/DeleteStateElement";

class ThankYouPage extends Component{

    state = {
        random: 0
    }

    componentDidMount() {
        FormPageEditorState.rerender = () => this.setState({
            random: Math.random()
        }, () => this.forceUpdate());
    }

    // Delete Tribute Gift related fields.
    handleDelete = (modal, component) => {
        FormPageEditorState.deleteComponent(modal, component, 'thankYouPage');
        this.forceUpdate();
    };

    // Delete Thank Page Receipt fields related fields.
    handleDeleteThankYouPageField(index) {
        FormPageEditorState.deleteThankYouField(index);
    }

    handleEdit = (thankYouPage, component) => {
        EditFieldState.open(thankYouPage, component, 'thankYouPage');
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        border: isDragging ? '1px #000 solid' : 'none',
        background: isDragging ? '#FFF' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    getListStyle = isDraggingOver => ({

    });

    getComponentIndex() {
        const index = {};

        FormPageEditorState.pages.forEach(page => {
            page.components.forEach(component => {
                if(component.id === "thankYouPageCustomHTML")
                {
                    index[component.dataId] = component;
                }
                else
                {

                    index[component.id] = component;
                }
            });
        });

        FormPageEditorState.modals.forEach(page => {
            page.components.forEach(component => {
                index[component.id] = component;
            });
        });

        return index;
    }

    renderItem(field, fieldIndex, componentIndex, pageType) {
        const component = componentIndex[field];
        
        return (
            <Draggable key={fieldIndex} draggableId={`${this.props.index}:${fieldIndex}:thankYouPage:${pageType}`} index={fieldIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )
                        }>
                        {typeof component !== 'undefined' ?
                            <ListItem>
                                <ListItemText primary={component.customName || component.thankYouName || component.name} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => this.handleDeleteThankYouPageField(fieldIndex)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem> : null}
                    </div>
                )}
            </Draggable>
        );
    }

    render(){
        const thankYouPage = FormPageEditorState.thankYouPage[this.props.index];
        const tyComponentIndex = this.getComponentIndex();

        return(
            <>
                <Grid className='thankYouPageEditorCard w-35' item>
                    <Card variant='outlined'>
                        <CardContent>
                            <Typography variant='h6' color='textSecondary' gutterBottom>{this.props.name}</Typography>
                        </CardContent>
                        <Divider />
                        <CardContent>

                                { thankYouPage.pageType === 'readonly' &&
                                        <List dense={true}>
                                            <Droppable droppableId={`field_list:thankYouPage:${this.props.index}:${thankYouPage.pageType}`}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver)}>
                                                        {thankYouPage.components.map((field, fieldIndex) => this.renderItem(field, fieldIndex, tyComponentIndex, thankYouPage.pageType))}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </List>
                                }

                                { thankYouPage.pageType !== 'readonly' && 
                                    <List dense={true}>
                                        <Droppable droppableId={`field_list:thankYouPage:${this.props.index}:${thankYouPage.pageType}`}>

                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={this.getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {thankYouPage.components.map((component, componentIndex) => (
                                                        <Draggable key={componentIndex} draggableId={`${this.props.index}:${componentIndex}:thankYouPage:${thankYouPage.pageType}`}  index={componentIndex}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={this.getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )
                                                                }>
                                                                    <ListItem style={{paddingRight:"80px", backgroundColor: (component.id === "thankYouPageCustomHTML" ?  "#f9a306" : "")}}>
                                                                        <ListItemText primary={component.customName || component.name}/>
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => this.handleDelete(this.props.index, componentIndex, (component.customName || component.name))}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                            <IconButton edge="end" aria-label="edit" onClick={() => this.handleEdit(this.props.index, componentIndex)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </div>
                                            )}
                                        </Droppable>
                                    </List>
                                } 
                        </CardContent>
                        <Divider />
                            <CardActions>
                                <Button color='primary' size="small" onClick={() => thankYouPage.pageType === 'readonly' ? AddFieldState.openThankYou() : AddFieldState.open(this.props.index, 'thankYouPage')}>Add Field</Button>
                            </CardActions>
                    </Card>
                </Grid>
                <DeleteFormElement />
            </>
        );
    }

}

export default view(ThankYouPage);