import { store } from "@risingstack/react-easy-state";
import PageEditorState from "./FormPageEditorState";

const EditFormModalState = store({
    isOpen: false,
    value: '',
    modal: 0,
    open: (modal) => {
        EditFormModalState.modal = modal;
        EditFormModalState.value = PageEditorState.modals[modal].name;
        EditFormModalState.isOpen = true;
    },
    close: () => {
        EditFormModalState.isOpen = false;
    },
    toggleOpen: () => {
        EditFormModalState.isOpen = !EditFormModalState.isOpen
    }
});

export default EditFormModalState;