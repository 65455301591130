import { store } from "@risingstack/react-easy-state";
import FormListSocket from "../Sockets/FormListSocket";
import CreateNewState from "./CreateNewState";
import hostname from "../lib/hostname";
import { getClientInitials, spaceWithUnderscore } from "../lib";
import axios from 'axios';
import DeleteState from "../State/Common/DeleteState";
import ClientState from '../State/ClientState';
import CopyFormState from "./Form/CopyFormState";

const apiHost = hostname.creativeAPI();


const FormListState = store({
    socket: null,
    data: [],
    newFormId: 0,
    isDuplicateCreative: false,
    isLoading: false,
    errorMessage: null,
    isDynamicElementNameLoading: false,
    initSocket: () => {
        if (!FormListState.socket) {
            FormListSocket.init();
            FormListState.socket = FormListSocket.socket;
        } else {
            FormListState.socket.emit('requestUpdate');
        }
        FormListState.receiveUpdate();
    },
    reinitSocket: (client, callback = null) => {
        if (FormListState.socket) {
            FormListState.socket.close();
            FormListState.socket = null;
        }
        FormListSocket.socket = null;
        FormListState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    emitUpdate: async () => {
        try {
            FormListState.isLoading = true;
            // Generate the CREATIVE file to DMC
            const { data } = await axios.post(`${apiHost}/creatives/newCreative?name=${spaceWithUnderscore(CreateNewState.value)}&acronym=${getClientInitials(ClientState.currentClient)}&layout=FormBuilder&createdType=FormBuilder`);
            if (data.status !== "error") {
                FormListState.socket.emit('create', {
                    name: CreateNewState.value
                });
                FormListState.isLoading = false;
            } else {
                FormListState.isDuplicateCreative = true;
                FormListState.errorMessage = 'Form Creation Failed! The form name is already in use.'
                FormListState.isLoading = false;
            }
        } catch (e) {
            console.error('Failed to create new creative', e);
            FormListState.isLoading = false;
            FormListState.errorMessage = e.message !== undefined ? e.message : "Failed to create new creative";
        }
    },
    copyForm: async (sourceId, targetName, sourceName, targetClient) => {
        try {

            FormListState.isLoading = true;
            let params = {
                current: spaceWithUnderscore(sourceName),
                new: spaceWithUnderscore(targetName),
                client: getClientInitials(ClientState.currentClient),
                targetClient: targetClient
            }

            const { data } = await axios.post(`${apiHost}/creatives/copyCreative`, null, { params: params });

            if (data.status.toLowerCase() !== "error") {
                FormListState.socket.emit('copy', {
                    sourceId,
                    targetName,
                    targetClient
                });
            } else {
                FormListState.isDuplicateCreative = true;
                FormListState.errorMessage = `Copy Failed! The form name is already in use ${CopyFormState.toClient ? `for ${CopyFormState.targetClient} client` : ``}.`;
                FormListState.isLoading = false;
            }
        } catch (e) {
            console.error('Failed to copy creative', e);
            FormListState.isLoading = false;
            FormListState.errorMessage = e.message !== undefined ? e.message : "Copy Failed!";
        }

    },
    deleteForm: async (sourceId, formName) => {

        try {

            DeleteState.isLoading = true;
            let params = {
                current: spaceWithUnderscore(formName),
                client: getClientInitials(ClientState.currentClient)
            }

            const { data } = await axios.delete(`${apiHost}/creatives/deleteCreative`, { params: params });

            if (data.status !== "error" || data.message.includes("The specified key does not exist.")) {
                FormListState.socket.emit('delete', { sourceId, formName });
                DeleteState.isLoading = false;
            } else {
                DeleteState.isLoading = false;
                FormListState.errorMessage = `Delete Failed! ${data.message !== undefined ? data.message : "Something went wrong"}`;
            }
        } catch (e) {
            console.error('Failed to Delete creative', e);
            DeleteState.isLoading = false;
            FormListState.errorMessage = `Delete Failed! ${e.message !== undefined ? e.message : "Something went wrong"}`;
        }
    },
    receiveUpdate: () => {
        FormListState.socket.on('serverUpdate', (data) => {
            FormListState.data = data;
        });
        FormListState.socket.on('createAccept', async (resData) => {
        
            // Update Form Builder Form Id into Creative File
            if (FormListState.newFormId !== resData.id) {
                FormListState.isLoading = false;
                CopyFormState.modalOpen = false;
                CopyFormState.dynamicElementName = "";
                FormListState.newFormId = resData.id;
                let postData = {};
                postData.formBuilderId = FormListState.newFormId;

                let env = "production";
                if(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)) {
                    env = "localhost"
                }
                else {
                    if(process.env.REACT_APP_STAGE === "true" || process.env.REACT_APP_STAGE === true) {
                        env = "stage";
                    }
                }
            
                let liveState = JSON.parse(resData.live_state);
                postData.environment = env;
                
                // Here we're setting google reCaptch object value as "on" or "off" from the value of liveState google recaptch settings.
                postData.googleRecaptcha = liveState.recaptcha === true ? "on" : "off";
                
                let formData = new FormData();
                formData.append('name', spaceWithUnderscore(resData.name));
                formData.append('acronym', getClientInitials(CopyFormState.toClient ? CopyFormState.targetClient : ClientState.currentClient));
                formData.append('createdType', "FormBuilder");
                formData.append('data', JSON.stringify(postData));
                let url = `${apiHost}/creatives/updateCreativeFromFormBuilder`;
                const { data } = await axios.post(url, formData);
                if (data.status !== "success") {
                    FormListState.errorMessage = data.message !== undefined ? data.message : "Failed to update new creative";
                } else {
                    if(CopyFormState.toClient) {
                        window.localStorage.setItem(ClientState.clientContextVariable, CopyFormState.targetClient);
                        window.location = '/';
                    }
                }
            }

            
        });
        FormListState.socket.on('getDynamicElementName', (data) => {
            if(!data.isDuplicate) {
                CopyFormState.dynamicElementName = data.dynamicElementName
            }
            FormListState.isDynamicElementNameLoading = false;
        });
    },
    getDynamicElementName: (sourceName, targetClient) =>{
        FormListState.isDynamicElementNameLoading = true;
        FormListState.socket.emit('getDynamicElementName', {
            sourceName,
            targetClient
        });
    }
});

export default FormListState;