import React, { Component } from 'react';
import {view} from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import SocketDisconnectedModalState from "../../State/SocketDisconnectedModalState";


class SocketDisconnected extends Component{

    render(){
        return (
            <>
                <Dialog open={SocketDisconnectedModalState.showModal}>
                    <DialogTitle>Are you still there?</DialogTitle>
                    <DialogContent>
                        You have been away for quite some time.<br /><br />
                        Please click "I'm here" below to keep working.
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => window.location.reload()} color='primary'>I'm here</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default view(SocketDisconnected);
