import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import mime from "mime";
import UnknownFile from "@material-ui/icons/Help";
import CodeFile from "@material-ui/icons/Code";
import PdfFile from "@material-ui/icons/PictureAsPdf";
import Avatar from '@material-ui/core/Avatar';
import AssetManagerState from "../../State/AssetManagerState";

class FileIcon extends Component{

    render() {
        const { filename } = this.props;
        console.log(filename);
        const mimeType = mime.getType(filename);
        console.log(mimeType);
        if(mimeType === null) {
            return (<UnknownFile />);
        } else if(mimeType.includes('html') || mimeType.includes('css') || mimeType.includes('javascript')){
            return (<CodeFile />);
        } else if(mimeType.includes('pdf')){
            return (<PdfFile />);
        } else if(mimeType.includes('image')){
            return (<Avatar alt="Remy Sharp" src={AssetManagerState.baseUrl + filename} />);
        } else {
            return (<UnknownFile />);
        }
    };

}

export default view(FileIcon);