import { store } from '@risingstack/react-easy-state';

const DeleteState = store({
    sourceId: null,
    assetName: null,
    modalOpen: false,
    formName: null,
    type: null,
    isLoading: false,
    toggleOpen() {
        DeleteState.modalOpen = !DeleteState.modalOpen;
    }
});

export default DeleteState;