import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import UIState from "../../../State/UIState";
import CreateNewState from "../../../State/CreateNewState";
import EditCustomFieldState from "../../../State/EditCustomFieldState";
import Paper from "@material-ui/core/Paper";
import FieldEditor from "../../../Components/Editors/FieldEditor";
import Button from "@material-ui/core/Button";
import TextField from "../../../Components/Editors/FieldTypes/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";


class EditCustomField extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        EditCustomFieldState.setCustomField(params.id);
        UIState.setPageTitle('Edit Custom Field');
        CreateNewState.itemType = 'Custom Field';
        EditCustomFieldState.isError = false;
        EditCustomFieldState.errorMessge = '';
    }

    componentWillUnmount() {
        EditCustomFieldState.socket.close();
        EditCustomFieldState.socket = null;
    }

    handleSubmit() {
        EditCustomFieldState.save();
    }

    render() {
        const { history } = this.props;
        setTimeout(() => {
            if (EditCustomFieldState.fieldName === "") {
                history.push('/customFields');
            }
        }, 500);
        return (
            <Paper style={{ padding: 15, margin: 20, width: 600 }}>
                <Typography variant='h6' gutterBottom>Custom Field: {EditCustomFieldState.fieldName}</Typography>
                {!EditCustomFieldState.data.custom &&
                    <TextField
                        label='Field Name'
                        value={EditCustomFieldState.fieldName}
                        defaultValue={EditCustomFieldState.fieldName}
                        onChange={(value) => EditCustomFieldState.fieldName = value}
                    />
                }

                {EditCustomFieldState.data.options && <FieldEditor options={EditCustomFieldState.data.options} custom={EditCustomFieldState.data.custom} />}
                <div style={{ textAlign: 'right', marginTop: 5 }}>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Save
                        {EditCustomFieldState.isLoading && (<>&nbsp; <CircularProgress size={18} /></>)}
                    </Button>
                </div>
            </Paper>
        )
    }

}

export default view(EditCustomField);