import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import Grid from "@material-ui/core/Grid";
import TriggerNew from "./Trigger";

class TriggerListNew extends Component{

    render(){
        const {triggers} = this.props;

        return (<Grid container spacing={2}>
            {triggers.map((trigger, index) => <TriggerNew key={index} index={index} {...trigger} />)}
        </Grid>);
    }

}

export default view(TriggerListNew);