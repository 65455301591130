import Ws from '@adonisjs/websocket-client';
import SocketDisconnectedModalState from "../State/SocketDisconnectedModalState";

const BaseSocket = {
    isConnected: false,
    socket: null,
    init: () => {
        let protocol = 'wss';
        if(process.env.NODE_ENV === 'development'){
            protocol = 'ws';
        }

        BaseSocket.socket = Ws(protocol+'://'+process.env.REACT_APP_API_HOST, {
            path: 'ws'
        }).connect();
        BaseSocket.socket.on('open', () => {
            BaseSocket.isConnected = true;
        });

        BaseSocket.socket.on('close', () => {
            SocketDisconnectedModalState.showModal = true;
            BaseSocket.isConnected = false;
        });
    }

};

export default BaseSocket;
