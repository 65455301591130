import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ValidationFieldState from "../../State/ValidationFieldState";
import FormPageEditorState from "../../State/FormPageEditorState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


class ValidationDialog extends Component {

    handleClose = () => {
        ValidationFieldState.close();
    };

    render() {
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={ValidationFieldState.isOpen}>
                    <DialogTitle>Publish Error</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <h4>The following fields are required before a form can be published:</h4>
                        <List>
                            {FormPageEditorState.missingFields.map((item, index) => (
                                <ListItem>
                                    <ListItemText key={index} primary={item.name} />
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}

export default withRouter(view(ValidationDialog));