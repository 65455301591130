import { store } from "@risingstack/react-easy-state";
import ComponentListSocket from "../Sockets/ComponentListSocket";
import CreateNewState from "./CreateNewState";
import CopyComponentState from "../State/Component/CopyComponentState";
import ClientState from "../State/ClientState";

const ComponentListState = store({
    socket: null,
    data: [],
    newFormId: 0,
    isLoading: false,
    isDuplicateComponent: false,
    errorMessage: null,
    isDynamicElementNameLoading: false,
    initSocket: () => {
        if (!ComponentListState.socket) {
            ComponentListSocket.init();
            ComponentListState.socket = ComponentListSocket.socket;
        } else {
            ComponentListState.socket.emit('requestUpdate');
        }
        ComponentListState.receiveUpdate();
    },
    reinitSocket: (client, callback = null) => {
        if (ComponentListState.socket) {
            ComponentListState.socket.close();
            ComponentListState.socket = null;
        }
        ComponentListSocket.socket = null;
        ComponentListState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    emitUpdate: () => {
        ComponentListState.socket.emit('create', {
            name: CreateNewState.value
        });
    },
    copyComponent: (sourceId, targetName, targetClient) => {
        ComponentListState.isLoading = true;
        ComponentListState.socket.emit('copy', {
            sourceId,
            targetName,
            targetClient
        });
    },
    deleteComponent: (sourceId) => {
        ComponentListState.socket.emit('delete', { sourceId });
    },
    receiveUpdate: () => {
        ComponentListState.socket.on('serverUpdate', (data) => {
            if (data) {
                ComponentListState.data = data;
            }
        });
        ComponentListState.socket.on('createAccept', (data) => {
            ComponentListState.isLoading = false;
            CopyComponentState.modalOpen = false;
            CopyComponentState.dynamicElementName = "";
            if(CopyComponentState.toClient) {
                window.localStorage.setItem(ClientState.clientContextVariable, CopyComponentState.targetClient);
                window.location = '/components';
            } else {
                ComponentListState.newFormId = data.id;
            }
        });
        ComponentListState.socket.on('createDecline', (data) => {
            ComponentListState.isDuplicateComponent = true;
            ComponentListState.errorMessage = `Copy Failed! The component name is already in use ${CopyComponentState.toClient ? `for ${CopyComponentState.targetClient} client` : ``}.`;
            ComponentListState.isLoading = false;
        });
        ComponentListState.socket.on('getDynamicElementName', (data) => {
            if(!data.isDuplicate) {
                CopyComponentState.dynamicElementName = data.dynamicElementName
            }
            ComponentListState.isDynamicElementNameLoading = false;
        });
    },
    getDynamicElementName: (sourceName, targetClient) =>{
        ComponentListState.isDynamicElementNameLoading = true;
        ComponentListState.socket.emit('getDynamicElementName', {
            sourceName,
            targetClient
        });
    }
});

export default ComponentListState;