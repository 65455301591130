import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import AceEditor from "react-ace";
import FormPageEditorState from "../../State/FormPageEditorState";

import 'ace-builds/src-min-noconflict/mode-javascript'
import 'ace-builds/src-min-noconflict/theme-tomorrow'
import ComponentEditorState from "../../State/ComponentEditorState";
import PageEditorState from "../../State/PageEditorState";

class JSEditor extends Component{

    onChange = (value) => {
        if(this.props.editing === 'page'){
            PageEditorState.js = value;
        } else  if(this.props.editing === 'component'){
            ComponentEditorState.js = value;
        } else {
            FormPageEditorState.customJS = value;
        }
    };

    render() {
        let value;
        if(this.props.editing === 'page'){
            value = PageEditorState.js;
        } else  if(this.props.editing === 'component'){
            value = ComponentEditorState.js;
        } else {
            value = FormPageEditorState.customJS;
        }

        return (
            <AceEditor
                placeholder="Insert Custom Javascript Here"
                style={{width: '100%'}}
                mode="javascript"
                theme="tomorrow"
                name="jsEditor"
                onChange={this.onChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={value}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}/>
        );
    }

}

export default view(JSEditor);