import { store } from '@risingstack/react-easy-state';

const CopyFormState = store({
    sourceId: null,
    modalOpen: false,
    sourceName: null,
    toClient: false,
    targetClient: "",
    dynamicElementName: "",
    toggleOpen() {
        CopyFormState.modalOpen = !CopyFormState.modalOpen;
    }
});

export default CopyFormState;