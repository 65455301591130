import { store } from "@risingstack/react-easy-state";

const AddCustomFieldState = store({
    isOpen: false,
    value: 0,
    object: 0,
    objectType: 'page',
    open: (object, objectType = 'page') => {
        AddCustomFieldState.object = object;
        AddCustomFieldState.objectType = objectType;
        AddCustomFieldState.isOpen = true;
    },
    close: () => {
        AddCustomFieldState.isOpen = false;
    },
    toggleOpen: () => {
        AddCustomFieldState.isOpen = !AddCustomFieldState.isOpen
    }
});

export default AddCustomFieldState;