import axios from 'axios'
import { Base64 } from 'js-base64';
import auth0 from 'auth0-js';
import ClientState from "../State/ClientState";

const apiAccessTokenKey = 'api_access_token';
const idTokenKey = 'id_token';
const accessTokenKey = 'access_token';

export async function validateJWT(jwt){
    try{
        let protocol = 'https';
        if(process.env.NODE_ENV === 'development'){
            protocol = 'http';
        }
        const response = await axios.get(`${protocol}://${process.env.REACT_APP_API_HOST}/auth/validateToken?jwt=${jwt}`);
        const responseData = response.data;
        return responseData.valid;
    } catch (e){
        console.error(e);
        return false;
    }
}

export async function validateCurrentSession(){
    const apiAccessToken = window.localStorage.getItem(apiAccessTokenKey);
    const idToken = window.localStorage.getItem(idTokenKey);
    const accessToken = window.localStorage.getItem(apiAccessTokenKey)
    if(apiAccessToken && idToken && accessToken){
        return await validateSessionData(apiAccessToken, idToken);
    }
    return false;
}

export async function validateSessionData(apiAccessToken, idToken){
    const apiAccessTokenValid = await validateJWT(apiAccessToken);
    if(apiAccessTokenValid){
        return await validateJWT(idToken);
    }else{
        return false;
    }
}

export async function startSession(apiAccessToken, idToken, accessToken){
    const isValid = await validateSessionData(apiAccessToken, idToken);
    if(isValid){
        window.localStorage.setItem(apiAccessTokenKey, apiAccessToken);
        window.localStorage.setItem(idTokenKey, idToken);
        window.localStorage.setItem(accessTokenKey, accessToken);
        await ClientState.init();
        return true;
    }
    return false;
}

export function getUserInfo(){
    const idToken = window.localStorage.getItem(idTokenKey);
    if(idToken){
        const parts = idToken.split('.');
        return JSON.parse(Base64.decode(parts[1]));
    }
    return undefined;
}

export async function isAuthorized(){
    const sessionValid = await validateCurrentSession();
    if(sessionValid){
        await ClientState.init();
    }
    return sessionValid;
}

export function getIdToken(){
    return window.localStorage.getItem(idTokenKey);
}

export function getAuth0Config(){
    let usersAPI;

    if(process.env.NODE_ENV === 'development'){
        usersAPI = 'https://mghumdzs81.execute-api.us-east-1.amazonaws.com/staging/user/';
    } else if([true, 'true'].includes(process.env.REACT_APP_STAGE)){
        usersAPI = 'https://mghumdzs81.execute-api.us-east-1.amazonaws.com/staging/user/'
    } else {
        usersAPI = 'https://3g2lc2qtt2.execute-api.us-east-1.amazonaws.com/production/user/';
    }

    return {
        domain: 'rkd.auth0.com',
        clientId: 'UAj2sUL8WXd8tGSwSgxqjt8hmS1RxM9x',
        usersAPI,
        options: {
            theme: {
                logo: 'https://public.charitable.one/assets/branding/one-logo.png',
                primaryColor: '#f44336'
            }
        },
        callback: `${window.location.protocol}//${window.location.host}/`
    };
}

export function getAuth0Object(){
    const auth0Config = getAuth0Config();
    return new auth0.WebAuth({
        domain: auth0Config.domain,
        clientID: auth0Config.clientId,
        redirectUri: auth0Config.callback,
        audience: `https://${auth0Config.domain}/userinfo`,
        responseType: 'token id_token',
        scope: 'openid profile email role'
    });
}

export function performLogout(){
    window.localStorage.clear();
    window.location.reload();
}