import React, { Component } from 'react';
import { view } from "@risingstack/react-easy-state";
import TextField from "./FieldTypes/TextField";
import ArrayField from "./FieldTypes/ArrayField";
import NumberField from "./FieldTypes/NumberField";
import HiddenField from "./FieldTypes/HiddenField";
import TemplateSize from "./FieldTypes/TemplateSize";
import TemplateOffset from "./FieldTypes/TemplateOffset";
import EnumField from "./FieldTypes/EnumField";
import TriggerField from "./FieldTypes/TriggerField";
import HTMLField from "./FieldTypes/HTMLField";
import ValidatorField from "./FieldTypes/ValidatorField";
import  AmountValidatorField from "./FieldTypes/AmountValidatorField";
import EditCustomFieldState from "../../State/EditCustomFieldState";
import LIDEANAField from "./FieldTypes/LIDEANAField";
class FieldEditor extends Component {

    render() {
        let { options, custom, isdisabled, savedOptions } = this.props;
        let paymentUiImageFields = ["credit_card", "paypal", "apple_pay"];
        let recurringGiftOptions = ["showOneTimeOption", "showWeeklyOption", "showMonthlyOption", "showQuarterlyOption", "showAnnuallyOption"];
        if (!savedOptions) {
            savedOptions = options;
        }
        var numRecurringGiftNoValues = savedOptions.reduce(function (n = 0, option) {
            return n + (recurringGiftOptions.includes(option.field) && option.value === 'No');
        }, 0);
        return (
            <>
                {options.map((option, index) => {
                    let savedOption = null;
                    console.log('Custom Field', custom);
                    savedOptions.forEach(savedOptionCheck => { // Map the current option to the saved option
                        if (option.field === savedOptionCheck.field) {
                            savedOption = savedOptionCheck;
                        }

                        if(savedOptionCheck.field === "displayType" && paymentUiImageFields.includes(option.field))
                        {
                            option.type= savedOptionCheck.value === "Image" ? "text" : "hidden";
                        }
                        
                    });

                    if (savedOption === null) { // New options could be created after the field was added.
                        const newSavedOption = {
                            field: option.field
                        };
                        if (option.default) {
                            newSavedOption.value = option.default;
                        }
                        if (option.value) {
                            newSavedOption.value = option.value;
                        }
                        if (option.values) {
                            newSavedOption.values = option.values;
                        }
                        savedOptions.push(newSavedOption);
                        savedOption = savedOptions[savedOptions.length - 1];
                    }

                    return (<div key={index}>
                        {option.type === 'text' && (option.field !== 'name' || (option.field === 'name' && !custom)) && <TextField
                            label={(typeof option.title !== "undefined" && option.title !== "") ? option.title : option.field}
                            value={savedOption.value || (savedOption.value === '' ? '' : option.default)}
                            defaultValue={''}
                            onChange={(value) => {
                                if(option.field === "percentage"){
                                    savedOption.value = value >= 0 ? value : savedOption.value;
                                }else{
                                    savedOption.value = value;
                                }
                            }}
                            helperText={option.helperText || (option.helperText === '' ? '' : option.helperText)}
                        />}
                        {option.type === 'text' && option.field === 'name' && custom && <TextField
                            label={option.field}
                            value={savedOption.value || (savedOption.value === '' ? '' : option.default)}
                            defaultValue={''}
                            isdisabled={isdisabled ? true : false}
                            helperText={EditCustomFieldState.isError ? EditCustomFieldState.errorMessge : ""}
                            error={EditCustomFieldState.isError}
                            onChange={(value) => {
                                savedOption.value = value;
                                (value.trim() === "") ? EditCustomFieldState.isError = true : EditCustomFieldState.isError = false;
                            }}
                        />}
                        {option.type === 'number' && <NumberField
                            label={option.field}
                            value={savedOption.value || (savedOption.value === '' ? '' : option.default)}
                            defaultValue={''}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'array' && <ArrayField
                            savedOption={savedOption}
                            option={option}
                        />}
                        {option.type === 'enum' && <EnumField
                            value={savedOption.value || (savedOption.value === '' ? '' : option.default)}
                            label={(typeof option.title !== "undefined" && option.title !== "") ? option.title : option.field}
                            options={option.options}
                            recurringGiftOptions={recurringGiftOptions}
                            numRecurringGiftNoValues={numRecurringGiftNoValues}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'hidden' && <HiddenField
                            value={savedOption.value || (savedOption.value === '' ? '' : option.default)}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'template_size' && <TemplateSize
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'template_offset' && <TemplateOffset
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'triggers' && <TriggerField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'validators' && <ValidatorField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'amountValidators' && <AmountValidatorField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'HTML' && <HTMLField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'LIDENEAtriggers' && <LIDEANAField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            value1={option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                        {option.type === 'LIDENEAmodals' && <LIDEANAField
                            label={option.field}
                            option={option}
                            value={savedOption.value || option.default}
                            onChange={(value) => savedOption.value = value}
                        />}
                    </div>)
                })}
            </>
        );
    }

}

export default view(FieldEditor);
