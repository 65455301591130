import {store} from '@risingstack/react-easy-state';

const CopyComponentState = store({
    sourceId: null,
    sourceName: null,
    modalOpen: false,
    toClient: false,
    targetClient: "",
    dynamicElementName: "",
    toggleOpen() {
        CopyComponentState.modalOpen = !CopyComponentState.modalOpen;
    }
});

export default CopyComponentState;