import {store} from '@risingstack/react-easy-state';

const CopyPageState = store({
    sourceId: null,
    sourceName: null,
    modalOpen: false,
    toClient: false,
    targetClient: "",
    dynamicElementName: "",
    toggleOpen() {
        CopyPageState.modalOpen = !CopyPageState.modalOpen;
    }
});

export default CopyPageState;