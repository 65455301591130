import React, {Component} from 'react';
import {view} from "@risingstack/react-easy-state";
import AddRuleState from "../../State/AddRuleState";
import GlobalSettingsState from "../../State/GlobalSettingsState";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CreateNewState from '../../State/CreateNewState';
import RuleState from '../../State/RuleState';

class AddRule extends Component{

    state = {
        isError: false
    }

    handleClose = () => {
        AddRuleState.close();
        AddRuleState.ruleName = '';
        AddRuleState.ruleJson = {expression: "", action: { }}
        this.setState({ isError: false });  
    };

    handleSubmit = async() => {
        if(AddRuleState.ruleName.trim() === "") {
            this.setState({ isError: true });
        } else {
            await RuleState.emitUpdate();
            this.handleClose();        
        }
    };

    render(){
        let errorMessage = this.state.isError ? "Please Enter Rule Name" : "";
        return (
            <Dialog onClose={() => this.handleClose()} open={AddRuleState.isOpen}>
                <DialogTitle>Create Rule</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Rule Name'
                        type='text'
                        value={AddRuleState.ruleName}
                        onChange={(event) => AddRuleState.ruleName = event.target.value}
                        fullWidth
                        id="standard-error-helper-text"
                        helperText={errorMessage}
                        error={this.state.isError ? true : false}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Create Rule</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(AddRule);