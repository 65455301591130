export const rulesSettings = {
    returningDonor: {
        "title": "Returning Donor",
        "conditionValueType": "select",
        "conditionValue": [
            {name: 'true', label: 'True'},
            {name: 'false', label: 'False'}
        ],
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="}
        ]
    },
    giftCount: {
        "title": "Donation Count",
        "conditionValueType": "text",
        "conditionValue": "",
        "placeholder": "Donation Count Value",
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="},
            {name: ">", label: ">"},
            {name: "<", label: "<"},
            {name: ">=", label: ">="},
            {name: "<=", label: "<="},
        ],
        inputType: 'number'
    },
    operatingSystem: {
        "title": "Operating System",
        "conditionValueType": "select",
        "conditionValue": [
            {name:"Linux", label: "Linux"},
            {name:"Windows", label: "Windows"},
            {name:"MacOS", label: "MacOS"}
        ],
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="}
        ]
    },
    avgGiftAmt: {
        "title": "Average Gift",
        "conditionValueType": "text",
        "conditionValue": "",
        "placeholder": "Average Gift Value",
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="},
            {name: ">", label: ">"},
            {name: "<", label: "<"},
            {name: ">=", label: ">="},
            {name: "<=", label: "<="},
        ],
        inputType: "text"
    },
    maxGiftAmt: {
        "title": "Maximum Gift",
        "conditionValueType": "text",
        "conditionValue": "",
        "placeholder": "Maximum Gift Value",
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="},
            {name: ">", label: ">"},
            {name: "<", label: "<"},
            {name: ">=", label: ">="},
            {name: "<=", label: "<="},
        ],
        inputType: 'text'
    },
    pageViews: {
        "title": "Number Of Visits",
        "conditionValueType": "text",
        "conditionValue": "",
        "placeholder": "Number Of Visits",
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="},
            {name: ">", label: ">"},
            {name: "<", label: "<"},
            {name: ">=", label: ">="},
            {name: "<=", label: "<="},
        ],
        inputType: 'text'
    },
    twzi: {
        "title": "Top Wealth Zipcode",
        "conditionValueType": "select",
        "conditionValue": [
            {name: 'true', label: 'True'},
            {name: 'false', label: 'False'}
        ],
        "operators": [
            {name: "=", label: "="},
            {name: "!=", label: "!="}
        ]
    }
};

export const valueTypesList = [
    {id: "percent", value: "Percent", type: 'number'},
    {id: "array", value: "Array", type: 'text'},
]