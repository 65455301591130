import React, {Component} from 'react';
import { view } from '@risingstack/react-easy-state';

class HiddenField extends Component{

    render(){
        const { value, defaultValue, onChange } = this.props;

        return (
            <input type={'hidden'}
                value={value || defaultValue}
                onChange={(event) => onChange(event.target.value)}
            />
        );
    }

}

export default view(HiddenField);