import { store } from '@risingstack/react-easy-state';

const DeleteStateElement = store({
    page: null,
    component: null,
    type: null,
    elementName: null,
    isLoading: false,
    isDeleteModal: true,
    toggleOpen() {
        DeleteStateElement.modalOpen = !DeleteStateElement.modalOpen;
    }
});

export default DeleteStateElement;