import React, {Component} from 'react';
import {view} from '@risingstack/react-easy-state';
import AssetManagerRoutes from "./AssetManager/Routes";
import ComponentRoutes from "./Component/Routes";
import CustomFieldRoutes from "./CustomField/Routes";
import FormRoutes from "./Form/Routes";
import PageRoutes from "./Page/Routes";
import GlobalRoutes from "./Global/Routes";

class Routes extends Component{

    render(){
        return (
            <>
                <FormRoutes />
                <ComponentRoutes />
                <PageRoutes />
                <AssetManagerRoutes />
                <CustomFieldRoutes />
                <GlobalRoutes />
            </>
        );
    }
}

export default view(Routes);