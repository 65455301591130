import React from 'react';
import {view} from '@risingstack/react-easy-state'
import {Route} from "react-router-dom";
import FormList from "./FormList";
import EditForm from "./EditForm";

export default view(function() {
    return (
        <>
            <Route exact path='/' component={FormList} />
            <Route path='/form/:id' component={EditForm} />
        </>
    );
});