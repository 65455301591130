import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AssetManagerState from "../../State/AssetManagerState";
import Divider from "@material-ui/core/Divider";

class CreateFolder extends Component{

    handleClose = () => {
        AssetManagerState.newFolderName = '';
        AssetManagerState.createFolderOpen = false;
    };

    handleSubmit = () => {
        AssetManagerState.createDirectory(AssetManagerState.newFolderName);
        this.handleClose();
    };

    render(){
        return (
            <Dialog onClose={() => this.handleClose()} open={AssetManagerState.createFolderOpen}>
                <DialogTitle>Create Folder</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>Folder Name</DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Folder Name'
                        type='text'
                        value={AssetManagerState.newFolderName}
                        onChange={(event) => AssetManagerState.newFolderName = event.target.value}
                        fullWidth
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    <Button onClick={() => this.handleSubmit()} color='primary'>Create Folder</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default view(CreateFolder);