import { store } from "@risingstack/react-easy-state";
import PageEditorState from "./FormPageEditorState";

const EditFormPageState = store({
    isOpen: false,
    value: '',
    page: 0,
    pageType: 'section',
    open: (page) => {
        EditFormPageState.page = page;
        EditFormPageState.value = PageEditorState.pages[page].name;
        EditFormPageState.isOpen = true;
        EditFormPageState.pageType = typeof PageEditorState.pages[page].pageType !== "undefined" ? PageEditorState.pages[page].pageType : '';
    },
    close: () => {
        EditFormPageState.isOpen = false;
    },
    toggleOpen: () => {
        EditFormPageState.isOpen = !EditFormPageState.isOpen
    }
});

export default EditFormPageState;