import { store } from '@risingstack/react-easy-state';
import ComponentSocket from "../Sockets/ComponentSocket";
import NotificationState from "./NotificationState";
import { manageVariableArray } from '../lib';

const ComponentEditorState = store({
    componentId: 0,
    html: '',
    css: '',
    js: '',
    socket: null,
    variables: [],
    name: '',
    versions: {},
    setComponent: (componentId) => {
        ComponentEditorState.componentId = componentId;
        ComponentEditorState.initSocket(componentId);
        ComponentEditorState.receiveUpdate();
    },
    initSocket: (componentId) => {
        if (componentId && !ComponentEditorState.socket) {
            ComponentSocket.init(componentId);
            ComponentEditorState.socket = ComponentSocket.socket;
        }
    },
    publishComponent: async () => {
        await ComponentEditorState.socket.emit('publishComponent');
        NotificationState.open('The component has been successfully published!', 'Component Published');
    },
    revertComponent: async () => {
        await ComponentEditorState.socket.emit('revertComponent');
        NotificationState.open('The component has been successfully reverted!', 'Component Reverted');
    },
    emitUpdate: () => {
        ComponentEditorState.variables = manageVariableArray(ComponentEditorState.variables);
        ComponentEditorState.socket.emit('clientUpdate', {
            html: ComponentEditorState.html,
            css: ComponentEditorState.css,
            javascript: ComponentEditorState.js,
            variables: ComponentEditorState.variables,
            versions: ComponentEditorState.versions,
        });
    },
    receiveUpdate: () => {
        ComponentEditorState.socket.on('serverUpdate', (data) => {
            ComponentEditorState.html = data.html;
            ComponentEditorState.css = data.css;
            ComponentEditorState.js = data.javascript;
            ComponentEditorState.variables = data.variables || [];
            ComponentEditorState.name = data.name;
            ComponentEditorState.versions =  data.versions || null;
        });
    },
    addVariable: () => {
        ComponentEditorState.variables.push({
            key: '',
            value: '',
            id: 'new'
        });
    },
    removeVariable: (index) => {
        if (ComponentEditorState.variables.length < 2) {
            ComponentEditorState.variables = [];
        } else {
            const newArray = ComponentEditorState.variables.slice(0, index);
            ComponentEditorState.variables.slice(index + 1).forEach((item) => {
                newArray.push(item);
            });
            ComponentEditorState.variables = newArray;
        }
    },
});

export default ComponentEditorState;