import { store } from "@risingstack/react-easy-state";
import PageListSocket from "../Sockets/PageListSocket";
import CreateNewState from "./CreateNewState";
import CopyPageState from "../State/Page/CopyPageState";
import ClientState from '../State/ClientState';

const PageListState = store({
    socket: null,
    data: [],
    newFormId: 0,
    dependencies: [],
    isLoading: false,
    isDependencyLoading: false,
    isDuplicateComponent: false,
    errorMessage: null,
    isDynamicElementNameLoading: false,
    initSocket: () => {
        if (!PageListState.socket) {
            PageListSocket.init();
            PageListState.socket = PageListSocket.socket;
        } else {
            PageListState.socket.emit('requestUpdate');
        }
        PageListState.receiveUpdate();
    },
    reinitSocket: (client, callback = null) => {
        if (PageListState.socket) {
            PageListState.socket.close();
            PageListState.socket = null;
        }
        PageListSocket.socket = null;
        PageListState.initSocket();

        if (typeof callback === 'function') {
            callback();
        }
    },
    emitUpdate: () => {
        PageListState.socket.emit('create', {
            name: CreateNewState.value
        });
    },
    copyPage: (sourceId, targetName, targetClient) => {
        PageListState.isLoading = true;
        PageListState.socket.emit('copy', {
            sourceId,
            targetName,
            targetClient
        });
    },
    deletePage: (sourceId) => {
        PageListState.socket.emit('delete', { sourceId });
    },
    receiveUpdate: () => {
        PageListState.socket.on('serverUpdate', (data) => {
            if (data) {
                PageListState.data = data;
            }
        });
        PageListState.socket.on('createAccept', (data) => {
            PageListState.isLoading = false;
            CopyPageState.modalOpen = false;
            CopyPageState.dynamicElementName = "";
            if(CopyPageState.toClient) {
                window.localStorage.setItem(ClientState.clientContextVariable, CopyPageState.targetClient);
                window.location = '/pages';
            } else {
                PageListState.newFormId = data.id;
            }
        });
        PageListState.socket.on('getDependencies', (dependencies) => {
            PageListState.dependencies = dependencies;
            PageListState.isDependencyLoading = false;
        });
        PageListState.socket.on('createDecline', (data) => {
            PageListState.isDuplicatePage = true;
            PageListState.errorMessage = `Copy Failed! The page name is already in use ${CopyPageState.toClient ? `for ${CopyPageState.targetClient} client` : ``}.`;
            PageListState.isLoading = false;
        });
        PageListState.socket.on('getDynamicElementName', (data) => {
            if(!data.isDuplicate) {
                CopyPageState.dynamicElementName = data.dynamicElementName
            }
            PageListState.isDynamicElementNameLoading = false;
        });
    },
    getDependencies: (sourceType, sourceId, sourceName) => {
        PageListState.isDependencyLoading = true;
        PageListState.socket.emit('getDependencies', {
            sourceType,
            sourceId,
            sourceName
        });
        
    },
    getDynamicElementName: (sourceName, targetClient) =>{
        PageListState.isDynamicElementNameLoading = true;
        PageListState.socket.emit('getDynamicElementName', {
            sourceName,
            targetClient
        });
    }
});

export default PageListState;