import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import CustomFieldListState from "../../../State/CustomFieldListState";
import CopyCustomFieldState from "../../../State/CustomField/CopyCustomFieldState";
import ClientState from '../../../State/ClientState';
import { getErrorMessage } from "../../../lib";

class CopyCustomField extends Component {

    state = {
        isError: false,
        toClient: "none",
        toClientValidate: false
    }

    handleSubmit() {
        let isFormValidate = true;
        // eslint-disable-next-line no-useless-escape
        let regex = new RegExp("^[a-zA-Z0-9_\s]+$");
        if (CopyCustomFieldState.dynamicElementName.trim() === "") {
            this.setState({ isError: true });
            isFormValidate = false;
        } else if (CopyCustomFieldState.dynamicElementName !== "" && !regex.test(CopyCustomFieldState.dynamicElementName)) {
            this.setState({ isError: true });
            isFormValidate = false;
        }
        
        if(CopyCustomFieldState.toClient && this.state.toClient === 'none') {
            isFormValidate = false;
            this.setState({ toClientValidate: true });
        }
        
        if(isFormValidate) {
            CopyCustomFieldState.targetClient = CopyCustomFieldState.toClient ? this.state.toClient : "";
            CustomFieldListState.copyCustomField(CopyCustomFieldState.sourceId, CopyCustomFieldState.dynamicElementName, CopyCustomFieldState.toClient ? this.state.toClient : "");
        }
    }

    handleClose() {
        this.setState({
            isError: false,
            toClient: "none",
            toClientValidate: false
        });
        CopyCustomFieldState.modalOpen = false;
        CopyCustomFieldState.toClient = false;
        CopyCustomFieldState.targetClient = "";
        CopyCustomFieldState.dynamicElementName = ""; 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === "name") {
            CopyCustomFieldState.dynamicElementName = event.target.value;
            if(event.target.value.trim() === ""){
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
            }
        }

        if(CopyCustomFieldState.toClient && event.target.name === "toClient") {
            if (event.target.value !== "none") {
                this.setState({ toClientValidate: false });
                CustomFieldListState.getDynamicElementName(CopyCustomFieldState.sourceName, event.target.value);        
            } else {
                this.setState({ toClientValidate: true });
            }
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={() => this.handleClose()} open={CopyCustomFieldState.modalOpen}>
                    <DialogTitle>Copy Custom Field {CopyCustomFieldState.toClient ? 'To Another Client' : ''}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>Let's give the custom field a name to get started.</DialogContentText>
                        {CopyCustomFieldState.toClient &&
                            <React.Fragment>
                                <FormControl fullWidth error={ this.state.toClientValidate }>
                                    <InputLabel id={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}>Client</InputLabel>
                                    <Select
                                        autoFocus
                                        fullWidth
                                        labelId={`client-select${this.state.toClientValidate ? `-error-` : `-`}label`}
                                        id={`client-select${this.state.toClientValidate ? `-error` : ``}`}
                                        name='toClient'
                                        value={this.state.toClient}
                                        onChange={(event) => this.handleChange(event)}
                                        >
                                            <MenuItem value="none" key="0">Please Select</MenuItem>
                                            {ClientState.clientList.map((client, key) => (
                                                <MenuItem value={client} key={key+1}>{client}</MenuItem>
                                            ))}
                                    </Select>
                                    { this.state.toClientValidate && 
                                        <FormHelperText>Please Select Client</FormHelperText>
                                    }
                                </FormControl>
                            </React.Fragment>
                        }
                        <TextField
                            margin='dense'
                            label={"Custom Field Name"}
                            name='name'
                            type='text'
                            value={CopyCustomFieldState.dynamicElementName}
                            helperText={this.state.isError ? getErrorMessage('customField') : ""}
                            error={this.state.isError}
                            onChange={(event) => this.handleChange(event)}
                            fullWidth
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                        {!CustomFieldListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'>
                                Copy Custom Field
                        </Button>
                        }
                        {CustomFieldListState.isLoading &&
                            <Button
                                onClick={() => this.handleSubmit()}
                                color='primary'
                                disabled={true}>
                                Copy Custom Field  &nbsp; <CircularProgress size={18} />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    CustomFieldListState.isDuplicateCustomField && <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={CustomFieldListState.isDuplicateCustomField} autoHideDuration={10000}
                        onClose={() => CustomFieldListState.isDuplicateCustomField = false}>

                        <Alert variant="filled" severity="error" onClose={() => CustomFieldListState.isDuplicateCustomField = false}>
                            {CustomFieldListState.errorMessage}
                        </Alert>
                    </Snackbar>
                }
            </>
        );
    }

}

export default view(CopyCustomField);

