import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AssetManagerState from "../../State/AssetManagerState";
import UploadFileModalState from "../../State/UploadFileModalState";
import Dropzone from "react-dropzone";
import Grid from '@material-ui/core/Grid';

import '../AssetManager/FileList.css';

class UploadFileModal extends Component {

    state = {
        dragEnter: false
    };

    handleUpload = (acceptedFiles) => {
        this.setState({ dragEnter: false })
        const currentDir = AssetManagerState.getCurrentDirectory();
        this.handleClose();
        AssetManagerState.uploadFiles(currentDir.Prefix, acceptedFiles);
    };

    handleClose() {
        UploadFileModalState.modalOpen = false;
    }

    render() {
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    onClose={() => this.handleClose()} open={UploadFileModalState.modalOpen}>
                    <DialogTitle id="alert-dialog-title">
                        Upload Assets
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Dropzone
                            onDrop={(acceptedFiles) => this.handleUpload(acceptedFiles)}
                            // accept="image/*"
                            // noClick={true}
                            // noKeyboard={true}
                            // preventDropOnDocument={true}
                            // onDragEnter={() => this.setState({ dragEnter: true })}
                            // onDragLeave={() => this.setState({ dragEnter: false })}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Grid container {...getRootProps()} className="dropzone">
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Drag & drop files here, or click to select files</p>
                                        </div>
                                    </section>
                                </Grid>
                            )}
                        </Dropzone>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color='secondary'>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default view(UploadFileModal);